import React from "react";
import { Switch } from "react-router-dom";

import {
  ADMIN,
  BOOK_SELLER,
  EDITOR_PDE,
  ONLINE_SELLER,
  PUBLICATION_MANAGER,
} from "./constants/users";

import SignInView from "containers/views/SignInView";
import StaffRoute from "components/routes/StaffRoute";
import PublicRoute from "components/routes/PublicRoute";
import OnlyPublicRoute from "components/routes/OnlyPublic";
import DefaultLayout from "components/layouts/DefaultLayout";
import PrivateRoute from "components/routes/PrivateRoute";
import ResumeActivationView from "containers/views/ResumeActivationView";
import ResetPasswordView from "containers/views/ResetPasswordView";
import ChangePasswordView from "./containers/views/ChangePasswordView";

import ProfileRouter from "./profile/ProfileRouter";
import AuthorDetails from "./containers/views/AuthorDetailsView";
import PublisherDetails from "./containers/views/PublisherDetailsView";
import CoverDetails from "./containers/views/CoverDetailsView";
import EventDetails from "./containers/views/EventDetailsView";
import Booksellers from "./containers/views/Booksellers";
import Users from "./containers/views/Users";
import Publishers from "./containers/views/Publishers";
import Authors from "./containers/views/Authors";
import Covers from "./containers/views/Covers";
import Events from "./containers/views/Events";
import SignUpView from "./containers/views/SignUpView";
import UpcomingBooks from "./containers/views/UpcomingBooks";
import Posts from "./containers/views/Posts";
import PostDetails from "./containers/views/PostDetailsView";

const ApplicationRouter = () => {
  return (
    <Switch>
      <OnlyPublicRoute path="/signin" component={SignInView} />
      <OnlyPublicRoute
        path="/signup/:kind(editor|bookseller)"
        component={(props) => <SignUpView kind={props.match.params.kind} />}
      />

      <PublicRoute
        path="/resume-activation/:token"
        component={ResumeActivationView}
      />
      <PublicRoute
        path="/change-password/:token"
        component={ChangePasswordView}
      />
      <PublicRoute path="/recover" component={ResetPasswordView} />

      <PrivateRoute
        path="/profile"
        layout={DefaultLayout}
        component={ProfileRouter}
        allowedRoles={[ADMIN, EDITOR_PDE, ONLINE_SELLER, PUBLICATION_MANAGER]}
        container={false}
      />

      <StaffRoute path="/users" layout={DefaultLayout} component={Users} />
      <PrivateRoute
        path="/publishers"
        exact
        layout={DefaultLayout}
        component={Publishers}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />
      <PrivateRoute
        path="/authors"
        exact
        layout={DefaultLayout}
        component={Authors}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />
      <PrivateRoute
        path="/covers"
        exact
        layout={DefaultLayout}
        component={Covers}
        allowedRoles={[
          ADMIN,
          EDITOR_PDE,
          ONLINE_SELLER,
          PUBLICATION_MANAGER,
          BOOK_SELLER,
        ]}
      />
      <PrivateRoute
        path="/events"
        exact
        layout={DefaultLayout}
        component={Events}
        allowedRoles={[ADMIN, EDITOR_PDE, PUBLICATION_MANAGER]}
      />
      <PrivateRoute
        path="/booksellers"
        exact
        layout={DefaultLayout}
        component={Booksellers}
        allowedRoles={[ADMIN, EDITOR_PDE, PUBLICATION_MANAGER]}
      />
      <PrivateRoute
        path="/upcoming-books"
        exact
        layout={DefaultLayout}
        component={UpcomingBooks}
        allowedRoles={[ADMIN, EDITOR_PDE, PUBLICATION_MANAGER, BOOK_SELLER]}
      />
      <PrivateRoute
        path="/posts"
        exact
        layout={DefaultLayout}
        component={Posts}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />

      <PrivateRoute
        path="/posts/:id"
        layout={DefaultLayout}
        component={(props) => <PostDetails id={props.match.params.id} />}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />

      <PrivateRoute
        path="/authors/:id"
        layout={DefaultLayout}
        component={(props) => <AuthorDetails id={props.match.params.id} />}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />
      <PrivateRoute
        path="/publishers/:id"
        layout={DefaultLayout}
        component={(props) => <PublisherDetails id={props.match.params.id} />}
        allowedRoles={[ADMIN, EDITOR_PDE, BOOK_SELLER]}
      />
      <PrivateRoute
        path="/covers/:id"
        layout={DefaultLayout}
        component={(props) => <CoverDetails id={props.match.params.id} />}
        allowedRoles={[
          ADMIN,
          EDITOR_PDE,
          ONLINE_SELLER,
          PUBLICATION_MANAGER,
          BOOK_SELLER,
        ]}
      />
      <PrivateRoute
        path="/events/:id"
        layout={DefaultLayout}
        component={(props) => <EventDetails id={props.match.params.id} />}
        allowedRoles={[ADMIN, EDITOR_PDE, PUBLICATION_MANAGER]}
      />

      <PrivateRoute
        path="/"
        exact
        layout={DefaultLayout}
        component={() => <div />}
        allowedRoles={[
          ADMIN,
          EDITOR_PDE,
          ONLINE_SELLER,
          PUBLICATION_MANAGER,
          BOOK_SELLER,
        ]}
      />
      <PrivateRoute path="/" layout={DefaultLayout} component={() => <div />} />
    </Switch>
  );
};

export default ApplicationRouter;
