import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class SettingsService {
  async get () {
    return axios.get(`${API_ENDPOINT}/settings`)
  }

  async update (body) {
    return axios.patch(`${API_ENDPOINT}/settings`, { data: body })
  }
}

export default new SettingsService()
