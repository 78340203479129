import React, { Component } from 'react'
import { Button } from 'reactstrap'

import AuthorsService from 'services/AuthorsService'
import Book from 'components/Book'
import Heading from 'components/Heading'
import GridList from 'components/GridList'
import PageContainer from 'components/layouts/PageContainer'
import ReduxModalWrapper from 'components/modals/reduxModal/ReduxModalWrapper'
import AddAuthorFormModal from 'components/modals/AddAuthorFormModal'
import ModalsService from 'services/ModalsService'
import { format } from 'helpers/dateTime'
import Author from 'models/author'

import styles from './AuthorDetails.module.scss'
import withAuth from 'containers/auth.container'

class AuthorDetails extends Component {
  state = {
    isLoading: false,
    author: null,
    publications: null
  }

  componentDidMount () {
    const { id } = this.props

    this.setState({ isLoading: true })

    AuthorsService
      .get(id)
      .then((response) => {
        const { data } = response
        const author = new Author(data.author)
        console.log('--------')
        console.log(data)
        this.setState({ author: author, publications: data.author.covers, isLoading: false })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  onEditAuthor = () => {
    const { author } = this.state

    ModalsService.open('EditAuthorFormModal', {
      title: 'Update Author',
      initialValues: {
        ...author,
        publisher: author.publisher._id
      },
      onSubmit: (body) => {
        AuthorsService
          .update(author._id, body)
          .then((response) => {
            const { data: { author } } = response
            ModalsService.close('EditAuthorFormModal')
            this.setState({ author: new Author(author) })
          })
      },
      onCancel: () => {
        ModalsService.close('EditAuthorFormModal')
      }
    })
  }

  render () {
    const { author, publications, isLoading } = this.state

    if (!isLoading && !author) {
      return null
    }

    return (
      <PageContainer animate loading={isLoading}>
        {() => {
          const {
            name,
            surname,
            description,
            birthDate,
            photo,
            publisher = {}
          } = author

          return (
            <div className={styles.AuthorDetailsPage}>
              <section>
                <Heading icon='fa fa-user fa-xs'><span>{name} {surname} {format(birthDate)}</span></Heading>
                <div className={styles.AuthorDetails}>
                  <img src={photo} width={400} alt='' />
                  <p className={styles.Description}>{description}</p>
                </div>
              </section>
              <section>
                <Heading icon='fas fa-book fa-xs'>Pubblicazioni con {publisher.companyName}</Heading>
                <GridList>
                  {publications.map((cover) => <Book cover={cover}
                    width={188}
                    height={360}
                    size='sm'
                    className='publisherBooks' />)}
                </GridList>
              </section>
              {
                !this.props.isBookSeller && (
                  <div>
                    <Button className={styles.Edit} color='warning' size='xs' onClick={this.onEditAuthor}>
                      <i className='fa fa-pen' style={{ color: '#ffffff' }} />
                    </Button>
                    <ReduxModalWrapper modal='EditAuthorFormModal' component={AddAuthorFormModal} />
                  </div>
                )
              }
            </div>
          )
        }}
      </PageContainer>
    )
  }
}

export default withAuth(AuthorDetails)
