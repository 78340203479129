import React from 'react'
import PropTypes from 'prop-types'

import LoadingDot from './LoadingDot'

const LoadingDots = ({ color, size }) => {
  return (
    <div style={{ fontSize: `${size}px` }}>
      <LoadingDot color={color} delay={0} />
      <LoadingDot color={color} delay={160} />
      <LoadingDot color={color} delay={320} />
    </div>
  )
}

LoadingDots.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
}

LoadingDots.defaultProps = {
  color: '#c8ced3',
  size: 6
}

export default LoadingDots
