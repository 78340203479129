import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Col, Row } from 'reactstrap'
import { Field, formPropTypes, reduxForm } from 'redux-form'

import FormModal from '../FormModal'

import { email, required } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'

import userFormStyles from './AddUserFormModal.module.scss'
import styles from '../FormModalBase.module.scss'
import {
  renderCheckbox,
  renderFormGroupTextField,
  renderSelectMappingField
} from '../../forms/formFields'
import { USER_ROLES_FROM_ADMIN, USER_ROLES } from '../../../constants/users'

const AddUserFormModal = props => {
  const { title, error, disabled, mode } = props

  return (
    <FormModal
      className={cx(styles.FormModalBase, userFormStyles.UserFormModal)}
      {...props}
      formTitle={title}
    >
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            disabled={disabled}
            validate={disabled ? [required] : []}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Cognome'
            name='surname'
            disabled={disabled}
            validate={disabled ? [required] : []}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Email'
            name='email'
            type='email'
            disabled={disabled}
            validate={disabled ? [required, email] : []}
          />
        </Col>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Ruolo'
            name='role'
            mapping={mode === 'create' ? USER_ROLES_FROM_ADMIN : USER_ROLES}
            disabled={disabled}
            validate={disabled ? [required] : []}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderCheckbox}
            label='Active'
            name='active'
            disabled={disabled}
          />
        </Col>
      </Row>
    </FormModal>
  )
}

AddUserFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

AddUserFormModal.defaultProps = {
  title: 'Utenti'
}

export default reduxForm({
  form: 'add-user-modal-form'
})(AddUserFormModal)
