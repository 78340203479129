import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CardText } from 'reactstrap'

import ChoosePasswordForm from 'components/forms/ChoosePasswordForm'
import AuthService from 'services/AuthService'
import SimpleFormView from './SimpleFormView'

const ResumeActivationView = (props) => {
  const { token } = props.match.params

  const [isProcessing, setProcessing] = useState(true)
  const [changed, setChanged] = useState(false)
  const [error, setError] = useState(null)

  const handleError = (error) => {
    if (error.response && error.response.data) {
      setError(error.response.data)
    } else {
      setError(error)
    }
    setProcessing(false)
  }

  useEffect(() => {
    setProcessing(true)

    AuthService
      .authVerifyRestoreToken(token)
      .then(() => {
        setProcessing(false)
      })
      .catch((error) => {
        handleError(error)
      })
  }, [token])

  const onSubmit = (values) => {
    const { token } = props.match.params
    const { password, passwordConfirmation } = values

    AuthService
      .restore(token, password, passwordConfirmation)
      .then(() => {
        setChanged(true)
      })
      .catch((error) => {
        handleError(error)
      })
  }

  let cardBody = null
  if (error) {
    cardBody = <h1 className='m-0'>Il link è invalido o scaduto</h1>
  } else if (changed) {
    cardBody = (
      <Fragment>
        <h1>Complimenti!</h1>
        <CardText>Ora puoi accedere al portale.</CardText>
        <NavLink className='navbar-brand' to='/signin'>
          Accedi
        </NavLink>
      </Fragment>
    )
  } else {
    cardBody = (
      <Fragment>
        <h1>Scegli una nuova password</h1>
        <p className='text-muted'>Usa la form sotto per impostare una nuova password</p>
        <ChoosePasswordForm onSubmit={onSubmit} />
      </Fragment>
    )
  }

  return (
    <SimpleFormView isLoading={isProcessing}>
      {cardBody}
    </SimpleFormView>
  )
}

export default ResumeActivationView
