import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import withAuth from 'containers/auth.container'
import { ADMIN } from '../../constants/users'

const PrivateRoute = ({ user, isAuthenticated, component: Component, layout: Layout, allowedRoles, container, ...rest }) => {
  const allowed = isAuthenticated && user && (Array.isArray(allowedRoles) ? allowedRoles.includes(user.role) : allowedRoles === 'all')

  return (
    <Route {...rest} render={props => {
      return (
        !isAuthenticated ? (
          <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
        ) : (
          allowed ? (
            Layout === undefined ? (
              <Component {...props} />
            ) : (
              <Layout container={container}><Component {...props} /></Layout>
            )
          ) : (
            <h1>Forbidden</h1>
          )
        )
      )
    }} />
  )
}

PrivateRoute.propTypes = {
  // component: PropTypes.func.isRequired,
  // location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
  // layout: PropTypes.func
}

PrivateRoute.defaultProps = {
  allowedRoles: [ADMIN]
}

export { PrivateRoute }
export default withAuth(PrivateRoute)
