import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { icons } from 'resources/assets/images/icons'
import ProfileDropdownMenu from 'components/Navbar/ProfileDropdownMenu'
import Notifications from './Notifications/Notifications'

const Header = ({ isAuthenticated, onToggleSidebar }) => {
  if (!isAuthenticated) {
    return null
  }

  return (
    <header className='app-header navbar'>
      <NavLink className='navbar-brand' to='/'>
        <img
          className='navbar-brand-full'
          src={icons.LOGO}
          height='80'
          alt='Logo'
        />
      </NavLink>
      <button
        className='navbar-toggler sidebar-toggler d-lg-none mr-auto'
        data-toggle='sidebar-show'
        onClick={onToggleSidebar}
      >
        <i className='fas fa-bars' style={{ color: '#ffffff' }} />
      </button>
      <button
        className='navbar-toggler sidebar-toggler d-md-down-none'
        data-toggle='sidebar-lg-show'
        onClick={onToggleSidebar}
      >
        <i className='fas fa-bars' style={{ color: '#ffffff' }} />
      </button>

      <ul className='nav navbar-nav ml-auto'>
        <li className='nav-item'>
          <Notifications />
        </li>
        <li className='nav-item'>
          <ProfileDropdownMenu />
        </li>
      </ul>
    </header>
  )
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  onToggleSidebar: PropTypes.func
}

export default Header
