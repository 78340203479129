import {
  UsersRoleFormatter,
  PublisherActionsFormatter,
  AuthorNameFormatter,
  PublisherDataFormatter,
  AuthorDataFormatter,
  PublicationActionsFormatter,
  DayMonthYearFormatter,
  ActionsFormatterGeneric,
  BooksellersActionFormatter,
  ImageCoverFormatter,
  PublisherNameFormatter,
  UpcomingBooksFormatter
} from './formatters'

export const Formatters = {
  ActionsFormatter: ActionsFormatterGeneric,
  BooksellersActionFormatter: BooksellersActionFormatter,
  ImageCoverFormatter: ImageCoverFormatter,
  UsersRoleFormatter: UsersRoleFormatter,
  PublisherActionsFormatter: PublisherActionsFormatter,
  AuthorNameFormatter: AuthorNameFormatter,
  PublisherNameFormatter: PublisherNameFormatter,
  PublisherDataFormatter: PublisherDataFormatter,
  AuthorDataFormatter: AuthorDataFormatter,
  PublicationActionsFormatter: PublicationActionsFormatter,
  DayMonthYearFormatter: DayMonthYearFormatter,
  UpcomingBooksFormatter: UpcomingBooksFormatter
}
