import React, { Component } from "react";
import { Button } from "reactstrap";

import PostsService from "services/PostsService";
import Book from "components/Book";
import Heading from "components/Heading";
import GridList from "components/GridList";
import PageContainer from "components/layouts/PageContainer";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import AddPostFormModal from "components/modals/AddPostFormModal";
import ModalsService from "services/ModalsService";
import { format } from "helpers/dateTime";
import Post from "models/post";

import { Col, Row } from "reactstrap";
import styles from "./PostDetails.module.scss";
import withAuth from "containers/auth.container";
import fileDownload from "js-file-download";

const download = (url) => {
  // window.location.href = url;
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = url.split("/").pop();
      // link.click();
      // const file = window.URL.createObjectURL(blob);
      // window.location.assign(file);
      fileDownload(blob, url.split("/").pop());
    })
    .catch(console.error);
};

class PostDetails extends Component {
  state = {
    isLoading: false,
    post: null,
  };

  componentDidMount() {
    const { id } = this.props;

    this.setState({ isLoading: true });

    PostsService.get(id)
      .then((response) => {
        const { data } = response;
        const post = new Post(data.post);
        this.setState({
          post: post,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { post, isLoading } = this.state;

    if (!isLoading && !post) {
      return null;
    }

    return (
      <PageContainer animate loading={isLoading}>
        {() => {
          const { title, body, published, publishDate, image } = post;

          return (
            <div className={styles.PostDetailsPage}>
              <section>
                <Heading icon="fa fa-user fa-xs">
                  <span>
                    {title} {format(publishDate)}
                  </span>
                </Heading>
                <Row>
                  <Col className="col-md-2">
                    {image && (
                      <Button
                        color="primary"
                        className={"ml-2"}
                        onClick={() => download(image)}
                      >
                        Scarica allegato
                      </Button>
                    )}
                  </Col>
                  <Col>
                    <div className={styles.Description}>{body}</div>
                  </Col>
                </Row>
              </section>
            </div>
          );
        }}
      </PageContainer>
    );
  }
}

export default withAuth(PostDetails);
