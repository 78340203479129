import React from "react";
import PropTypes from "prop-types";
import withAuth from "containers/auth.container";

import styles from "./UserActionsFormatter.module.scss";
import DateFormatter from "./DateFormatter";

const DayMonthYearFormatter = (props) => {
  const { value } = props;
  return (
    <div className={styles.userActionsFormatter}>
      {value !== undefined && value !== null && (
        <div className="mr-2">
          <DateFormatter value={value} format="DD MMMM YYYY" />
        </div>
      )}
    </div>
  );
};

DayMonthYearFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  canShow: PropTypes.bool,
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
};

DayMonthYearFormatter.defaultProps = {
  canPublish: false,
};

export default (outerProps) =>
  withAuth((props) => {
    return <DayMonthYearFormatter {...outerProps} {...props} />;
  });
