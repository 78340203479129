import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  Card,
  CardBody,
  CardGroup
} from 'reactstrap'

import Loader from 'components/Loader'

import styles from './SimpleFormView.module.scss'

const SimpleFormView = ({ isLoading, loadingText, children }) => {
  const loader = isLoading ? (
    <div className={styles.loader}>
      <h4 className='mr-3'>{loadingText}</h4>
      <Loader />
    </div>
  ) : null

  return (
    <div className={styles.signInView}>
      <CardGroup>
        <Card className='p-4'>
          <CardBody className={cx({ [styles.hide]: isLoading })}>
            {children}
          </CardBody>
          {loader}
        </Card>
      </CardGroup>
    </div>
  )
}

SimpleFormView.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string
}

export default SimpleFormView
