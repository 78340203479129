import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'

import GridItemWrapper from './GridItemWrapper'

import styles from './GridList.module.scss'

class GridList extends Component {
  constructor (props) {
    super(props)

    this.containerRef = React.createRef()
    this.resizeObserver = new ResizeObserver((entries) => {
      Promise.resolve().then(this.onResize)
    })

    this.elementRefs = []
  }

  onResize = () => {
    const container = this.containerRef.current

    if (container && this.elementRefs.length > 0) {
      const containerBounds = container.getBoundingClientRect()
      const containerWidth = containerBounds.width - 40 // padding of container

      const minOffset = this.props.offset
      const elementWidth = this.elementRefs[0].getWidth()
      let elementsCount = Math.floor(containerWidth / elementWidth)

      while (elementsCount * elementWidth + (elementsCount - 1) * minOffset > containerWidth) {
        elementsCount--
      }

      const offset = (containerWidth - elementsCount * elementWidth) / (elementsCount - 1)

      this.elementRefs.forEach((value, idx) => {
        const itm = this.elementRefs[idx].getItem()
        if (idx !== 0 && (idx + 1) % elementsCount === 0) {
          itm.style = `margin-right: ${0}px`
        } else {
          itm.style = `margin-right: ${offset}px`
        }
      })
    }
  }

  componentDidMount () {
    this.resizeObserver.observe(this.containerRef.current)
  }

  componentWillUnmount () {
    this.resizeObserver.disconnect()
  }

  render () {
    const { children } = this.props

    return (
      <div ref={this.containerRef} className={styles.GridList}>
        {children.map((element, idx) => (
          <GridItemWrapper ref={r => {
            if (r) {
              this.elementRefs[idx] = r
            }
          }} key={idx} className={styles.GridListItemWrapper}>
            {element}
          </GridItemWrapper>
        ))}
      </div>
    )
  }
}

GridList.propTypes = {
  offset: PropTypes.number
}

GridList.defaultProps = {
  offset: 50
}

export default GridList
