import {
  AUTH_LOGIN_USER_FAILURE,
  AUTH_LOGIN_USER_REQUEST,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGOUT_USER,
  AUTH_UPDATE_USER,
  AUTH_VERIFY_TOKEN_FAILURE,
  AUTH_VERIFY_TOKEN_REQUEST,
  AUTH_VERIFY_TOKEN_SUCCESS
} from '../../constants'

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isRestoring: false,
  user: null
}

export default function authReducer (state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGOUT_USER: {
      return {
        ...initialState
      }
    }

    case AUTH_LOGIN_USER_REQUEST: {
      return {
        ...state,
        isAuthenticating: true
      }
    }

    case AUTH_LOGIN_USER_SUCCESS: {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        user
      }
    }

    case AUTH_LOGIN_USER_FAILURE: {
      const responseError = action.payload.error
      const response = responseError.response
      let error = null

      if (response && response.data) {
        error = response.data
      } else {
        error = action.payload
      }

      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        error: error
      }
    }

    case AUTH_VERIFY_TOKEN_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
        isRestoring: true
      }
    }

    case AUTH_VERIFY_TOKEN_SUCCESS: {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isRestoring: false,
        user: user
      }
    }

    case AUTH_VERIFY_TOKEN_FAILURE: {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isRestoring: false,
        user: user
      }
    }

    case AUTH_UPDATE_USER: {
      const { user } = action.payload

      return {
        ...state,
        user: user
      }
    }

    default:
      return state
  }
}
