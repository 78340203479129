import React, { Fragment, useState } from 'react'

import { Formatters } from 'components/data-grid-table'
import Publisher from 'models/publisher'
import DataTable from './DataTable'
import PublishersService from 'services/PublishersService'
import ModalsService from 'services/ModalsService'
import { PUBLISHER_PROMOTER_FILTER } from 'constants/publishers'
import { FilterDropDownComponent } from 'components/data-grid-table/filters/components'
import AddPublisherFormModal from 'components/modals/AddPublisherFormModal'
import PublisherEditorsPDEModal from 'components/modals/PublisherEditorsPDEModal'
import CancelSubmitionError from 'components/forms/errors/CancelSubmitionError'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import ReduxModalWrapper from 'components/modals/reduxModal/ReduxModalWrapper'
import withAuth from '../../containers/auth.container'
import { ADMIN, BOOK_SELLER } from '../../constants/users'

const getColumns = (outerProps, role) => props => {
  const columns = [
    {
      key: 'companyName',
      name: 'Ragione Sociale',
      sortable: true,
      filterable: true
    },
    {
      key: 'address',
      name: 'Indirizzo',
      sortable: true,
      filterable: true
    },
    {
      key: 'vatNumber',
      name: 'P. IVA',
      sortable: true,
      filterable: true
    },
    {
      key: 'code',
      name: 'Codice',
      sortable: true,
      filterable: true
    },
    {
      key: 'promoter',
      name: 'Promozione',
      sortable: true,
      filterable: true,
      filterRenderComponent: props => (
        <FilterDropDownComponent
          {...props}
          values={PUBLISHER_PROMOTER_FILTER}
        />
      )
    },
    {
      key: 'publisherId',
      name: 'PublisherId',
      sortable: true,
      filterable: true,
      formatter: Formatters.PublisherNameFormatter
    },
    {
      key: 'actions',
      name: 'Azioni',
      formatter: Formatters.PublisherActionsFormatter({
        ...props,
        ...outerProps
      }),
      cellClass: 'justify-content-center'
    }
  ]
  if (role === ADMIN) {
    columns.splice(6, 0, {
      key: 'editorsPDE',
      name: 'External Users',
      formatter: props => {
        if (props.row._editorsPDE) {
          return props.row._editorsPDE.map(eu => eu.email).join('\n')
        }
        return null
      }
    })
  }

  if (role === BOOK_SELLER) {
    columns.pop()
  }

  return columns
}

const CONFIRMATION_WINDOW_MODAL = 'confirmPublisherDeleteModal'

const PublishersDataTable = ({ user }) => {
  const [
    publisherEditorsPDEModalOpened,
    setPublisherEditorsPDEModalOpened
  ] = useState(false)
  const [
    publisherEditorsPDEModalProps,
    setPublisherEditorsPDEModalProps
  ] = useState(null)
  const [publishers, setPublishers] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const fetchPublishers = ({ page: { number, size }, formFilterData }) => {
    PublishersService.getPublishers(number, size, formFilterData)
      .then(response => {
        const {
          data: { results, totalRecords }
        } = response
        const publishers = results.map(response =>
          Publisher.fromResponse(response)
        )

        setPublishers(publishers)
        setTotalRows(totalRecords)
      })
      .catch(() => {})
  }

  const onChange = params => {
    fetchPublishers(params)
  }

  const togglePublisherEditorsPDEModal = publisher => {
    const title = `${publisher.companyName}: Associate Editors PDE`

    setPublisherEditorsPDEModalProps(
      !publisherEditorsPDEModalOpened
        ? { title: title, publisher: publisher }
        : null
    )
    setPublisherEditorsPDEModalOpened(!publisherEditorsPDEModalOpened)
  }

  const onUpdatePublisherEditorsPDE = (id, publisherData) => {
    const newPublishers = [...publishers]
    const publisherIndex = newPublishers.findIndex(
      publisher => publisher._id === id
    )

    if (publisherIndex !== -1) {
      const updatedPublisher = new Publisher(publisherData)
      newPublishers[publisherIndex] = updatedPublisher
      setPublishers(newPublishers)

      if (publisherEditorsPDEModalOpened) {
        setPublisherEditorsPDEModalProps({
          ...publisherEditorsPDEModalProps,
          publisher: updatedPublisher
        })
      }
    }
  }

  const handleSubmit = action => {
    return formValues => {
      const body = { ...formValues }

      let request = PublishersService[action]

      if (request) {
        if (action === 'create') {
          request = request(body)
        }

        if (action === 'update') {
          const { _id } = body
          request = request(_id, body)
        }

        if (action === 'delete') {
          const { _id } = body

          request = new Promise((resolve, reject) => {
            PublishersService.show(_id)
              .then(response => {
                const {
                  data: { publisher }
                } = response
                const { authors, covers } = publisher

                if (authors.length > 0 || covers.length > 0) {
                  ModalsService.open(CONFIRMATION_WINDOW_MODAL, {
                    title: 'Elimina editore',
                    text:
                      'Ci sono autore e copertine associati a questo editore. Proseguendo verranno eliminati tutti.\n\nConfermi questa azione?',
                    onSubmit: () => {
                      PublishersService[action](_id, body)
                        .then(response => {
                          ModalsService.close(CONFIRMATION_WINDOW_MODAL)
                          resolve(response)
                        })
                        .catch(error => {
                          ModalsService.close(CONFIRMATION_WINDOW_MODAL)
                          resolve(error)
                        })
                    },
                    onCancel: () => {
                      reject(new CancelSubmitionError())
                    }
                  })
                } else {
                  resolve(PublishersService[action](_id, body))
                }
              })
              .catch(reject)
          })
        }

        return request
      }
    }
  }

  const getFormModalModeProps = mode => {
    if (mode === 'create') {
      return {
        submitButtonLabel: 'Crea',
        title: 'Crea editore',
        mode: mode,
        role: user.role,
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'update') {
      return {
        submitButtonLabel: 'Aggiorna',
        title: 'Aggiorna editore',
        mode: mode,
        role: user.role,
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'delete') {
      return {
        submitButtonLabel: 'Elimina',
        title: 'Elimina editore',
        mode: mode,
        role: user.role,
        onSubmit: handleSubmit(mode)
      }
    }

    return {
      submitButtonLabel: 'Salva',
      title: 'Editore'
    }
  }

  const outerColumnProps = {
    onInfo: togglePublisherEditorsPDEModal,
    canRemove: user.role === ADMIN,
    canInfo: user.role === ADMIN
  }

  const formModalProps =
    user.role === BOOK_SELLER
      ? null
      : {
          formModalComponent: AddPublisherFormModal,
          getFormModalModeProps: getFormModalModeProps
        }

  return (
    <Fragment>
      <DataTable
        columns={getColumns(outerColumnProps, user.role)}
        data={publishers}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={formModalProps}
      />
      <PublisherEditorsPDEModal
        {...publisherEditorsPDEModalProps}
        onUpdatePublisher={onUpdatePublisherEditorsPDE}
        isOpen={publisherEditorsPDEModalOpened}
        toggle={togglePublisherEditorsPDEModal}
      />
      <ReduxModalWrapper
        modal={CONFIRMATION_WINDOW_MODAL}
        component={ConfirmationModal}
      />
    </Fragment>
  )
}
export default withAuth(PublishersDataTable)
