import React, { Component } from 'react'
import Select from 'react-select'
import { AutoSizer, List } from 'react-virtualized'
import { NoOptionsMessage } from './NoOptionMessage'
import { LoadingMessage } from './LoadingMessage'

class VirtualizedSelect extends Component {
  constructor (props) {
    super(props)

    this.renderMenu = this.renderMenu.bind(this)
  }

  renderMenu (props) {
    const { children, isLoading } = props

    let rowRenderer
    if (children && children.length > 0) {
      rowRenderer = ({ key, index, style }) => {
        return (
          <div key={key} style={style}>{children[index]}</div>
        )
      }
    } else if (isLoading) {
      rowRenderer = ({ key }) => <LoadingMessage key={key} {...props} />
    } else {
      rowRenderer = ({ key }) => <NoOptionsMessage key={key} {...props} />
    }

    return (
      <AutoSizer disableHeight style={{ width: 'auto' }}>
        {({ width }) => {
          return (
            <List
              style={{ width: '100%' }}
              width={width}
              height={Math.min(300, children && children.length > 0 ? children.length * 40 : 40)}
              rowHeight={40}
              rowCount={children.length || 1}
              rowRenderer={rowRenderer}
            />
          )
        }}
      </AutoSizer>
    )
  }

  render () {
    const { components, ...props } = this.props
    return (
      <Select
        {...props}
        components={{ MenuList: this.renderMenu, ...components }}
      />
    )
  }
}

export default VirtualizedSelect
