import PropTypes from 'prop-types'

import { simpleTablePropTypes } from './simpleTablePropTypes'
import { formModalPropTypes } from './formModalPropTypes'

const dataTablePropTypes = {
  ...simpleTablePropTypes,
  columns: PropTypes.oneOfType([
    PropTypes.func,
    simpleTablePropTypes.columns
  ]),
  title: PropTypes.string,
  totalRowsCount: PropTypes.number,
  onChange: PropTypes.func,
  onAddData: PropTypes.func,
  selectPagesOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPageDefaultValue: PropTypes.number,
  formModalProps: PropTypes.shape({
    ...formModalPropTypes
  }),
  allowToChangeRowsPerPage: PropTypes.bool,
  prepareModalData: PropTypes.func,
  selected: PropTypes.bool,
  onSelectedRowChange: PropTypes.func
}

export { dataTablePropTypes }
