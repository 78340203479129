import axios from "libs/axios";
import { API_ENDPOINT } from "config";

class CoversService {
  async getCovers(pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize,
      },
      search: Object.assign({}, search),
    };

    return axios.get(`${API_ENDPOINT}/covers`, { params: params });
  }

  async index() {
    return axios.get(`${API_ENDPOINT}/covers`);
  }

  async get(id) {
    return axios.get(`${API_ENDPOINT}/covers/${id}`);
  }

  async create(body) {
    const formData = new FormData();

    for (const key in body) {
      if (key !== "additionalImages") {
        formData.append(key, body[key]);
      } else {
        body["additionalImages"].forEach((imageData) => {
          const { image, fileName } = imageData;
          formData.append("additionalImages", image[0], fileName);
        });
      }
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return axios.post(`${API_ENDPOINT}/covers`, formData, config);
  }

  async updateMedia(id, body) {
    return axios.patch(`${API_ENDPOINT}/covers/${id}/media`, { data: body });
  }

  async update(id, body) {
    const formData = new FormData();

    for (const key in body) {
      if (key !== "additionalImages") {
        formData.append(key, body[key]);
      } else {
        const arr = [];
        body["additionalImages"].forEach((imageData) => {
          const { image, fileName } = imageData;
          if (typeof image === "string") {
            arr.push(imageData);
          } else {
            formData.append("additionalImages", image[0], fileName);
          }
        });
        if (arr.length > 0) {
          formData.append("additionalImages", JSON.stringify(arr));
        }
      }
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return axios.patch(`${API_ENDPOINT}/covers/${id}`, formData, config);
  }

  async delete(id, body) {
    return axios.delete(`${API_ENDPOINT}/covers/${id}`, { data: body });
  }

  async publish(id, body) {
    return axios.patch(`${API_ENDPOINT}/covers/${id}/publish`, { data: body });
  }

  async download(...coverIds) {
    const params = {
      covers: coverIds,
    };

    return axios.get(`${API_ENDPOINT}/covers/download`, {
      params: params,
      responseType: "blob",
    });
  }
}

export default new CoversService();
