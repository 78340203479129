import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { format } from "helpers/dateTime";

import styles from "./Book.module.scss";

const Book = (props) => {
  const {
    cover: {
      authors,
      authorsStr,
      title,
      publicationDate,
      imageUrl,
      ean,
      series,
      price,
      genre,
      pageNumber,
      edition,
      language,
      publishOnPDE,
    },
    size,
    className,
    width,
    height,
  } = props;

  return (
    <div className={cx(styles.Book, styles[`Book-${size}`], className)}>
      <img src={imageUrl} alt="" width={width} height={height} />
      <span>
        <strong>Titolo:</strong> {title}
      </span>
      <span>
        <strong>Autori: </strong>
        <span className="d-inline mr-2">{authorsStr}</span>
        {/* {authors.map(({ name, surname }, index, arr) => {
          const coma = index < arr.length - 1 ? ", " : "";
          const authorName = size === "sm" ? `${name[0]}.` : name;
          return (
            <span key={index} className="d-inline mr-2">
              {authorName}&nbsp;{`${surname}${coma}`}
            </span>
          );
        })} */}
      </span>
      {genre && (
        <span>
          <strong>Genere:</strong> {genre}
        </span>
      )}
      {edition && (
        <span>
          <strong>Edizione:</strong> {edition}
        </span>
      )}
      {ean && (
        <span>
          <strong>EAN:</strong> {ean}
        </span>
      )}
      {language && (
        <span>
          <strong>Lingua:</strong> {language}
        </span>
      )}
      {price && (
        <span>
          <strong>Prezzo:</strong> {price}
        </span>
      )}
      {pageNumber && (
        <span>
          <strong>Pagine:</strong> {pageNumber}
        </span>
      )}
      {publicationDate && (
        <span>
          <strong>Data di pubblicazione:</strong>{" "}
          {format(publicationDate, "MMMM YYYY")}
        </span>
      )}
      {series && (
        <span>
          <strong>Collezione:</strong> {series}
        </span>
      )}
      <span>
        <strong>Pubblica su PDE:</strong> {publishOnPDE ? "Si" : "No"}
      </span>
    </div>
  );
};

Book.propTypes = {
  cover: PropTypes.shape({
    title: PropTypes.string,
    publicationDate: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.oneOf(["sm", "lg"]),
};

Book.defaultProps = {
  size: "sm",
};

export default Book;
