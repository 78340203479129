import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

const ROOT_URL = `${API_ENDPOINT}/books`

class BooksCatalogService {
  async getBooks (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }
    return axios.get(ROOT_URL, { params: params })
  }

  async index () {
    return axios.get(ROOT_URL)
  }

  async getUpcomingBooks (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: pageNumber,
      size: pageSize,
      ...search
    }
    return axios.get(`${ROOT_URL}/upcoming-books`, { params: params })
  }

  async getMyUpcomingBooks (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: pageNumber,
      size: pageSize,
      ...search
    }
    return axios.get(`${ROOT_URL}/upcoming-books/my/`, { params: params })
  }

  async updateUpcomingBook (id, body) {
    return axios.patch(`${ROOT_URL}/upcoming-books/my/${id}`, { data: body })
  }

  async get (id) {
    return axios.get(`${ROOT_URL}/${id}`)
  }

  async create (body) {
    return axios.post(ROOT_URL, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${ROOT_URL}/${id}`, { data: body })
  }

  async delete (id, body) {
    return axios.delete(`${ROOT_URL}/${id}`, { data: body })
  }
}

export default new BooksCatalogService()
