import React, { Fragment, useState } from 'react'
import { Tooltip } from 'reactstrap'

export default (props) => {
  const [opened, setOpened] = useState(false)

  const { id, placement, text } = props

  const toggle = () => {
    setOpened(!opened)
  }

  const children = React.Children.map(props.children, child => React.cloneElement(child, { id: 'Tooltip-' + id }))

  return (
    <Fragment>
      {children}
      {text && text.trim() !== '' && (
        <Tooltip placement={placement} isOpen={opened} target={'Tooltip-' + id} toggle={toggle}>
          {text}
        </Tooltip>
      )}
    </Fragment>
  )
}
