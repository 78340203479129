import React, { useState, useEffect } from 'react'

import NotificationSettingsForm from 'components/forms/NotificationSettingsForm'
import UsersService from 'services/UsersService'
import SettingsService from 'services/SettingsService'
import Loader from 'components/Loader'

import styles from './Notifications.module.scss'

const Notifications = () => {
  const [isLoading, setLoading] = useState(true)
  const [users, setUsers] = useState({ isLoading: false, mapping: [] })
  const [settings, setSettings] = useState({ onConfirmNotifiers: [], onDeleteNotifiers: [], alertOnConfirm: false, alertOnDelete: false })

  useEffect(() => {
    setUsers({ isLoading: true, mapping: [] })
    UsersService
      .index()
      .then((response) => {
        const { data: { users } } = response
        const mapping = users.map(({ _id, name, surname }) => {
          return { value: _id, label: `${name}  ${surname}` }
        })
        setUsers({ isLoading: false, mapping: mapping })
      })
      .catch(() => {
        setUsers({ isLoading: false, mapping: [] })
      })

    SettingsService
      .get()
      .then(({ data }) => {
        const { alertOnConfirm, alertOnDelete, onConfirmNotifiers, onDeleteNotifiers } = data
        setLoading(false)
        setSettings({ alertOnConfirm: alertOnConfirm, alertOnDelete: alertOnDelete, onConfirmNotifiers: onConfirmNotifiers, onDeleteNotifiers: onDeleteNotifiers })
      })
  }, [])

  const saveChanges = (values) => {
    SettingsService
      .update(values)
      .then(() => {
        // TODO: update store with response data
      })
  }

  return (
    <div className={styles.Notifications}>
      <section className={styles.Container}>
        <h2>Impostazioni di notifica</h2>
        <section>
          {isLoading ? (
            <Loader />
          ) : (
            <NotificationSettingsForm onSubmit={saveChanges} users={users} initialValues={settings} />
          )}
        </section>
      </section>
    </div>
  )
}

export default Notifications
