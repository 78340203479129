import axios from "libs/axios";
import { API_ENDPOINT } from "config";

class PostsService {
  async getPosts(pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize,
      },
      search: search,
    };

    return axios.get(`${API_ENDPOINT}/posts`, { params: params });
  }

  async index() {
    return axios.get(`${API_ENDPOINT}/posts`);
  }

  async get(id) {
    return axios.get(`${API_ENDPOINT}/posts/${id}`);
  }

  async create(body) {
    return axios.post(`${API_ENDPOINT}/posts`, { data: body });
  }

  async update(id, body) {
    return axios.patch(`${API_ENDPOINT}/posts/${id}`, { data: body });
  }
}

export default new PostsService();
