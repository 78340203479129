import React, { useState } from 'react'
import cx from 'classnames'
import { Modal } from 'reactstrap'

import StickyCloseButton from './StickyCloseButton'

import styles from './PagePreview.module.scss'

const PagePreview = ({ className, image, fileName, imageType }) => {
  const [opened, setOpened] = useState(false)

  const toggle = () => {
    setOpened(!opened)
  }

  const ExternalButton = (
    <div className='position-fixed' style={{ top: '0px', right: '0px', backgroundColor: 'grey' }}>
      <StickyCloseButton onClick={toggle} />
    </div>
  )

  return (
    <div className={cx(styles.PagePreview, className)}>
      <img className={styles.Image} src={image} width={240} alt={fileName} onClick={toggle} />
      <div className='d-flex justify-content-center align-items-center flex-column mt-2'>
        <span className='d-block text-secondary' style={{ color: '#8f9ba6' }}>{fileName}</span>
        <span className='d-block text-secondary' style={{ color: '#8f9ba6' }}>{imageType}</span>
      </div>
      <Modal isOpen={opened} toggle={toggle} external={ExternalButton}>
        <img className={styles.ImagePreview} src={image} alt={fileName} />
      </Modal>
    </div>
  )
}

export default PagePreview
