import {
  AUTH_UPDATE_USER,
  AUTH_LOGIN_USER_REQUEST,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGIN_USER_FAILURE,
  AUTH_VERIFY_TOKEN_REQUEST,
  AUTH_VERIFY_TOKEN_SUCCESS,
  AUTH_VERIFY_TOKEN_FAILURE,
  AUTH_LOGOUT_USER
} from '../../constants'

import axios from 'libs/axios'

import { API_ENDPOINT } from 'config'
import User from 'models/user'
import { promiseWrapper } from 'helpers/promiseWrapper'

import LocalStorageService, { JWT_TOKEN, USER } from 'services/LocalStorageService'
import NotificationsService from 'services/NotificationsService'

export function authLogOutUserRequest () {
  return {
    type: AUTH_LOGOUT_USER
  }
}

export function authLoginUserRequest () {
  return {
    type: AUTH_LOGIN_USER_REQUEST
  }
}

export function authLogOutUser () {
  return promiseWrapper(async (dispatch) => {
    LocalStorageService.clear()
    dispatch(authLogOutUserRequest())
  })
}

export function authLoginUserSuccess (token, user) {
  return {
    type: AUTH_LOGIN_USER_SUCCESS,
    payload: {
      token,
      user
    }
  }
}

export function authLoginUserFailure (error) {
  return {
    type: AUTH_LOGIN_USER_FAILURE,
    payload: {
      error: error
    }
  }
}

export function authLoginUser (email, password) {
  return promiseWrapper(async (dispatch) => {
    dispatch(authLoginUserRequest())

    const body = { email, password }
    let response = await axios.post(`${API_ENDPOINT}/auth/login`, body)

    const responseData = await response.data
    const jwtToken = responseData.token
    // const refreshToken = responseData.refreshToken

    LocalStorageService.setItem(JWT_TOKEN, jwtToken)

    response = await axios.get(`${API_ENDPOINT}/users/me`)

    const { user: userData } = response.data
    const user = User.fromResponse(userData)

    LocalStorageService.setItem(USER, JSON.stringify(user))

    dispatch(authLoginUserSuccess(jwtToken, user))

    NotificationsService.fetchNotifications()
    NotificationsService.run(1000 * 60 * 10)
  },
  authLoginUserFailure
  )
}

export function updateUserData (user) {
  return {
    type: AUTH_UPDATE_USER,
    payload: { user }
  }
}

export function authVerifyTokenRequest () {
  return {
    type: AUTH_VERIFY_TOKEN_REQUEST
  }
}

export function authVerifyTokenSuccess (token, user) {
  return {
    type: AUTH_VERIFY_TOKEN_SUCCESS,
    payload: {
      token,
      user
    }
  }
}

export function authVerifyTokenFailure (error) {
  return {
    type: AUTH_VERIFY_TOKEN_FAILURE,
    payload: {
      status: error.statusCode,
      statusText: error.statusText,
      error: error,
      json: error.json
    }
  }
}

export function authRestoreUser () {
  return promiseWrapper(async (dispatch) => {
    const jwtToken = LocalStorageService.getItem(JWT_TOKEN)

    if (jwtToken) {
      dispatch(authVerifyTokenRequest())

      try {
        const response = await axios.get(`${API_ENDPOINT}/users/me`)
        const { user: userData } = response.data

        const user = User.fromResponse(userData)

        dispatch(authVerifyTokenSuccess(jwtToken, user))

        NotificationsService.fetchNotifications()
        NotificationsService.run(1000 * 60 * 10)
      } catch (e) {
        // TODO
        // dispatch(authLogOutUser())
      }
    }
  },
  authVerifyTokenFailure
  )
}
