const NAVIGATION_MENU_CFG = {
  POSTS: {
    label: "Notiziario PDE",
    link: "/posts",
  },
  USERS: {
    label: "Utenti",
    link: "/users",
  },
  PUBLISHERS: {
    label: "Editori",
    link: "/publishers",
  },
  AUTHORS: {
    label: "Autori",
    link: "/authors",
  },
  COVERS: {
    label: "Libri",
    link: "/covers",
  },
  BOOKSELLERS: {
    label: "Librerie",
    link: "/booksellers",
  },
  EVENTS: {
    label: "Eventi",
    link: "/events",
  },
  UPCOMING_BOOKS: {
    label: "Libri in uscita",
    link: "/upcoming-books",
  },
};

export const navigationMenuBuilder = ({
  isStaff,
  isEditorPDE,
  isPublicationManager,
  isOnlineSeller,
  isBookSeller,
}) => {
  if (isStaff) {
    return [
      NAVIGATION_MENU_CFG.POSTS,
      NAVIGATION_MENU_CFG.USERS,
      NAVIGATION_MENU_CFG.EVENTS,
      NAVIGATION_MENU_CFG.PUBLISHERS,
      NAVIGATION_MENU_CFG.AUTHORS,
      NAVIGATION_MENU_CFG.COVERS,
      NAVIGATION_MENU_CFG.BOOKSELLERS,
      NAVIGATION_MENU_CFG.UPCOMING_BOOKS,
    ];
  }

  if (isEditorPDE) {
    return [
      NAVIGATION_MENU_CFG.POSTS,
      NAVIGATION_MENU_CFG.PUBLISHERS,
      NAVIGATION_MENU_CFG.COVERS,
      NAVIGATION_MENU_CFG.EVENTS,
      NAVIGATION_MENU_CFG.BOOKSELLERS,
      NAVIGATION_MENU_CFG.UPCOMING_BOOKS,
    ];
  }

  if (isPublicationManager) {
    return [
      NAVIGATION_MENU_CFG.COVERS,
      NAVIGATION_MENU_CFG.BOOKSELLERS,
      NAVIGATION_MENU_CFG.EVENTS,
    ];
  }

  if (isOnlineSeller) {
    return [NAVIGATION_MENU_CFG.COVERS];
  }

  if (isBookSeller) {
    return [
      NAVIGATION_MENU_CFG.POSTS,
      NAVIGATION_MENU_CFG.COVERS,
      NAVIGATION_MENU_CFG.UPCOMING_BOOKS,
    ];
  }

  return [];
};
