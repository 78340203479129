import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class EventsService {
  async getEvents (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }

    return axios.get(`${API_ENDPOINT}/events`, { params: params })
  }

  async performances (id, params = {}) {
    return axios.get(`${API_ENDPOINT}/events/${id}/performances`, { params: params })
  }

  async get (id) {
    return axios.get(`${API_ENDPOINT}/events/${id}`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/events`, { data: body })
  }

  async import (body) {
    return axios.post(`${API_ENDPOINT}/events/import`, body)
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/events/${id}`, { data: body })
  }

  async delete (id, body) {
    return axios.delete(`${API_ENDPOINT}/events/${id}`, { data: body })
  }

  async download (id) {
    return axios.get(`${API_ENDPOINT}/events/${id}/performances/download`, { responseType: 'blob' })
  }

  async export (params = {}) {
    return axios.get(`${API_ENDPOINT}/events/export`, { params: params, responseType: 'blob' })
  }
}

export default new EventsService()
