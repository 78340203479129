import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import cssModule from './Heading.module.scss'
import HeadingIcon from '../HeadingIcon'

const Heading = props => {
  const { tag: Tag, classNames, sub, icon, children, ...attributes } = props
  const headingIcon = icon && (<HeadingIcon icon={icon} classNames={cssModule.HeadingIcon} />)
  return (
    <Tag {...attributes} className={cx(cssModule.Heading, classNames)}>
      {headingIcon}
      {children}
    </Tag>
  )
}

Heading.propTypes = {
  icon: PropTypes.string,
  classNames: PropTypes.string,
  tag: PropTypes.string
}

Heading.defaultProps = {
  tag: 'div'
}

export default Heading
