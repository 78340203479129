import React from 'react'
import { Link } from 'react-router-dom'

export default ({ value, row }) => {
  const { publisher } = row
  if (publisher) {
    return <Link to={`/publishers/${publisher['_id']}`}>{publisher.companyName}</Link>
  } else {
    return <span />
  }
}
