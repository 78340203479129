import React, { Component } from "react";
import { Button } from "reactstrap";
import { saveAs } from "file-saver";

import PageContainer from "components/layouts/PageContainer/PageContainer";
import Heading from "components/Heading";
import Book from "components/Book";
import Tooltip from "components/Tooltip";
import ConfirmationModal from "components/modals/ConfirmationModal";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import AddCoverFormModal from "components/modals/AddCoverFormModal";
import CoversService from "services/CoversService";
import ModalsService from "services/ModalsService";
import Cover from "models/cover";
import withAuth from "containers/auth.container";
import { extractFileName } from "helpers/responseHelper";
import GridList from "components/GridList";

import styles from "./CoverDetails.module.scss";
import PagePreview from "./PagePreview";

class CoverDetailsView extends Component {
  state = {
    isLoading: false,
    cover: null,
  };

  componentDidMount() {
    const { id } = this.props;

    this.setState({ isLoading: true });

    CoversService.get(id)
      .then((response) => {
        const {
          data: { cover },
        } = response;
        this.setState({ isLoading: false, cover: new Cover(cover) });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  onEditCover = () => {
    const cover = this.state.cover;

    ModalsService.open("EditCoverFormModal", {
      title: "Update Cover",
      initialValues: {
        ...cover,
        publisher: cover.publisher._id,
        authors: cover.authors.map(({ _id }) => _id),
      },
      onSubmit: (body) => {
        const { month, year } = body;
        let requestBody = {
          ...body,
        };

        if (year && month) {
          requestBody = {
            ...requestBody,
            publicationDate: new Date(Date.UTC(year, month)),
          };
        } else {
          delete requestBody["publicationDate"];
        }

        CoversService.update(cover._id, requestBody).then((response) => {
          const {
            data: { cover },
          } = response;
          ModalsService.close("EditCoverFormModal");
          this.setState({ cover: new Cover(cover) });
        });
      },
      onCancel: () => {
        ModalsService.close("EditCoverFormModal");
      },
    });
  };

  onExport = () => {
    CoversService.download(this.props.id).then((response) => {
      const filename = extractFileName(response.headers["content-disposition"]);
      saveAs(response.data, filename);
    });
  };

  render() {
    const { isOnlineSeller, isStaff, isBookSeller } = this.props;
    const { cover, isLoading } = this.state;

    if (!isLoading && !cover) {
      return null;
    }

    return (
      <PageContainer animate loading={isLoading}>
        {() => {
          const { description, publisher, additionalImages } = cover;

          return (
            <div className={styles.CoverDetailsPage}>
              <section>
                <Heading>{publisher ? publisher.companyName : ""}</Heading>
                <div className={styles.CoverDetails}>
                  <Book size="lg" cover={cover} className={styles.Book} />
                  {/* <p className={styles.Description}>{description}</p> */}

                  <span className="clearfix" />
                </div>
              </section>
              <section>
                <div className="p-4">
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                  {/* <p className={styles.Description}>{description}</p> */}
                  <span className="clearfix" />
                </div>
              </section>
              <section>
                <Heading icon="fas fa-book fa-xs">Pagine</Heading>
                <GridList>
                  {additionalImages.map(({ _id, ...rest }) => {
                    return <PagePreview key={_id} {...rest} />;
                  })}
                </GridList>
              </section>
              <div className={styles.ButtonGroup}>
                {!isBookSeller && (
                  <Button
                    className="mr-2"
                    color="warning"
                    size="xs"
                    onClick={this.onEditCover}
                  >
                    <i className="fa fa-pen" style={{ color: "#ffffff" }} />
                  </Button>
                )}

                {(isOnlineSeller || isStaff || isBookSeller) && (
                  <Tooltip id="test" placement="top" text="Esporta in Excel">
                    <Button color="success" size="xs" onClick={this.onExport}>
                      <i
                        className="fas fa-download"
                        style={{ color: "#ffffff" }}
                      />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <ReduxModalWrapper
                modal="EditCoverFormModal"
                component={AddCoverFormModal}
              />
              <ReduxModalWrapper
                modal="confirmDeleteAdditionalPage"
                component={ConfirmationModal}
              />
            </div>
          );
        }}
      </PageContainer>
    );
  }
}

export default withAuth(CoverDetailsView);
