import React, { Fragment, useState } from 'react'

import { Formatters } from 'components/data-grid-table'
import CancelSubmitionError from 'components/forms/errors/CancelSubmitionError'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import ReduxModalWrapper from 'components/modals/reduxModal/ReduxModalWrapper'
import AddBooksellerFormModal from 'components/modals/AddBooksellerFormModal'
import BooksellersService from 'services/BooksellersService'
import ModalsService from 'services/ModalsService'
import Bookseller from 'models/bookseller'

import DataTable from './DataTable'
import BooksellerExternalUsersModal from '../modals/BooksellerExternalUsersModal'
import withAuth from '../../containers/auth.container'

const getColumns = (isStaff, outerProps) => (props) => {
  const columns = [
    {
      key: 'companyName',
      name: 'Ragione Sociale',
      filterable: true
    },
    {
      key: 'city',
      name: 'Città',
      filterable: true
    },
    {
      key: 'code',
      name: 'Codice',
      filterable: true
    },
    {
      key: 'surname',
      name: 'Cognome',
      filterable: true
    },
    {
      key: 'actions',
      name: 'Azioni',
      formatter: Formatters.BooksellersActionFormatter({ ...props, ...outerProps }),
      cellClass: 'justify-content-center'
    }
  ]

  if (isStaff) {
    columns.splice(4, 0, {
      key: 'externalUsers',
      name: 'External Users',
      formatter: (props) => {
        if (props.row._externalUsers) {
          return props.row._externalUsers.map(eu => eu.email).join('\n')
        }
        return null
      }
    })
  }

  return columns
}

const BooksellersDataTable = ({ isStaff }) => {
  const [booksellers, setBooksellers] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [booksellerExternalUsersModalOpened, setBooksellerExternalUsersModalOpened] = useState(false)
  const [booksellerExternalUsersModalProps, setBooksellerExternalUsersModalProps] = useState(null)

  const toggleBooksellerExternalUsersModal = (bookseller) => {
    const title = `${bookseller.companyName}: Associate External Users`
    setBooksellerExternalUsersModalProps(!booksellerExternalUsersModalOpened ? { title: title, bookseller: bookseller } : null)
    setBooksellerExternalUsersModalOpened(!booksellerExternalUsersModalOpened)
  }

  const onUpdateBooksellerExternalUsers = (id, booksellerData) => {
    const newBooksellers = [...booksellers]
    const bookSellerIndex = newBooksellers.findIndex(publisher => publisher._id === id)

    if (bookSellerIndex !== -1) {
      const updatedBookseller = new Bookseller(booksellerData)
      newBooksellers[bookSellerIndex] = updatedBookseller
      setBooksellers(newBooksellers)

      if (booksellerExternalUsersModalOpened) {
        setBooksellerExternalUsersModalProps({ ...booksellerExternalUsersModalProps, bookseller: updatedBookseller })
      }
    }
  }

  const fetchBooksellers = ({ page: { number, size }, formFilterData }) => {
    BooksellersService
      .getBooksellers(number, size, formFilterData)
      .then((response) => {
        const { data: { results, totalRecords } } = response
        const booksellers = results.map(response => Bookseller.fromResponse(response))

        setBooksellers(booksellers)
        setTotalRows(totalRecords)
      })
      .catch(() => { })
  }

  const onChange = (params) => {
    fetchBooksellers(params)
  }

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues }

      let request = BooksellersService[action]

      if (request) {
        if (action === 'create') {
          request = request(body)
        }

        if (action === 'update') {
          const { _id } = body
          request = request(_id, body)
        }

        if (action === 'delete') {
          const { _id } = body

          request = new Promise((resolve, reject) => {
            BooksellersService
              .performances(_id, { date: 'incoming' })
              .then(response => {
                const { data: { performances } } = response

                if (performances.length > 0) {
                  ModalsService.open(
                    'confirmBooksellerDeleteModal',
                    {
                      title: 'Elimina libreria',
                      text: 'Ci sono eventi associati a questa libreria. Proseguendo verranno eliminati.\n\nConfermi questa azione?',
                      onSubmit: () => {
                        BooksellersService[action](_id, body)
                          .then(response => {
                            ModalsService.close('confirmBooksellerDeleteModal')
                            resolve(response)
                          })
                          .catch((error) => {
                            ModalsService.close('confirmBooksellerDeleteModal')
                            resolve(error)
                          })
                      },
                      onCancel: () => {
                        reject(new CancelSubmitionError())
                      }
                    }
                  )
                } else {
                  resolve(BooksellersService[action](_id, body))
                }
              })
              .catch(reject)
          })
        }

        return request
      }
    }
  }

  const getFormModalModeProps = (mode) => {
    if (mode === 'create') {
      return {
        title: 'Crea libreria',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'update') {
      return {
        title: 'Aggiorna libreria',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'delete') {
      return {
        title: 'Elimina libreria',
        onSubmit: handleSubmit(mode)
      }
    }

    return {
      title: 'Libreria'
    }
  }

  const outerColumnProps = {
    onInfo: toggleBooksellerExternalUsersModal
  }

  return (
    <Fragment>
      <DataTable columns={getColumns(isStaff, outerColumnProps)}
        data={booksellers}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={{
          formModalComponent: AddBooksellerFormModal,
          getFormModalModeProps: getFormModalModeProps
        }}
      />
      <BooksellerExternalUsersModal {...booksellerExternalUsersModalProps}
        onUpdateBooksellerExternalUsers={onUpdateBooksellerExternalUsers}
        isOpen={booksellerExternalUsersModalOpened}
        toggle={toggleBooksellerExternalUsersModal} />
      <ReduxModalWrapper modal='confirmBooksellerDeleteModal' component={ConfirmationModal} />
    </Fragment>
  )
}
export default withAuth(BooksellersDataTable)
