import React, { useState } from 'react'
import { Input, Button } from 'reactstrap'

const FileInput = ({ handleChange, types, defaultType }) => {
  const DEFAULT_TYPE = defaultType || Object.keys(types)[0]

  const [type, setType] = useState(DEFAULT_TYPE)
  const [file, setFile] = useState(null)

  const clearFileInput = ctrl => {
    try {
      ctrl.value = null
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl)
    }
  }

  const handleChangeFile = e => {
    setFile(e.target.files[0])
  }

  const handleTypeChange = e => {
    setType(e.target.value)
  }

  const submit = () => {
    handleChange({ file, type })
    clearFileInput(document.getElementById('file-input'))
    setType(DEFAULT_TYPE)
  }

  return (
    <div className='d-flex flex-row align-items-center justify-content-between'>
      <Input type='select' className='w-25 mr-2' onChange={handleTypeChange}>
        {Object.keys(types).map(type => (
          <option key={type}>{type}</option>
        ))}
      </Input>
      <Input
        id='file-input'
        type='file'
        accept={types[type]}
        onChange={handleChangeFile}
      />
      <Button onClick={submit} disabled={!file}>
        Aggiungi
      </Button>
    </div>
  )
}

export default FileInput
