import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { navigationMenuBuilder } from '../../helpers/menuBuilder'

const NavigationMenu = (props) => {
  const { className, isStaff, isEditorPDE, isPublicationManager, isOnlineSeller, isBookSeller } = props

  const navigationMenuItems = navigationMenuBuilder({
    isStaff: isStaff,
    isEditorPDE: isEditorPDE,
    isPublicationManager: isPublicationManager,
    isOnlineSeller: isOnlineSeller,
    isBookSeller: isBookSeller
  }).map(({ label, link }) => {
    return (
      <li key={label} className={cx('nav-item', className)}>
        <NavLink className='nav-link' activeClassName='active' to={link}>
          { label }
        </NavLink>
      </li>
    )
  })

  return (
    <ul className='nav navbar-nav'>
      { navigationMenuItems }
    </ul>
  )
}

NavigationMenu.propTypes = {
  className: PropTypes.string,
  isStaff: PropTypes.bool
}

NavigationMenu.defaultProps = {
  isStaff: false
}

export default NavigationMenu
