import React from 'react'

export const NoOptionsMessage = (props) => {
  const { children, className, cx, getStyles, innerProps } = props

  return (
    <div
      css={getStyles('noOptionsMessage', props)}
      className={cx(
        {
          'menu-notice': true,
          'menu-notice--no-options': true
        },
        className
      )}
      {...innerProps}
    >
      {children}
    </div>
  )
}

NoOptionsMessage.defaultProps = {
  children: 'No options'
}
