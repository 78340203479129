import React from 'react'
import cx from 'classnames'
import { Input, Table } from 'reactstrap'

import { simpleTablePropTypes } from './simpleTablePropTypes'

const SimpleTable = (props) => {
  const { columns, data, className, selectable, onSelectedRowChange, selectedRows } = props

  const tableColumns = [...columns]
  if (selectable) {
    tableColumns.unshift({
      name: '',
      key: 'simple-table-checkbox',
      formatter: ({ row }) => <Input type='checkbox' addon checked={selectedRows.includes(row._id)} onChange={() => onSelectRow(row)} />,
      cellClass: 'justify-content-center'
    })
  }

  function onSelectRow ({ _id }) {
    const newSelectedRows = !selectedRows.includes(_id) ? [...selectedRows, _id] : selectedRows.filter(id => id !== _id)
    onSelectedRowChange && onSelectedRowChange(newSelectedRows)
  }

  return (
    <Table className={className} bordered style={{ height: '1px' }}>
      <thead>
        <tr>
          {tableColumns.map(({ name, key, width }, idx) => {
            if (key === 'simple-table-checkbox') {
              return (
                <th key={idx}
                  className='text-center'
                  style={{ width: '50px' }}>
                  {/* <div className="btn btn-primary" onClick={onSelectAll} style={{ padding: '0.1rem 0.36rem', cursor: 'pointer' }}> */}
                  {/*  <i className='fa fa-check' style={{ color: '#ffffff' }} /> */}
                  {/* </div> */}
                </th>
              )
            } else {
              return (
                <th key={idx}
                  className='text-left'
                  style={{ width: width }}>
                  {name}
                </th>
              )
            }
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIdx) => {
          return (
            <tr key={rowIdx}>
              {tableColumns.map((column, columnIdx) => {
                const { key, formatter: Formatter, cellClass } = column
                const cellValue = row[key]

                return (
                  <td key={`${rowIdx}:${columnIdx}`}
                    className='text-left'>
                    <div className={cx('d-flex align-items-center h-100', cellClass)}>{Formatter ? <Formatter value={cellValue} row={row} /> : cellValue}</div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

SimpleTable.propTypes = {
  ...simpleTablePropTypes
}

SimpleTable.defaultProps = {
  columns: [],
  data: []
}

export default SimpleTable
