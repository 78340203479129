import React from 'react'
import PropTypes from 'prop-types'

import styles from './LoadingDots.module.scss'

const LoadingDot = ({ color, delay, offset }) => {
  return (
    <span className={styles.LoadingDot}
      style={{ backgroundColor: color, animationDelay: `${delay}ms`, marginLeft: offset }} />
  )
}

LoadingDot.propTypes = {
  color: PropTypes.string,
  delay: PropTypes.number,
  offset: PropTypes.string
}

LoadingDot.defaultProps = {
  color: '#c8ced3',
  delay: 0,
  offset: '1em'
}

export default LoadingDot
