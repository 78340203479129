import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form
} from 'reactstrap'
import { formPropTypes, reduxForm } from 'redux-form'

const DataTableFilterForm = (props) => {
  const {
    className,
    children,
    handleSubmit,
    reset,
    onReset
  } = props

  const resetForm = () => {
    reset()
    onReset()
  }

  return (
    <Card className={cx(className, 'w-100')}>
      <Form onSubmit={handleSubmit}>
        <CardHeader>
          <i className='fas fa-filter' />
        </CardHeader>
        <CardBody className='w-100'>
          {children}
        </CardBody>
        <CardFooter>
          <Button className='mr-3' color='success' size='xs'>
            <i className='fa fa-search' style={{ color: '#ffffff' }} />
          </Button>
          <Button color='danger' size='xs' onClick={resetForm}>
            <i className='fa fa-times' style={{ color: '#ffffff' }} />
          </Button>
        </CardFooter>
      </Form>
    </Card>
  )
}

DataTableFilterForm.propTypes = {
  ...formPropTypes,
  className: PropTypes.string
}

export default reduxForm({
  form: 'dataTableFilterForm'
})(DataTableFilterForm)
