import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import styles from './UserActionsFormatter.module.scss'

const UserActionsFormatter = (props) => {
  const {
    row,
    canShow,
    canEdit,
    canRemove,
    canInfo,
    canMedia,
    onShow,
    onEdit,
    onRemove,
    onInfo,
    onMedia
  } = props
  if ('deleted' in row && row['deleted']) {
    return <span className='text-secondary'>Not available</span>
  }

  return (
    <div className={styles.userActionsFormatter}>
      {canShow && (
        <Button color='success' size='xs' onClick={() => onShow && onShow(row)}>
          <i className='fa fa-search-plus' style={{ color: '#ffffff' }} />
        </Button>
      )}

      {canEdit && (
        <Button color='warning' size='xs' onClick={() => onEdit && onEdit(row)}>
          <i className='fa fa-pen' style={{ color: '#ffffff' }} />
        </Button>
      )}

      {canRemove && (
        <Button color='danger' size='xs' onClick={() => onRemove && onRemove(row)}>
          <i className='fas fa-trash' style={{ color: '#ffffff' }} />
        </Button>
      )}
      {canInfo && (
        <Button color='info' size='xs' onClick={() => onInfo && onInfo(row)}>
          <i className='fas fa-file' style={{ color: '#ffffff' }} />
        </Button>
      )}
      {canMedia && (
        <Button color='info' size='xs' onClick={() => onMedia && onMedia(row)}>
          <i className='fas fa-photo-video' style={{ color: '#ffffff' }} />
        </Button>
      )}
    </div>
  )
}

UserActionsFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onInfo: PropTypes.func,
  onMedia: PropTypes.func,
  canShow: PropTypes.bool,
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
  canInfo: PropTypes.bool,
  canMedia: PropTypes.bool
}

UserActionsFormatter.defaultProps = {
  canShow: false,
  canEdit: true,
  canRemove: true,
  canInfo: true,
  canMedia: false
}

export default (outerProps) => (props) => {
  return <UserActionsFormatter {...outerProps} {...props} />
}
