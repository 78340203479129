import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ApplicationRouter from './ApplicationRouter'
import App from './App'

export default ({ store }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App>
          <ToastContainer />
          <ApplicationRouter />
        </App>
      </BrowserRouter>
    </Provider>
  )
}
