import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { Field, formPropTypes, reduxForm } from 'redux-form'

import {
  renderFormGroupTextField,
  renderSelectMappingField,
  renderFileUploadInput,
  renderDateField
} from 'components/forms/formFields'

import {
  required
} from 'components/forms/validators'
import ResponseError from 'components/ResponseError'
import PublishersService from 'services/PublishersService'
import PublisherDataToDropField from 'helpers/data/publisherFormatHelper'
import Publisher from 'models/publisher'

import FormModal from '../FormModal'
import styles from '../FormModalBase.module.scss'
import authorModalStyles from './AddAuthorFormModal.module.scss'

const AddAuthorFormModal = (props) => {
  const { title, error, disabled } = props

  const [publishers, setPublishers] = useState({ isLoading: false, isLoaded: false, mapping: [], data: [] })

  const fetchPublishers = () => {
    setPublishers({ isLoading: false, isLoaded: false, mapping: [], data: [] })
    PublishersService.index().then((response) => {
      const { data } = response
      const publisherMapping = PublisherDataToDropField(data.publishers)
      const publishers = data.publishers.map(publisherData => new Publisher(publisherData))
      setPublishers({ isLoading: false, isLoaded: true, mapping: publisherMapping, data: publishers })
    }).catch(() => {
      setPublishers({ isLoading: false, isLoaded: false, mapping: [], data: [] })
    })
  }

  useEffect(() => {
    fetchPublishers()
  }, [])

  return (
    <FormModal {...props} formTitle={title} className={cx(styles.FormModalBase, authorModalStyles.AuthorFormModal)}>
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Cognome'
            name='surname'
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderDateField}
            label='Data di nascita'
            name='birthDate'
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Editore'
            name='publisher'
            isLoading={publishers.isLoading}
            mapping={publishers.mapping}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Field
        component={renderFileUploadInput}
        preview
        label='Foto'
        accept='image/jpg, image/jpeg'
        name='photo'
        disabled={disabled}
      />
      <Field
        component={renderFormGroupTextField}
        label='Descrizione'
        name='description'
        type='textarea'
        disabled={disabled}
      />
    </FormModal>
  )
}

AddAuthorFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

AddAuthorFormModal.defaultProps = {
  title: 'Autore',
  publishers: []
}

export default reduxForm({
  form: 'addAuthorModalForm'
})(AddAuthorFormModal)
