import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Collapse } from 'reactstrap'

import styles from './Accordion.module.scss'

class Accordion extends Component {
  state = {
    opened: false,
    status: ''
  }

  componentDidMount () {
    const { expandOnMound } = this.props
    if (expandOnMound) {
      this.setState({ opened: true })
    }
  }

  toggle = () => {
    const { onClosed } = this.props
    if (onClosed) {
      onClosed && onClosed()
    } else {
      this.setState({ opened: !this.state.opened })
    }
  }

  onEntered = () => {
    this.setState({ status: 'opened' })
  }

  onHeaderClick = () => {
    const { onToggle, editable } = this.props
    if (editable) {
      onToggle ? onToggle() : this.toggle()
    }
  }

  render () {
    const { header, children, className, editable } = this.props
    const { opened, status } = this.state

    return (
      <div className={cx(styles.Accordion, className, { [styles.Opened]: status === 'opened' })}>
        <div className={cx(styles.AccordionHeader, { [styles.Opened]: opened, [styles.Disabled]: !editable })} onClick={this.onHeaderClick}>
          <span>{header}</span>
          <i className='fas fa-caret-right' />
        </div>
        <Collapse isOpen={opened} onEntered={this.onEntered}>
          {children}
        </Collapse>
      </div>
    )
  }
}

Accordion.propTypes = {
  header: PropTypes.string,
  editable: PropTypes.bool
}

Accordion.defaultProps = {
  editable: true
}

export default Accordion
