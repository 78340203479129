import React, { Fragment } from 'react'
import { Button } from 'reactstrap'

const UpcomingBooksFormatter = (props) => {
  const { openAddMediaModal, row } = props
  return (
    <Fragment style={
      { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }
    }>
      <Button color='info' size='xs' onClick={() => openAddMediaModal && openAddMediaModal(row)}>
        <i className='fa fa-photo-video' style={{ color: '#ffffff' }} />
      </Button>
    </Fragment>
  )
}
export default (outerProps) => (props) => {
  return <UpcomingBooksFormatter {...outerProps} {...props} />
}
