import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class FileUploadService {
  prepareFile (file) {
    if (!(file instanceof File)) {
      throw new Error('Incorrect file format')
    }
    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return { formData, config }
  }

  async upload (file) {
    const { formData, config } = this.prepareFile(file)
    return axios.post(`${API_ENDPOINT}/file/upload`, formData, config)
  }

  async uploadToRollout (file) {
    const { formData, config } = this.prepareFile(file)
    return axios.post(`${API_ENDPOINT}/file/upload-to-rollout/upcoming-books`, formData, config)
  }
}

export default new FileUploadService()
