import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class ExternalTokensService {
  async get () {
    return axios.get(`${API_ENDPOINT}/tokens`)
  }

  async create () {
    return axios.post(`${API_ENDPOINT}/tokens`)
  }

  async delete (token) {
    return axios.delete(`${API_ENDPOINT}/tokens/${token}`)
  }
}

export default new ExternalTokensService()
