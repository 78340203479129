import React from 'react'
import PropTypes from 'prop-types'

import SimpleError from 'components/SimpleError'

const ResponseError = (props) => {
  const { error: { message, details } } = props

  return <SimpleError message={`${message}: ${details}`} />
}

ResponseError.propTypes = {
  error: PropTypes.object
}

export default ResponseError
