import {
  INITIALIZE,
  DESTROY,
  OPEN,
  CLOSE
} from './actionTypes'

const initialModalState = {
  isOpen: false,
  initialValues: { abc: 'initial' }
}

export default (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE: {
      const { meta } = action
      const { modal } = meta

      return {
        ...state,
        [modal]: {
          ...initialModalState
        }
      }
    }

    case OPEN: {
      const { meta, payload } = action
      const { modal } = meta

      const modalState = state[modal]

      return {
        ...state,
        [modal]: {
          ...modalState,
          isOpen: true,
          initialValues: {
            ...payload
          }
        }
      }
    }

    case CLOSE: {
      const { meta } = action
      const { modal } = meta

      const modalState = state[modal]

      return {
        ...state,
        [modal]: {
          ...modalState,
          isOpen: false
        }
      }
    }

    case DESTROY: {
      const { meta } = action
      const { modal } = meta
      const newState = { ...state }

      delete newState[modal]

      return {
        ...newState
      }
    }

    default:
      return state
  }
}
