import store from 'store/store'
import { fetchNotifications, notificationSetViewed } from 'store/actions/notifications'

class NotificationsService {
  constructor () {
    this.dispatch = store.dispatch
    this.timerId = null
  }

  removeNotification (id) {
    this.dispatch(notificationSetViewed(id))
  }

  fetchNotifications () {
    this.dispatch(fetchNotifications())
  }

  run (delay) {
    clearInterval(this.timerId)

    this.timerId = setInterval(() => {
      this.fetchNotifications()
    }, delay)
  }
}

export default new NotificationsService()
