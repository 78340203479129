import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import {
  change,
  Field,
  FieldArray,
  formPropTypes,
  formValueSelector,
  reduxForm,
} from "redux-form";

import {
  renderFileUploadInput,
  renderFormGroupTextField,
  renderNumberFormatInput,
  renderSelectMappingField,
  renderCheckbox,
  renderDateField,
} from "components/forms/formFields";
import { required } from "components/forms/validators";
import ResponseError from "components/ResponseError";
import PublishersService from "services/PublishersService";

import { COVER_STATUSES } from "constants/coverStatuses";
import ModalsService from "services/ModalsService";

import FormModal from "../FormModal";

import coverFormModalStyles from "./AddCoverFormModal.module.scss";
import styles from "../FormModalBase.module.scss";
import { renderExtraPages } from "./renderExtraPages";
import AuthorDataToDropField from "../../../helpers/data/authorFormatHelper";

const imageTypesMapping = {
  in: "IN",
  pt: "PT",
  top: "TOPP",
  btm: "BOTT",
  left: "LEFT",
  right: "RIGHT",
  frnt: "FRNT",
  back: "BACK",
  side: "SIDE",
};

const AddCoverFormModal = (props) => {
  const {
    title,
    isOpen,
    error,
    disabled,
    modalState: { values },
    ean,
    additionalImages,
    resetAuthors,
  } = props;

  const [publishers, setPublishers] = useState({
    isLoading: true,
    data: [],
    isLoaded: false,
  });
  const [authors, setAuthors] = useState({
    isLoading: false,
    data: [],
    isLoaded: false,
  });

  useEffect(() => {
    if (isOpen && !publishers.isLoaded) {
      setPublishers({ isLoading: true, isLoaded: false, data: [] });
      PublishersService.index()
        .then((response) => {
          const {
            data: { publishers },
          } = response;
          const publisherMapping = publishers.map((v) => ({
            value: v._id,
            label: `${v.companyName}`,
          }));
          publisherMapping.unshift({ label: "No Editore", value: "" });

          setPublishers({
            isLoading: false,
            isLoaded: true,
            data: publisherMapping,
          });
        })
        .catch(() => {
          setPublishers({ isLoading: false, isLoaded: false, data: [] });
          setAuthors({ isLoading: false, isLoaded: false, data: [] });
        });
    }
  }, [isOpen]);

  const getFileName = (ean, type, index, ext) => {
    let fileName = `${ean}.${imageTypesMapping[type]}`;

    if (type === "in" || type === "pt") {
      fileName += `0${index}`;
    }
    if (ext) {
      fileName += "." + ext;
    }

    return fileName;
  };

  const getNextNumber = (type) => {
    if (additionalImages && additionalImages.length > 0) {
      const imagesByType = additionalImages.filter(
        (imgData) => imgData.imageType === type
      );
      if (imagesByType.length === 0) {
        return 0;
      } else {
        const substr = `.${imageTypesMapping[type]}`;
        const setNumbers = imagesByType.reduce((memo, { fileName }) => {
          const n = parseInt(fileName.substring(fileName.indexOf(substr) + 3));
          memo.push(n);
          return memo;
        }, []);

        setNumbers.sort();

        for (const [index, number] of setNumbers.entries()) {
          if (index < number) {
            return index;
          }
        }
        return setNumbers[setNumbers.length - 1] + 1;
      }
    }

    return 0;
  };

  const onChangeImageType = (index, type) => {
    let ext = null;
    const { image, imageType } = additionalImages[index];

    if (type !== imageType) {
      if (image) {
        ext =
          typeof image === "string"
            ? image.split(".").pop()
            : image[0].name.split(".").pop();
      }

      props.change(
        "addCoverModalForm",
        `additionalImages[${index}].fileName`,
        getFileName(ean, type, getNextNumber(type), ext)
      );
    }
  };

  const onChangeFile = (index, file) => {
    const ext = file.split(".").pop();
    const { isNew, imageType, image, fileName } = additionalImages[index];
    const substr = `.${imageTypesMapping[imageType]}`;
    const nextNumber = isNew
      ? parseInt(fileName.substring(fileName.indexOf(substr) + 3))
      : parseInt(image.substring(image.indexOf(substr) + 3));

    props.change(
      "addCoverModalForm",
      `additionalImages[${index}].fileName`,
      getFileName(ean, imageType, nextNumber, ext)
    );
  };

  useEffect(() => {
    if (isOpen && additionalImages) {
      additionalImages.forEach(({ isNew, imageType, image }, index) => {
        let ext = null;
        if (image) {
          ext =
            typeof image === "string"
              ? image.split(".").pop()
              : image[0].name.split(".").pop();
        }
        const substr = `.${imageTypesMapping[imageType]}`;
        const nextNumber = isNew
          ? getNextNumber(imageType)
          : parseInt(image.substring(image.indexOf(substr) + 3));

        props.change(
          "addCoverModalForm",
          `additionalImages[${index}].fileName`,
          getFileName(ean, imageType, nextNumber, ext)
        );
      });
    }
  }, [ean, isOpen]);

  useEffect(() => {
    if (isOpen && values.publisher) {
      setAuthors({ isLoading: true, isLoaded: false, data: [] });

      const { initialValues } = props;
      if (!initialValues) {
        resetAuthors();
      }

      PublishersService.getAuthors(
        typeof values.publisher === "string"
          ? values.publisher
          : values.publisher._id
      )
        .then((response) => {
          const {
            data: { authors },
          } = response;
          const authorMapping = AuthorDataToDropField(authors);

          setAuthors({ isLoading: false, isLoaded: true, data: authorMapping });
        })
        .catch(() => {
          setAuthors({ isLoading: false, isLoaded: false, data: [] });
        });
    }
  }, [values.publisher]);

  const onAddExtraPage = (fields) => {
    fields.push({
      isNew: true,
      image: "",
      fileName: getFileName(ean, "in", getNextNumber("in")),
      imageType: "in",
    });
  };

  const onRemoveExtraPage = (fields, index) => {
    ModalsService.open("confirmDeleteAdditionalPage", {
      title: "Elimina pagina",
      text: "Elimina pagina?",
      onSubmit: () => {
        fields.remove(index);
        ModalsService.close("confirmDeleteAdditionalPage");
      },
    });
  };

  return (
    <FormModal
      className={cx(styles.FormModalBase, coverFormModalStyles.CoverFormModal)}
      {...props}
      formTitle={title}
    >
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Titolo"
            name="title"
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            label="Editore"
            name="publisher"
            mapping={publishers.data}
            isLoading={publishers.isLoading}
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderSelectMappingField}
            label="Autori"
            name="authors"
            isMulti
            mapping={authors.data}
            isLoading={authors.isLoading}
            validate={disabled ? [] : [required]}
            disabled={disabled || !values.publisher}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col">
          <Field
            component={renderFormGroupTextField}
            label="Genere"
            name="genre"
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col">
          <Field
            component={renderFormGroupTextField}
            label="Edizione"
            name="edition"
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col">
          <Field
            component={renderFormGroupTextField}
            label="Traduttore/i"
            name="translator"
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="EAN"
            name="ean"
            validate={[]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Lingua"
            name="language"
            disabled={disabled}
            validate={[]}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Descrizione"
            name="description"
            type="textarea"
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Field
            component={renderFileUploadInput}
            preview
            label="Immagine"
            name="imageUrl"
            accept="image/jpg, image/jpeg"
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Image URL"
            name="imageUrl"
            validate={disabled ? [] : [required]}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderNumberFormatInput}
            label="Prezzo"
            name="price"
            validate={[]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderNumberFormatInput}
            label="Pagine"
            name="pageNumber"
            disabled={disabled}
            validate={[]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderDateField}
            label="Data di pubblicazione"
            name="publishDate"
            validate={[required]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Collezione"
            name="series"
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Field
            component={renderSelectMappingField}
            label="Stato"
            name="status"
            mapping={COVER_STATUSES}
            validate={[]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderCheckbox}
            label="Pubblica su PDE"
            name="publishOnPDE"
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Meta Title"
            name="metaTitle"
            validate={[]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="og:title"
            name="ogTitle"
            disabled={disabled}
            validate={[]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Meta Description"
            name="metaDescription"
            type="textarea"
            validate={[]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="og:description"
            name="ogDescription"
            type="textarea"
            disabled={disabled}
            validate={[]}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <FieldArray
            name="additionalImages"
            disabled={disabled}
            imageTypesMapping={imageTypesMapping}
            additionalImages={additionalImages}
            onAddExtraPage={onAddExtraPage}
            onRemoveExtraPage={onRemoveExtraPage}
            onChangeType={onChangeImageType}
            onChangeFile={onChangeFile}
            component={renderExtraPages}
          />
        </Col>
      </Row>
    </FormModal>
  );
};

AddCoverFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string,
};

AddCoverFormModal.defaultProps = {
  title: "Copertina",
  publishers: [],
  authors: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetAuthors: () =>
      dispatch({
        meta: {
          field: "author",
          form: "addCoverModalForm",
          persistentSubmitErrors: false,
          touch: false,
        },
        payload: "",
        type: "@@redux-form/CHANGE",
      }),
    change: (form, field, value) => {
      dispatch(change(form, field, value));
    },
  };
};

const selector = formValueSelector("addCoverModalForm");

const mapStateToProps = (state) => {
  const formState = state.form["addCoverModalForm"];
  const additionalImages = selector(state, "additionalImages");
  const ean = selector(state, "ean");

  return {
    ean: ean,
    additionalImages: additionalImages,
    modalState: {
      values: formState && formState["values"] ? formState["values"] : {},
    },
  };
};

export default reduxForm({
  form: "addCoverModalForm",
  destroyOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(AddCoverFormModal));
