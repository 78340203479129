import React, { Component } from 'react'
import Book from 'components/Book'
import Heading from 'components/Heading'
import GridList from 'components/GridList'
import PageContainer from 'components/layouts/PageContainer'
import CoversService from 'services/CoversService'
import Cover from 'models/cover'

class PublisherDetails extends Component {
  state = {
    isLoading: false,
    publications: [],
    page: 1,
    totalRecords: 0,
    perPage: 50
  }
  componentDidMount () {
    this.loadCovers(1)
  }

  loadCovers = (page) => {
    this.setState({ isLoading: true })

    CoversService
      .getCovers(page, this.state.perPage, { publisher: this.props.id })
      .then((coversResponse) => {
        const { data: { results, totalRecords } } = coversResponse
        const covers = results.map(response => Cover.fromResponse(response))
        this.setState({ publications: [...this.state.publications, ...covers], isLoading: false, totalRecords: totalRecords })
      }).catch(() => { })
  }

  loadMore = (page) => {
    this.setState({ page: page })
    this.loadCovers(page)
  }

  render () {
    const { publications, isLoading, page, totalRecords, perPage } = this.state

    if (!isLoading && !publications) {
      return null
    }

    return (
      <PageContainer animate loading={isLoading}>
        <div>
          <section>
            <Heading icon='fas fa-book fa-xs'>Pubblicazioni</Heading>
            <GridList>
              {publications && publications.map((cover, idx) => <Book cover={cover} key={idx}
                width={188}
                height={360}
                size='sm'
                className='publisherBooks' />)}
            </GridList>
            {page * perPage < totalRecords && (
              <button type='button' className='btn btn-success btn-xs' onClick={() => this.loadMore(page + 1)} ><i className='fa fa-plus mr-2' />Carica altre</button>
            )}
          </section>
        </div>
      </PageContainer>
    )
  }
}

export default PublisherDetails
