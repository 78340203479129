import PropTypes from 'prop-types'

const formModalPropTypes = {
  // formModalComponent: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'update', 'delete', 'import']),
  getFormModalModeProps: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  onClose: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func
}

export { formModalPropTypes }
