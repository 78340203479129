import { connect } from 'react-redux'
import React, { Fragment, Component } from 'react'
import cx from 'classnames'
import { Badge } from 'reactstrap'
import Notification from './Notification/Notification'
import NotificationsService from '../../services/NotificationsService'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class Notifications extends Component {
  constructor (props) {
    super(props)

    this.state = {
      opened: false
    }

    this.menuRef = React.createRef()
  }

  toggleOpen = (opened) => {
    this.setState({ opened: opened }, () => {
      if (this.state.opened) {
        document.addEventListener('mousedown', this.handleClick)
      } else {
        document.removeEventListener('mousedown', this.handleClick)
      }
    })
  }

  handleClick = ({ target }) => {
    if (!this.menuRef.current.contains(target)) {
      this.toggleOpen(false)
    }
  }

  removeNotification = (id) => {
    NotificationsService.removeNotification(id)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick)
  }

  render () {
    const { opened } = this.state
    const { notifications } = this.props
    const notificationsCount = notifications.length
    const notificationText = notificationsCount > 0 ? `You have ${notificationsCount} notifications` : 'You don\'t have notifications yet'

    return (
      <Fragment>
        <div className='nav-link' onClick={() => { this.toggleOpen(!opened) }} style={{ cursor: 'pointer' }}>
          <i className='icon-bell' style={{ color: '#ffffff' }} />
          {notificationsCount > 0 && <Badge color='danger' pill>{notificationsCount}</Badge>}
        </div>
        <div ref={this.menuRef} className={cx('dropdown-menu dropdown-menu-right dropdown-menu-lg', { show: opened })}
          style={{ maxHeight: '300px', overflowY: 'scroll' }}>
          <div className='dropdown-header text-center'>
            <strong>{notificationText}</strong>
          </div>
          <TransitionGroup component='div'>
            {notifications.map(notification => {
              return (
                <CSSTransition
                  timeout={500}
                  classNames='fade'
                  key={notification._id}
                >
                  <Notification {...notification} onRemove={this.removeNotification} />
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        </div>

      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.notifications
  }
}

export default connect(mapStateToProps)(Notifications)
