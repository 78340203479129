import React from 'react'
import cx from 'classnames'
import { Container } from 'reactstrap'

import Loader from 'components/Loader'
import styles from './PageContainer.module.scss'

export default (props) => {
  const { children, animate, loading, ...rest } = props

  if (loading) {
    return (
      <div className={styles.Loader}>
        <Loader />
      </div>
    )
  }

  return (
    <Container className={cx(styles.PageContainer, { [styles.Animate]: animate })} {...rest}>
      <section className={styles.PageContent}>
        {typeof children === 'function' ? children() : children}
      </section>
    </Container>
  )
}
