import React from 'react'
import {
  Field,
  Form,
  formPropTypes,
  formValueSelector,
  reduxForm
} from 'redux-form'
// import { required } from './validators'
import { Col, Row } from 'reactstrap'

import { renderSelectMappingField, renderSwitch } from './formFields'
import { connect } from 'react-redux'

const NotificationSettingsForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    alertOnConfirm,
    alertOnDelete,
    users
  } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field
            label="Notificami quando un evento è confermato dall'editore"
            name='alertOnConfirm'
            placeholder=''
            component={renderSwitch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            name='onConfirmNotifiers'
            disabled={!alertOnConfirm}
            isMulti
            isLoading={users.isLoading}
            mapping={users.mapping}
            // validate={alertOnConfirm ? [required] : []}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label='Notificami quando la data di un evento viene eliminata'
            name='alertOnDelete'
            component={renderSwitch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            name='onDeleteNotifiers'
            disabled={!alertOnDelete}
            isMulti
            isLoading={users.isLoading}
            mapping={users.mapping}
            // validate={alertOnDelete ? [required] : []}
          />
        </Col>
      </Row>

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Salva
      </button>
    </Form>
  )
}

NotificationSettingsForm.propTypes = formPropTypes

const selector = formValueSelector('notificationSettingsForm')

const mapStateToProps = (state) => {
  const alertOnConfirm = selector(state, 'alertOnConfirm')
  const alertOnDelete = selector(state, 'alertOnDelete')

  return {
    alertOnConfirm: alertOnConfirm || false,
    alertOnDelete: alertOnDelete || false
  }
}

export default reduxForm({
  form: 'notificationSettingsForm',
  enableReinitialize: true
})(connect(mapStateToProps)(NotificationSettingsForm))
