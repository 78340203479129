import React from 'react'
import { format } from 'helpers/dateTime'

const EventsCoverPublicationDateFormatter = (props) => {
  const { row: event } = props
  const { cover } = event

  if (cover && cover.publishDate) {
    const { publishDate } = cover
    return (
      <div>
        {format(publishDate, 'MMMM YYYY')}
      </div>
    )
  }

  return <div>Not published</div>
}

export default EventsCoverPublicationDateFormatter
