import configureStore from './configureStore'
import LocalStorageService, { JWT_TOKEN, USER } from 'services/LocalStorageService'

const token = LocalStorageService.getItem(JWT_TOKEN)
let user = null

try {
  user = JSON.parse(LocalStorageService.getItem(USER))
} catch (e) {

}

const initialState = {}

if (user && token) {
  initialState['auth'] = {
    isAuthenticated: true,
    user: user
  }
}

const store = configureStore(initialState)

export default store
