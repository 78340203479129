import React, { Component } from "react";
import { Input, Label } from "reactstrap";
import cx from "classnames";

import styles from "./FileInput.module.scss";

class FileInput extends Component {
  renderPreview = () => {
    const { value } = this.props;
    if (value && value !== "") {
      const src =
        typeof value === "string"
          ? value.toLowerCase()
          : URL.createObjectURL(value[0]);

      console.log("SRC --->", src);
      if (
        src.endsWith(".jpg") ||
        src.endsWith(".jpeg") ||
        src.endsWith(".gif")
      ) {
        return (
          <img className="d-block mb-2" src={src} width={100} alt="file" />
        );
      } else {
        return <i className={`fa fa-file`} style={{ color: "#63c2de" }} />;
      }
    }

    return null;
  };

  render() {
    const { disabled, invalid, accept, name, value, ...inputProps } =
      this.props;

    const labelClasses = cx(
      styles.labelButton,
      "btn btn-secondary text-center d-inline-block mr-auto d-flex align-items-baseline"
    );
    const inputClasses = styles.fileInput;

    return (
      <div className={styles.FileInput}>
        <div className="d-flex justify-content-center flex-column align-items-center">
          {this.renderPreview()}
          <Label className={labelClasses} htmlFor={name}>
            {value && value !== "" ? "Aggiorna file" : "File"}
          </Label>
          <span className={cx({ "text-danger": invalid })}>
            Nessun file selezionato
          </span>
        </div>
        <Input
          {...inputProps}
          className={inputClasses}
          id={name}
          type="file"
          invalid={invalid}
          accept={accept}
          disabled={disabled}
          onBlur={() => {}}
        />
      </div>
    );
  }
}

export default FileInput;
