import React from 'react'
import {
  Field,
  formPropTypes,
  reduxForm
} from 'redux-form'

import { renderFormGroupTextField } from './formFields'
import { required, email } from './validators'

const ResetPasswordForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        type='email'
        name='email'
        icon='user'
        placeholder='Inserisci la tua email'
        component={renderFormGroupTextField}
        validate={[required, email]}
      />

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Reimposta password
      </button>
    </form>
  )
}

ResetPasswordForm.propTypes = formPropTypes

export default reduxForm({
  form: 'resetPasswordForm'
})(ResetPasswordForm)
