import React from 'react'
import cx from 'classnames'

import Tooltip from 'components/Tooltip'
import styles from './EventPerformanceAlert.module.scss'

export default (props) => {
  const { className, id, eventStartDate } = props

  let eventAlertColor = '#c8ced3'
  let tooltipText = null

  if (eventStartDate) {
    const currentDate = new Date()
    const MS_PER_DAY = 1000 * 60 * 60 * 24
    const utc1 = Date.UTC(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate())
    const utc2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

    const daysDiff = Math.floor((utc1 - utc2) / MS_PER_DAY)

    if (daysDiff >= 0) {
      if (daysDiff > 5) {
        eventAlertColor = '#4dbd74'
        tooltipText = 'STANDARD'
      } else if (daysDiff >= 3) {
        eventAlertColor = '#ffc107'
        tooltipText = 'POSSIBLE FOR PAYMENT'
      } else {
        eventAlertColor = '#f86c6b'
        tooltipText = 'AT RISK OF NON-FEASIBILITY'
      }
    }
  }

  return (
    <Tooltip id={id} placement='top' text={tooltipText}>
      <div className={cx(styles.EventPerformanceAlert, className)} style={{ backgroundColor: eventAlertColor }} />
    </Tooltip>
  )
}
