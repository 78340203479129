import React from 'react'
import { Button } from 'reactstrap'

import withAuth from 'containers/auth.container'
import EventPerformanceAlert from 'components/modals/AddEventFormModal/EventPerformanceAlert'
import {
  EVENT_PERFOMANCE_STATUSES,
  EVENT_PERFOMANCE_STATUS_DRAFT,
  EVENT_PERFOMANCE_STATUS_APPROVED,
  EVENT_PERFOMANCE_STATUS_PUBLISHED
} from 'constants/performanceStatuses'

const PerformanceStatusFormatter = ({
  value,
  row: event,
  creatorId,
  onApprove,
  onPublish,
  ...rest
}) => {
  const { _id, status, date, time } = event
  const { isStaff, isEditorPDE, isPublicationManager } = rest

  const [hours, minutes] = time.split(':')
  const performanceDate = new Date(date)
  performanceDate.setHours(hours)
  performanceDate.setMinutes(minutes)

  const isExpired = performanceDate.valueOf() < new Date().valueOf()
  let renderApprove = null
  let renderPublish = null

  if (status === EVENT_PERFOMANCE_STATUS_PUBLISHED) {
  } else {
    if (isStaff || isEditorPDE || isPublicationManager) {
      const canPublish = isStaff || isPublicationManager

      if (status === EVENT_PERFOMANCE_STATUS_DRAFT && canPublish) {
        renderApprove = (
          <Button
            className='w-100'
            color='success'
            size='sm'
            onClick={() => {
              onApprove && onApprove(event)
            }}
          >
            Approva
          </Button>
        )
      }

      if (canPublish && status === EVENT_PERFOMANCE_STATUS_APPROVED) {
        renderPublish = (
          <Button
            className='w-100'
            color='success'
            size='sm'
            onClick={() => onPublish && onPublish(event)}
          >
            Pubblica
          </Button>
        )
      }
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <EventPerformanceAlert
        id={_id}
        className='mr-3'
        eventStartDate={performanceDate}
      />
      {isExpired ? (
        <div className='text-secondary'>L'evento è scaduto</div>
      ) : (
        <div>
          {EVENT_PERFOMANCE_STATUSES[value]}
          <div>
            {renderApprove}
            {renderPublish}
          </div>
        </div>
      )}
    </div>
  )
}

export default withAuth(PerformanceStatusFormatter)
