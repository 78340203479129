import React, { Component } from 'react'

class GridItemWrapper extends Component {
  constructor (props) {
    super(props)

    this.itemRef = React.createRef()
  }

  getItem () {
    return this.itemRef.current
  }

  getWidth () {
    return this.itemRef.current.getBoundingClientRect().width
  }

  render () {
    const { children, className } = this.props

    return (
      <div ref={this.itemRef} className={className}>
        {children}
      </div>
    )
  }
}

export default GridItemWrapper
