import React from 'react'

import styles from './RowFormatter.module.scss'
import { Button } from 'reactstrap'

const RowFormatter = (props) => {
  const {
    row: { _id, name, surname, email },
    publisher: { editorsPDE },
    onAssociate
  } = props
  const associated = (editorsPDE || []).includes(_id)

  return (
    <div id={_id} className={styles.RowFormatter}>
      <span className={styles.UserName}>{`${name} ${surname}`}</span>
      <span>{email}</span>

      {associated ? (
        <div className='d-flex align-items-center ml-auto'>
          <span className='text-secondary mr-2'>Associated</span>
          <i className='fas fa-check text-success' />
        </div>
      ) : (
        <Button className='ml-auto' color='success' size='sm' onClick={() => onAssociate && onAssociate(props.row)}>Associate</Button>
      )}
    </div>
  )
}

export default RowFormatter
