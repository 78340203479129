import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { Field, formPropTypes, formValueSelector, reduxForm } from 'redux-form'

import { COMMERCIAL } from 'constants/organizationTypes'
import { required } from 'components/forms/validators'
import { renderFileUploadInput } from 'components/forms/formFields'
import ResponseError from 'components/ResponseError'

import FormModal from '../FormModal'
import styles from '../FormModalBase.module.scss'
import eventModalStyles from './AddEventFormModal.module.scss'

const ImportEventsFormModal = (props) => {
  const { title, error } = props

  return (
    <FormModal {...props} formTitle={title} className={cx(styles.FormModalBase, eventModalStyles.EventFormModal)}>
      {error && <ResponseError error={error} />}
      <Row className={eventModalStyles.EventData}>
        <Col>
          <Field
            component={renderFileUploadInput}
            label='File'
            name='file'
            accept={['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            validate={[required]}
          />
        </Col>
      </Row>

    </FormModal>
  )
}

ImportEventsFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

ImportEventsFormModal.defaultProps = {
  title: 'Evento'
}

const selector = formValueSelector('ImportEventsFormModal')

const mapStateToProps = (state) => {
  const cover = selector(state, 'cover')
  const performances = selector(state, 'performances')
  const organizationType = selector(state, 'organizationType')

  return {
    selectedCoverId: cover,
    performances: performances,
    isCommercial: organizationType === COMMERCIAL
  }
}

export default reduxForm({
  form: 'ImportEventsFormModal'
})(connect(mapStateToProps)(ImportEventsFormModal))
