import React from "react";
import { Link } from "react-router-dom";

const EventsCoverPublisherFormatter = (props) => {
  const { row: event } = props;
  const { cover } = event;

  if (cover) {
    if (cover.publisher) {
      const {
        publisher: { _id, companyName },
      } = cover;
      return (
        <div>
          <Link to={`/publishers/${_id}`}>{companyName}</Link>
        </div>
      );
    }
    if (cover.publisherCompanyName) {
      return (
        <div>
          <Link to={`/publishers/${cover.publisherId}`}>
            {cover.publisherCompanyName}
          </Link>
        </div>
      );
    }
  }

  return <div>-</div>;
};

export default EventsCoverPublisherFormatter;
