import store from 'store/store'

import {
  open,
  close
} from 'components/modals/reduxModal/actions'

class ModalsService {
  constructor () {
    this.dispatch = store.dispatch
  }

  open (modal, payload, meta) {
    this.dispatch(open(modal, payload, meta))
  }

  close (modal, payload, meta) {
    this.dispatch(close(modal, payload, meta))
  }
}

export default new ModalsService()
