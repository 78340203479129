import PropTypes from 'prop-types'

const simpleTablePropTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      formatter: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]
      )
    })
  ),
  selectable: PropTypes.bool,
  onSelectedRowChange: PropTypes.func
}

export { simpleTablePropTypes }
