class Post {
  constructor(postData) {
    this._id = postData._id;
    this.title = postData.title;
    this.body = postData.body;
    this.image = postData.image;
    this.category = postData.category;
    this.publishDate = postData.publishDate;
    this.published = postData.published;
    this.creatorId = postData.creatorId;
  }

  static fromResponse = (postData) => {
    return new Post(postData);
  };
}

export default Post;
