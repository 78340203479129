import React from 'react'
import { Link } from 'react-router-dom'
import Cover from '../../../models/cover'

export default (props) => {
  const { value, row } = props
  const cover = row instanceof Cover ? row : row.cover
  const coverId = typeof cover === 'string' ? cover : cover._id

  return <Link to={`/covers/${coverId}`} target='_blank'><img src={value} width={80} alt='' /></Link>
}
