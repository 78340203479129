import React from 'react'
import { reset } from 'redux-form'
import { toast } from 'react-toastify'

import store from 'store/store'
import ChangePasswordForm from 'components/forms/ChangePasswordForm'
import AuthService from 'services/AuthService'
import handleFormSubmitError from 'helpers/forms/handleFormSubmitError'
import styles from './Security.module.scss'

const Security = () => {
  const submitChangePassword = (values) => {
    const { currentPassword, newPassword } = values

    return AuthService.setPassword(currentPassword, newPassword)
      .then((response) => {
        const { data } = response
        if (data['flash']) {
          toast.success(data['flash']['message'])
        }
        store.dispatch(reset('changePasswordForm'))
      })
      .catch((error) => {
        handleFormSubmitError(error)
      })
  }

  return (
    <div className={styles.security}>
      <section className={styles.container}>
        <h2>Password</h2>
        <section className={styles.form}>
          <h4>Cambia Password</h4>
          <ChangePasswordForm onSubmit={submitChangePassword} />
        </section>
      </section>
    </div>
  )
}

export default Security
