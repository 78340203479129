import { connect } from 'react-redux'

import { ADMIN, EDITOR_PDE, ONLINE_SELLER, PUBLICATION_MANAGER } from 'constants/users'
import { BOOK_SELLER } from '../constants/users'

const mapStateToProps = state => {
  const {
    isAuthenticated,
    isAuthenticating,
    isRestoring,
    isProcessing,
    error,
    user
  } = state.auth

  return {
    isAuthenticated,
    isAuthenticating,
    isRestoring,
    isProcessing,
    error,
    user
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isAuthenticated, user } = stateProps

  return {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    isStaff: isAuthenticated && user && user.role === ADMIN,
    isEditorPDE: isAuthenticated && user && user.role === EDITOR_PDE,
    isPublicationManager: isAuthenticated && user && user.role === PUBLICATION_MANAGER,
    isOnlineSeller: isAuthenticated && user && user.role === ONLINE_SELLER,
    isBookSeller: isAuthenticated && user && user.role === BOOK_SELLER
  }
}

export default connect(mapStateToProps, null, mergeProps)
