import axios from 'axios'

import LocalStorageService, { JWT_TOKEN } from 'services/LocalStorageService'
import AuthService from 'services/AuthService'

axios.interceptors.request.use(function (config) {
  const token = LocalStorageService.getItem(JWT_TOKEN)

  if (token) {
    config.headers['Content-Type'] = 'application/json'
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    const { data } = error.response
    if (data.message === 'jwt expired') {
      AuthService.logout()
    }
  } else if (error.request) {
    // server is down
  } else {
    // unknow error
  }
  return Promise.reject(error)
})

export default axios
