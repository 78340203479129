import React, { Component } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import UsersService from 'services/UsersService'
import User from 'models/user'
import { BOOK_SELLER } from 'constants/users'
import BooksellersService from 'services/BooksellersService'

import DataTable from '../DataTable'
import RowFormatter from './RowFormatter'

import styles from './BooksellerExternalUsersModalTable.module.scss'

const getColumns = (columnsProps) => (
  [
    {
      key: 'name',
      name: 'External User',
      sortable: true,
      formatter: (props) => <RowFormatter {...props} {...columnsProps} />,
      filterable: true
    }
  ]
)

class BooksellerExternalUsersModalTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bookseller: this.props.bookseller,
      columnsProps: { bookseller: this.props.bookseller, onAssociate: this.onAssociate },
      users: [],
      totalRows: 0
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.bookseller !== prevState.bookseller) {
      return { bookseller: nextProps.bookseller, columnsProps: { ...prevState.columnsProps, bookseller: nextProps.bookseller } }
    }

    return null
  }

  onChange = (params) => {
    this.fetchUsers(params)
  }

  fetchUsers = ({ page: { number, size }, formFilterData }) => {
    const filterData = {
      ...formFilterData,
      role: BOOK_SELLER
    }

    UsersService
      .getUsers(number, size, filterData)
      .then((response) => {
        const { data: { results, totalRecords } } = response
        const users = results.map(response => User.fromResponse(response))

        this.setState({ users, totalRows: totalRecords })
      })
      .catch(() => { })
  }

  onAssociate = (user) => {
    const { bookseller } = this.props
    const { _id } = user

    if (bookseller) {
      if (!bookseller.externalUsers.includes(_id)) {
        const body = { externalUsers: [...bookseller.externalUsers, _id] }
        // todo: for dev only. An easy way to reset all associated editors
        // const body = { externalUsers: [] }

        BooksellersService
          .update(bookseller._id, body)
          .then((response) => {
            const { data: { flash, bookseller: booksellerData } } = response
            const { onUpdateBooksellerExternalUsers } = this.props

            onUpdateBooksellerExternalUsers && onUpdateBooksellerExternalUsers(bookseller._id, booksellerData)
            if (flash) {
              toast.success(flash['message'])
            }
          })
          .catch((error) => {
            toast.error(error)
          })
      }
    }
  }

  render () {
    const { users, totalRows, columnsProps } = this.state

    return (
      <DataTable className={styles.BooksellerExternalUsersModalTable}
        columns={getColumns}
        columnsProps={columnsProps}
        rowsPerPageDefaultValue={10}
        data={users}
        totalRowsCount={totalRows}
        onChange={this.onChange}
      />
    )
  }
}

BooksellerExternalUsersModalTable.propTypes = {
  bookseller: PropTypes.object.isRequired,
  onUpdateBooksellerExternalUsers: PropTypes.func.isRequired
}

export default BooksellerExternalUsersModalTable
