class Books {
  constructor(data) {
    this._id = data._id;
    this.publishOnPDE = data.publishOnPDE;
    this.deleted = data.deleted;
    this.EAN = data.EAN || data.ean;
    this.authors = data.authors;
    this.author = data.author;
    this.description = data.description;
    this.editor = data.editor || data.publisherCompanyName;
    this.format = data.format;
    this.gender = data.gender;
    this.imageUrl = data.imageUrl;
    this.language = data.language;
    this.pageNumber = data.pageNumber;
    this.price = data.price;
    this.publishDate = data.publishDate;
    this.series = data.series;
    this.title = data.title;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this._media = data.media;
    this.translator = data.translator;
  }

  get media() {
    return this._media || [];
  }

  static fromResponse = (data) => {
    return new Books(data);
  };
}

export default Books;
