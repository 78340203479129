class Publisher {
  constructor (publisherData) {
    this._id = publisherData._id
    this.companyName = publisherData.companyName
    this.address = publisherData.address
    this.vatNumber = publisherData.vatNumber
    this.code = publisherData.code
    this.promoter = publisherData.promoter
    this.email = publisherData.email
    this.zipCode = publisherData.zipCode
    this.city = publisherData.city
    this.province = publisherData.province
    this.country = publisherData.country
    this.logo = publisherData.logo
    this.description = publisherData.description
    this.telephone = publisherData.telephone
    this.website = publisherData.website
    this.promotion = publisherData.promotion
    this.distribution = publisherData.distribution
    this.sdiCode = publisherData.sdiCode
    this.meliCode = publisherData.meliCode
    this._editorsPDE = publisherData.editorsPDE
    this.publisherId = publisherData.publisherId
    this.metaDescription = publisherData.metaDescription
    this.metaTitle = publisherData.metaTitle
    this.ogDescription = publisherData.ogDescription
    this.ogTitle = publisherData.ogTitle
  }

  get editorsPDE () {
    if (this._editorsPDE) {
      return this._editorsPDE.map(eu => eu._id)
    }
    return []
  }

  static fromResponse = (publisherData) => {
    return new Publisher(publisherData)
  }
}

export default Publisher
