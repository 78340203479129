const DEFAULT_BORDER_COLOR = '#ced4da'
const INVALID_BORDER_COLOR = '#f86c6b'

export default {
  container: (base) => {
    return {
      ...base,
      width: '100%'
    }
  },
  control: (base, state) => {
    const { selectProps: { invalid } } = state

    return {
      ...base,
      // todo: fix background image
      transition: 'none',
      '&:hover': { borderColor: DEFAULT_BORDER_COLOR },
      ...(state.isFocused ? {
        border: `1px solid #8ad4ee`,
        borderColor: '#8ad4ee',
        outline: 0,
        boxShadow: '0 0 0 0.2rem rgba(32, 168, 216, 0.25)'
      } : {
        border: `1px solid ${DEFAULT_BORDER_COLOR}`,
        borderColor: DEFAULT_BORDER_COLOR,
        boxShadow: 'none'
      }),
      ...(invalid && {
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E\")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right calc(0.375em + 0.1875rem)',
        backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
        border: `1px solid ${INVALID_BORDER_COLOR}`,
        borderColor: INVALID_BORDER_COLOR,
        ...(state.isFocused && {
          boxShadow: '0 0 0 0.2rem rgba(248, 108, 107, 0.25)'
        }),
        '&:hover': {
          borderColor: INVALID_BORDER_COLOR
        }
      })
    }
  }
}
