import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'

import createRootReducer from '../reducers'

function createEnhancer (middlewares) {
  const storeEnhancer = applyMiddleware(...middlewares)
  if (process.env.NODE_ENV === 'development') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(storeEnhancer)
  }

  return storeEnhancer
}

export default function configureStore (initialState) {
  const reducer = createRootReducer()

  const middlewares = [thunk]

  if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger')
    // TODO: remove logger
    middlewares.push(createLogger())
  }

  const enhancer = createEnhancer(middlewares)
  const store = createStore(reducer, initialState, enhancer)

  store.asyncReducers = {}

  store.attachReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer
    store.replaceReducer(createRootReducer(store.asyncReducers))

    return store
  }

  store.detachReducer = (key) => {
    if (key in store.asyncReducers) {
      delete store.asyncReducers[key]

      store.replaceReducer(createRootReducer(store.asyncReducers))
    }

    return store
  }

  return store
}
