import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import styles from './UserActionsFormatter.module.scss'

const PublisherActionsFormatter = (props) => {
  const {
    row,
    onInfo,
    onEdit,
    onRemove,
    canRemove,
    canInfo
  } = props

  return (
    <Fragment>
      <div className={`${styles.userActionsFormatter} d-flex flex-wrap`}>
        <Button color='warning' size='xs' onClick={() => onEdit && onEdit(row)} className='m-1'>
          <i className='fa fa-pen' style={{ color: '#ffffff' }} />
        </Button>

        {canRemove && (
          <Button color='danger' size='xs' onClick={() => onRemove && onRemove(row)} className='m-1'>
            <i className='fas fa-trash' style={{ color: '#ffffff' }} />
          </Button>
        )}

        {canInfo && (
          <Button color='info' size='xs' onClick={() => onInfo && onInfo(row)} className='m-1'>
            <i className='fas fa-file-alt' style={{ color: '#ffffff' }} />
          </Button>
        )}

      </div>
    </Fragment>
  )
}

PublisherActionsFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  canRemove: PropTypes.bool,
  canInfo: PropTypes.bool
}

export default (outerProps) => (props) => {
  return <PublisherActionsFormatter {...outerProps} {...props} />
}
