import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import EventsService from "services/EventsService";
import AddEventFormModal from "components/modals/AddEventFormModal";
import ImportEventsFormModal from "components/modals/ImportEventsFormModal";
import { Formatters } from "components/data-grid-table";
import DataTable from "components/tables/DataTable";
import Event from "models/event";
import Tooltip from "components/Tooltip";

import {
  EventsAuthorFormatter,
  EventsCoverFormatter,
  EventsCoverPublisherFormatter,
  EventsCoverPublicationDateFormatter,
  EventsCoverTitleFormatter,
  EventsTitleFormatter,
} from "./formatters";
import ModalsService from "services/ModalsService";
import CancelSubmitionError from "components/forms/errors/CancelSubmitionError";
import ConfirmationModal from "components/modals/ConfirmationModal";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import { FilterCoverPublicationDatComponent } from "../../data-grid-table/filters/components";
import { saveAs } from "file-saver";
import { extractFileName } from "helpers/responseHelper";
import EventsStatusFormatter from "./formatters/EventsStatusFormatter";

const getColumns = (props) => [
  {
    key: "title",
    name: "Evento",
    formatter: EventsTitleFormatter,
    filterable: false,
  },
  {
    key: "cover",
    name: "Copertina",
    formatter: EventsCoverFormatter,
    cellClass: "justify-content-center",
    filterable: false,
  },
  {
    key: "publisher",
    name: "Editore",
    formatter: EventsCoverPublisherFormatter,
    filterable: false,
  },
  {
    key: "publicationDate",
    name: "Data pubblicazione",
    formatter: EventsCoverPublicationDateFormatter,
    filterRenderComponent: FilterCoverPublicationDatComponent,
    filterable: false,
  },
  {
    key: "actions",
    name: "Azioni",
    formatter: Formatters.ActionsFormatter(props),
    cellClass: "justify-content-center",
  },
  {
    key: "status",
    name: "Stato",
    formatter: EventsStatusFormatter,
    cellClass: "justify-content-center",
    filterable: false,
  },
];

const EventsDataTable = () => {
  const [events, setEvents] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const fetchEvents = ({ page: { number, size }, formFilterData }) => {
    EventsService.getEvents(number, size, formFilterData)
      .then((response) => {
        const {
          data: { results, totalRecords },
        } = response;

        const events = results.map((response) => Event.fromResponse(response));
        setEvents(events);
        setTotalRows(totalRecords);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChange = (params) => {
    fetchEvents(params);
  };

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues };

      let request = EventsService[action];

      if (request) {
        if (action === "create") {
          request = request(body);
        }

        if (action === "update") {
          const { _id } = body;
          request = request(_id, body);
        }

        if (action === "delete") {
          const { _id } = body;

          request = new Promise((resolve, reject) => {
            EventsService.performances(_id, { date: "incoming" })
              .then((response) => {
                const {
                  data: { performances },
                } = response;

                if (performances.length > 0) {
                  ModalsService.open("confirmEventDeleteModal", {
                    title: "Elimina evento",
                    text: "Ci sono date future per questo evento. Proseguendo verranno eliminate.\n\nConfermi questa azione?",
                    onSubmit: () => {
                      EventsService[action](_id, body)
                        .then((response) => {
                          ModalsService.close("confirmEventDeleteModal");
                          resolve(response);
                        })
                        .catch((error) => {
                          ModalsService.close("confirmEventDeleteModal");
                          resolve(error);
                        });
                    },
                    onCancel: () => {
                      reject(new CancelSubmitionError());
                    },
                  });
                } else {
                  resolve(EventsService[action](_id, body));
                }
              })
              .catch(reject);
          });
        }

        return request;
      }
    };
  };

  const handleImport = () => {
    return (formValues) => {
      let body = {
        ...formValues,
      };

      let request = EventsService["import"];

      if (request) {
        request = request(body);
        return request;
      }
    };
  };

  const handleExport = (filterData) => {
    EventsService.export(filterData).then((response) => {
      const filename = extractFileName(response.headers["content-disposition"]);
      console.log("filename", filename);
      saveAs(response.data, filename);
    });
  };

  const getFormModalModeProps = (mode) => {
    if (mode === "create") {
      return {
        title: "Crea evento",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "update") {
      return {
        title: "Aggiorna evento",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "delete") {
      return {
        title: "Elimina evento",
        onSubmit: handleSubmit(mode),
      };
    }

    return {
      submitButtonLabel: "Salva",
      title: "Evento",
    };
  };

  const getImportModalModeProps = (mode) => {
    if (mode === "import") {
      return {
        title: "Importa eventi",
        onSubmit: handleImport(),
      };
    }
    return {
      submitButtonLabel: "Importa",
      title: "Evento",
    };
  };

  const prepareModalData = (data) => {
    return {
      ...data,
      cover: data.cover._id,
    };
  };

  return (
    <Fragment>
      <DataTable
        columns={getColumns}
        prepareModalData={prepareModalData}
        data={events}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={{
          formModalComponent: AddEventFormModal,
          getFormModalModeProps: getFormModalModeProps,
        }}
        importModalProps={{
          formModalComponent: ImportEventsFormModal,
          getFormModalModeProps: getImportModalModeProps,
        }}
        createTableActions={() => {
          return (
            <Button
              className="ml-2"
              color="success"
              size="xs"
              onClick={() => {
                window.location.href = "/sample-import.xlsx";
              }}
            >
              {"   "}
              <i
                className="fas fa-download  mr-2"
                style={{ color: "#ffffff", pointerEvents: "none" }}
              />
              Tracciato di esempio
            </Button>
          );
        }}
        handleExport={handleExport}
      />
      <ReduxModalWrapper
        modal="confirmEventDeleteModal"
        component={ConfirmationModal}
      />
      <ReduxModalWrapper
        modal="confirmEventPerformanceDeleteModal"
        component={ConfirmationModal}
      />
    </Fragment>
  );
};
export default EventsDataTable;
