import React, { Fragment } from 'react'
import cx from 'classnames'
import { Card } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import withAuth from 'containers/auth.container'

import styles from './Profile.module.scss'

const Profile = (props) => {
  const { children, isStaff } = props

  return (
    <div className={styles.profile}>
      <ul className={styles.menu}>
        <li className={styles.item}>
          <NavLink className={styles.navLink} activeClassName={styles.active} to='/profile/settings/profile'>
            <i className='fas fa-user-alt fa-2x' />
            <span className={cx('ml-2', styles.linkText)}>Profilo</span>
          </NavLink>
        </li>
        <li className={styles.item}>
          <NavLink className={styles.navLink} activeClassName={styles.active} to='/profile/settings/security'>
            <i className='fas fa-shield-alt fa-2x' />
            <span className={cx('ml-2', styles.linkText)}>Password</span>
          </NavLink>
        </li>
        {isStaff && (
          <Fragment>
            <li className={styles.item}>
              <NavLink className={styles.navLink} activeClassName={styles.active} to='/profile/settings/notifications'>
                <i className='fa fa-bell fa-2x' />
                <span className={cx('ml-2', styles.linkText)}>Notifiche</span>
              </NavLink>
            </li>
            <li className={styles.item}>
              <NavLink className={styles.navLink} activeClassName={styles.active} to='/profile/settings/tokens'>
                <i className='fas fa-link fa-2x' />
                <span className={cx('ml-2', styles.linkText)}>Tokens</span>
              </NavLink>
            </li>
          </Fragment>
        )}
      </ul>
      <main className={styles.main}>
        <Card>
          {children}
        </Card>
      </main>
    </div>
  )
}

export default withAuth(Profile)
