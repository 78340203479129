import React from 'react'
import PropTypes from 'prop-types'

function App ({ children }) {
  return (
    <div className='w-100 h-100'>
      {children}
    </div>
  )
}

App.propTypes = {
  isStaff: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default App
