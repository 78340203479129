import React from 'react'

export const LoadingMessage = (props) => {
  const { children, className, cx, getStyles, innerProps } = props

  return (
    <div
      css={getStyles('loadingMessage', props)}
      className={cx(
        {
          'menu-notice': true,
          'menu-notice--loading': true
        },
        className
      )}
      {...innerProps}
    >
      {children}
    </div>
  )
}

LoadingMessage.defaultProps = {
  children: 'Loading...'
}
