import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Field, formPropTypes, reduxForm } from 'redux-form'
import countryList from 'react-select-country-list'
import { Col, Row } from 'reactstrap'

import {
  renderFileUploadInput,
  renderFormGroupTagsInput,
  renderFormGroupTextField,
  renderSelectMappingField
} from 'components/forms/formFields'
import { email, required, url, zipCode } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'

import { DISTRIBUTIONS, PUBLISHER_PROMOTER } from 'constants/publishers'

import FormModal from '../FormModal'
import styles from '../FormModalBase.module.scss'
import publisherModal from './AddPublisherFormModal.module.scss'
import { ADMIN, BOOK_SELLER } from 'constants/users'
import { EDITOR_PDE } from '../../../constants/users'

const AddPublisherFormModal = props => {
  const { title, error, disabled, role } = props
  return (
    <FormModal
      {...props}
      className={cx(styles.FormModalBase, publisherModal.PublisherFormModal)}
      formTitle={title}
    >
      {error && <ResponseError error={error} />}
      {role === EDITOR_PDE ? (
        <Field
          component={renderFormGroupTextField}
          label='Descrizione'
          name='description'
          type='textarea'
          disabled={disabled}
        />
      ) : (
        <>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTagsInput}
                label='PublisherId'
                name='publisherId'
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Ragione Sociale'
                name='companyName'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Indirizzo'
                name='address'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderSelectMappingField}
                label='Nazione'
                name='country'
                mapping={countryList().getData()}
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Provincia'
                name='province'
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Città'
                name='city'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='CAP'
                name='zipCode'
                validate={disabled ? [] : [required, zipCode]}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Telefono'
                name='telephone'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Email'
                name='email'
                validate={disabled ? [] : [required, email]}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Codice identificativo'
                name='code'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Sito web'
                name='website'
                validate={disabled ? [] : [url]}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='P. IVA'
                name='vatNumber'
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderSelectMappingField}
                label='Promotore'
                name='promoter'
                mapping={PUBLISHER_PROMOTER}
                validate={disabled ? [] : [required]}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Account PDE'
                name='promotion'
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderSelectMappingField}
                label='Distribuzione'
                name='distribution'
                mapping={DISTRIBUTIONS}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Codice SDI'
                name='sdiCode'
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='PEC'
                name='meliCode'
                disabled={disabled}
              />
            </Col>
          </Row>
          <Field
            component={renderFileUploadInput}
            label='Logo'
            name='logo'
            preview
            disabled={disabled}
          />
          <Field
            component={renderFormGroupTextField}
            label='Descrizione'
            name='description'
            type='textarea'
            disabled={disabled}
          />
          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Meta Title'
                name='metaTitle'
                validate={[]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='og:title'
                name='ogTitle'
                disabled={disabled}
                validate={[]}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='Meta Description'
                name='metaDescription'
                type='textarea'
                validate={[]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                component={renderFormGroupTextField}
                label='og:description'
                name='ogDescription'
                type='textarea'
                disabled={disabled}
                validate={[]}
              />
            </Col>
          </Row>
        </>
      )}
    </FormModal>
  )
}

AddPublisherFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

AddPublisherFormModal.defaultProps = {
  title: 'Editori'
}

export default reduxForm({
  form: 'add-publisher-modal-form'
})(AddPublisherFormModal)
