class EventPerformance {
  constructor(eventData = {}) {
    this._id = eventData._id;
    this.date = eventData.date;
    this.time = eventData.time;
    this.location = eventData.location;
    this.address = eventData.address;
    this.province = eventData.province;
    this.booksellerCode = eventData.booksellerCode;
    this.status = eventData.status;
    this.requiredBookCopies = eventData.requiredBookCopies;
    this.organizationType = eventData.organizationType;
    this.title = eventData.title;
    this.description = eventData.description;
    this.referenceName = eventData.referenceName;
    this.referencePhone = eventData.referencePhone;
    this.referenceEmail = eventData.referenceEmail;
    this.bookHandling = eventData.bookHandling;
    this.notes = eventData.notes;
  }

  static fromResponse = (eventData) => {
    return new EventPerformance(eventData);
  };
}

export default EventPerformance;
