import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import store from 'store/store'
import AuthService from 'services/AuthService'
import Root from './Root'

import 'resources/assets/styles/index.scss'

// configure application root component
const node = <Root store={store} />

AuthService.authRestoreUser()

const target = document.getElementById('root')
ReactDOM.render(node, target)

serviceWorker.unregister()
