import React, { Component } from 'react'
import { connect, ReactReduxContext } from 'react-redux'

import { initialize, destroy, open, close } from './actions'
import reducer from './reducer'

const createReduxModal = (params) => {
  const {
    modal,
    ...rest
  } = params

  return WrappedComponent => {
    class Modal extends Component {
      static contextType = ReactReduxContext

      componentDidMount () {
        this.context.store.attachReducer('modal', reducer)
      }

      render () {
        return <WrappedComponent {...this.props} />
      }
    }

    class ReduxModal extends Component {
      componentDidMount () {
        const { initialize, _reduxModal } = this.props
        initialize(_reduxModal)
      }

      componentWillUnmount () {
        const { destroy, _reduxModal } = this.props
        destroy(_reduxModal)
      }

      toggleModal = () => {
        const {
          isOpen,
          _reduxModal,
          open,
          close
        } = this.props

        isOpen ? close(_reduxModal) : open(_reduxModal)
      }

      render () {
        const {
          isOpen,
          initialValues
        } = this.props

        return <Modal isOpen={isOpen} toggle={this.toggleModal} {...initialValues} />
      }
    }

    const ConnectedModal = connect(
      (state, props) => {
        const { _reduxModal } = props
        return state['modal'] && state['modal'][_reduxModal] ? state['modal'][_reduxModal] : {}
      },
      (dispatch) => ({
        open: (modal) => dispatch(open(modal)),
        close: (modal) => dispatch(close(modal)),
        initialize: (modal, payload = {}, meta = {}) => dispatch(initialize(modal, payload, meta)),
        destroy: (modal) => dispatch(destroy((modal)))
      })
    )(ReduxModal)

    ConnectedModal.defaultProps = { _reduxModal: modal, ...rest }

    return ConnectedModal
  }
}

export default createReduxModal
