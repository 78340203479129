import React from 'react'
import { Button } from 'reactstrap'

import styles from './StickyCloseButton.module.scss'

const StickyCloseButton = ({ onClick }) => {
  return (
    <Button className={styles.Button} close onClick={onClick}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z' />
      </svg>
    </Button>
  )
}

export default StickyCloseButton
