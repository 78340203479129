import React, { useEffect, useState } from 'react'
import { Field, formPropTypes, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { renderFormGroupTextField, renderSelectMappingField } from './formFields'
import { email, required } from './validators'
import { Col, Row } from 'reactstrap'
import { EDITOR_PDE, BOOK_SELLER, SIGN_UP_EDITOR_ROLES, SIGN_UP_BOOKESELLER_ROLES } from '../../constants/users'
import PublisherDataToDropField from '../../helpers/data/publisherFormatHelper'
import PublishersService from '../../services/PublishersService'

const FORM_NAME = 'signUpForm'

let SignUpForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    role
  } = props
  const [publishers, setPublishers] = useState({})

  const fetchPublishers = async () => {
    try {
      const response = await PublishersService.names()
      const { data } = response
      const publisherMapping = PublisherDataToDropField(data.publishers)
      setPublishers(publisherMapping)
    } catch (e) {
      setPublishers({})
    }
  }

  useEffect(() => {
    fetchPublishers()
  }, [])

  const renderAdditionalFields = () => {
    if (role === EDITOR_PDE) {
      return (
        <Field
          component={renderSelectMappingField}
          label='Editore'
          name='publisher'
          mapping={publishers}
          validate={[required]}
        />
      )
    } else if (role === BOOK_SELLER) {
      return (
        <Field
          component={renderFormGroupTextField}
          label='Meli Code'
          name='meliCode'
          validate={[required]}
        />
      )
    }
  }

  const roleValues = () => {
    if (role === EDITOR_PDE) {
      return SIGN_UP_EDITOR_ROLES
    } else {
      return SIGN_UP_BOOKESELLER_ROLES
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            validate={[required]}
          />
          <Field
            component={renderFormGroupTextField}
            label='Cognome'
            name='surname'
            validate={[required]}
          />
          <Field
            component={renderFormGroupTextField}
            label='Email'
            name='email'
            type='email'
            validate={[required, email]}
          />
          <Field
            component={renderSelectMappingField}
            label='Ruolo'
            name='role'
            mapping={roleValues()}
            validate={[required]}
          />
          {renderAdditionalFields()}
        </Col>
      </Row>
      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        SignUp
      </button>
    </form>
  )
}

SignUpForm.propTypes = formPropTypes

SignUpForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: false
})(SignUpForm)

const selector = formValueSelector(FORM_NAME)
SignUpForm = connect(state => {
  const role = selector(state, 'role')
  return {
    role
  }
})(SignUpForm)

export default SignUpForm
