export const ALL = ''
export const PDE = 'PDE'
export const PDE_SERVICE = 'PDE Service'

export const PUBLISHER_PROMOTER = {
  [PDE]: 'PDE',
  [PDE_SERVICE]: 'PDE Service'
}

export const PUBLISHER_PROMOTER_FILTER = {
  [ALL]: 'ALL',
  [PDE]: 'PDE',
  [PDE_SERVICE]: 'PDE Service'
}

export const MESSAGGERIE = 'messaggerie'
export const ALI = 'ali'
export const ALTRO = 'altro'

export const DISTRIBUTIONS = {
  [MESSAGGERIE]: 'Messaggerie',
  [ALI]: 'ALI',
  [ALTRO]: 'Altro'
}
