import React from 'react'
import { formModalPropTypes } from './formModalPropTypes'

const FormModal = (props) => {
  const {
    formModalComponent: FormModalComponent,
    mode,
    wrapFormSubmitRequest,
    getFormModalModeProps
  } = props

  const formModalModeProps = getFormModalModeProps ? getFormModalModeProps(mode) : null

  const { onSubmit } = formModalModeProps
  const onSubmitWrapped = formValues => {
    if (onSubmit) {
      return wrapFormSubmitRequest(onSubmit(formValues))
    }
  }

  return FormModalComponent ? <FormModalComponent multipart {...props} {...formModalModeProps} onSubmit={onSubmitWrapped} /> : null
}

FormModal.propTypes = {
  ...formModalPropTypes
}

FormModal.defaultProps = {
  mode: 'create'
}

export default (formModalProps) => {
  return <FormModal {...formModalProps} />
}
