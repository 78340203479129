import {
  authLoginUser,
  authRestoreUser,
  authLogOutUser
} from 'store/actions/auth'
import store from 'store/store'
import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class AuthService {
  constructor () {
    this.dispatch = store.dispatch
  }

  authLoginUserRequest (email, password) {
    this.dispatch(authLoginUser(email, password))
  }

  authRestoreUser () {
    this.dispatch(authRestoreUser())
  }

  async authVerifyRestoreToken (token) {
    return axios.get(`${API_ENDPOINT}/auth/restore/${token}`)
  }

  async restore (token, password, passwordConfirmation) {
    const body = {
      password: password,
      passwordConfirmation: passwordConfirmation
    }

    return axios.post(`${API_ENDPOINT}/auth/restore/${token}`, body)
  }

  async recover (email) {
    const body = { email: email }

    return axios.post(`${API_ENDPOINT}/auth/reset-password`, body)
  }

  async changePassword (token, password, passwordConfirmation) {
    const body = {
      password: password,
      passwordConfirmation: passwordConfirmation
    }

    return axios.post(`${API_ENDPOINT}/auth/change-password/${token}`, body)
  }

  async setPassword (currentPassword, newPassword) {
    const body = {
      currentPassword: currentPassword,
      confirmPassword: newPassword,
      newPassword: newPassword
    }

    return axios.post(`${API_ENDPOINT}/auth/set-password`, body)
  }

  logout () {
    this.dispatch(authLogOutUser())
  }
}

export default new AuthService()
