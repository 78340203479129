import React from "react";
import { Fragment, useState } from "react";

import { Formatters } from "components/data-grid-table";
import AddPostFormModal from "components/modals/AddPostFormModal/AddPostFormModal";
import ConfirmationModal from "components/modals/ConfirmationModal";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import Post from "models/post";
import ModalsService from "services/ModalsService";
import PostsService from "services/PostsService";
import DataTable from "./DataTable";

import {
  FilterYearComponent,
  FilterDropDownComponent,
} from "components/data-grid-table/filters/components";
import CancelSubmitionError from "components/forms/errors/CancelSubmitionError";
import { BOOK_SELLER } from "../../constants/users";
import withAuth from "../../containers/auth.container";
import { POST_CATEGORIES } from "../../constants/posts";
import { UploadsFormatter } from "../data-grid-table/formatters";

const getColumns =
  (outerProps) =>
  (props = {}) => {
    const { ...restProps } = props;
    const columns = [
      {
        key: "title",
        name: "Titolo",
        sortable: true,
        filterable: true,
      },
      {
        key: "category",
        name: "Categoria",
        sortable: true,
        filterable: true,
        filterRenderComponent: (props) => (
          <FilterDropDownComponent {...props} values={POST_CATEGORIES} />
        ),
      },
      {
        key: "image",
        name: "Allegato",
        cellClass: "justify-content-center",
        filterable: false,
        formatter: UploadsFormatter,
      },
      {
        key: "publishDate",
        name: "Pubblicato il",
        formatter: Formatters.DayMonthYearFormatter({ ...props }),
        filterRenderComponent: FilterYearComponent,
        sortable: true,
        filterable: true,
      },
      {
        key: "actions",
        name: "Azioni",
        formatter: Formatters.ActionsFormatter({ ...restProps, ...outerProps }),
        cellClass: "justify-content-center",
      },
    ];
    return columns;
  };

const PostDataTable = ({
  user,
  isOnlineSeller,
  isStaff,
  isBookSeller,
  isEditorPDE,
}) => {
  const [posts, setPosts] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const fetchPosts = ({ page: { number, size }, formFilterData }) => {
    PostsService.getPosts(number, size, formFilterData)
      .then((response) => {
        const {
          data: { results, totalRecords },
        } = response;
        const posts = results.map((response) => Post.fromResponse(response));

        setPosts(posts);
        setTotalRows(totalRecords);
      })
      .catch(() => {});
  };

  const onChange = (params) => {
    fetchPosts(params);
  };

  const onShow = (row) => {
    window.location.href = `/posts/${row._id}`;
  };

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues };

      let request = PostsService[action];

      if (request) {
        if (action === "create") {
          request = request(body);
        }

        if (action === "update") {
          const { _id } = body;
          request = request(_id, body);
        }

        if (action === "delete") {
          const { _id } = body;

          request = new Promise((resolve, reject) => {
            PostsService.get(_id)
              .then((response) => {
                const {
                  data: { post },
                } = response;
                const { covers, performances } = post;

                if (covers.length > 0 || performances.length > 0) {
                  ModalsService.open("confirmPostDeleteModal", {
                    title: "Elimina post",
                    text: "Confermi questa azione?",
                    onSubmit: () => {
                      PostsService[action](_id, body)
                        .then((response) => {
                          ModalsService.close("confirmPostDeleteModal");
                          resolve(response);
                        })
                        .catch((error) => {
                          ModalsService.close("confirmPostDeleteModal");
                          resolve(error);
                        });
                    },
                    onCancel: () => {
                      reject(new CancelSubmitionError());
                    },
                  });
                } else {
                  resolve(PostsService[action](_id, body));
                }
              })
              .catch(reject);
          });
        }

        return request;
      }
    };
  };

  const getFormModalModeProps = (mode) => {
    if (mode === "create") {
      return {
        title: "Crea post",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "update") {
      return {
        title: "Aggiorna post",
        onSubmit: handleSubmit(mode),
      };
    }

    return {
      submitButtonLabel: "Salva",
      title: "Autore",
    };
  };

  const prepareModalData = (data) => {
    const { publisher } = data;

    return {
      ...data,
      publisher: publisher ? publisher._id : null,
    };
  };

  const columnsOuterProps = {
    onShow: (row) => {
      window.location.href = `/posts/${row._id}`;
    },
    canInfo: false,
    canRemove: false,
    canEdit: isStaff,
    canShow: true,
    canCreate: isStaff,
  };

  const formModalProps = isStaff
    ? {
        formModalComponent: AddPostFormModal,
        getFormModalModeProps: getFormModalModeProps,
      }
    : null;

  return (
    <Fragment>
      <DataTable
        columns={getColumns(columnsOuterProps)}
        data={posts}
        prepareModalData={prepareModalData}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={formModalProps}
      />
    </Fragment>
  );
};
export default withAuth(PostDataTable);
