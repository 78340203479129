import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_REMOVE
  // NOTIFICATIONS_HIDE_ALL
} from '../../constants'

import { promiseWrapper } from 'helpers/promiseWrapper'
import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

export function notificationsRequest () {
  return {
    type: NOTIFICATIONS_REQUEST
  }
}

export function notificationsRequestSuccess (notifications) {
  return {
    type: NOTIFICATIONS_SUCCESS,
    payload: {
      notifications: notifications
    }
  }
}

export function notificationsRequestFailure (error) {
  return {
    type: NOTIFICATIONS_FAILURE,
    payload: {
      error: error
    }
  }
}

export function notificationRemove (id) {
  return {
    type: NOTIFICATIONS_REMOVE,
    payload: {
      id: id
    }
  }
}

export function notificationSetViewed (id) {
  return promiseWrapper(async (dispatch) => {
    dispatch(notificationRemove(id))

    await axios.post(`${API_ENDPOINT}/notifications/${id}/view`)
  })
}

// export function notificationHideAll () {
//   return {
//     type: NOTIFICATIONS_HIDE_ALL
//   }
// }

export function fetchNotifications () {
  return promiseWrapper(async (dispatch) => {
    dispatch(notificationsRequest())

    const response = await axios.get(`${API_ENDPOINT}/notifications`)
    const { notifications } = response.data

    dispatch(notificationsRequestSuccess(notifications))
  },
  notificationsRequestFailure
  )
}
