import {
  OPEN,
  CLOSE,
  INITIALIZE,
  DESTROY
} from './actionTypes'

export function initialize (modal, payload, meta) {
  return {
    type: INITIALIZE,
    meta: { modal, ...meta },
    payload: payload
  }
}

export function destroy (modal, meta) {
  return {
    type: DESTROY,
    meta: { modal, ...meta }
  }
}

export function open (modal, payload, meta) {
  return {
    type: OPEN,
    meta: { modal, ...meta },
    payload: payload
  }
}

export function close (modal, meta) {
  return {
    type: CLOSE,
    meta: { modal, ...meta }
  }
}
