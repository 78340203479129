import React from "react";
import { Badge, Button } from "reactstrap";
import fileDownload from "js-file-download";

const FileToSubmit = ({ file, handleDelete, canEdit }) => {
  const download = (url) => {
    // window.location.href = url;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(blob);
        // link.download = url.split("/").pop();
        // link.click();
        // const file = window.URL.createObjectURL(blob);
        // window.location.assign(file);
        fileDownload(blob, url.split("/").pop());
      })
      .catch(console.error);
  };

  return (
    <div className="d-flex justify-content-between mb-4">
      <Badge className="d-flex align-items-center" color="warning">
        {file.type}
      </Badge>
      <Badge className="d-flex align-items-center w-75 mx-5" color="info">
        {file.file.name}
      </Badge>
      {canEdit && (
        <Button className="mx-1" onClick={() => handleDelete(file)}>
          Rimuovi
        </Button>
      )}
      {file.url && (
        <Button color="success" onClick={() => download(file.url)}>
          Scarica
        </Button>
      )}
    </div>
  );
};
export default FileToSubmit;
