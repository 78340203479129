import React, { useState, useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { Field, formPropTypes, reduxForm } from "redux-form";

import {
  renderFormGroupTextField,
  renderSelectMappingField,
  renderFormGroupTagsInput,
  renderFileUploadInput,
  renderDateField,
  renderCheckbox,
  renderEditor,
} from "components/forms/formFields";
import { POST_CATEGORIES } from "../../../constants/posts";

import { required } from "components/forms/validators";
import ResponseError from "components/ResponseError";

import FormModal from "../FormModal";
import styles from "../FormModalBase.module.scss";
import postModalStyles from "./AddPostFormModal.module.scss";

const AddPostFormModal = (props) => {
  const { title, error, disabled } = props;

  return (
    <FormModal
      {...props}
      formTitle={title}
      className={cx(styles.FormModalBase, postModalStyles.PostFormModal)}
    >
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Titolo"
            name="title"
            validate={disabled ? [] : [required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Field
        component={renderFileUploadInput}
        preview
        label="Allegato"
        accept="*"
        name="image"
        disabled={disabled}
      />
      <Field
        component={renderFormGroupTextField}
        label="Body"
        name="body"
        type="textarea"
        disabled={disabled}
      />

      <Field
        component={renderSelectMappingField}
        label="Categoria"
        name="category"
        mapping={POST_CATEGORIES}
        disabled={disabled}
        validate={disabled ? [required] : []}
      />

      <Field
        component={renderDateField}
        label="Data di pubblicazione"
        name="publishDate"
        disabled={disabled}
      />
    </FormModal>
  );
};

AddPostFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string,
};

AddPostFormModal.defaultProps = {
  title: "Autore",
  publishers: [],
};

export default reduxForm({
  form: "addPostModalForm",
})(AddPostFormModal);
