import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal
} from 'reactstrap'

import PublisherEditorsPDETable from 'components/tables/PublisherEditorsPDETable'

import styles from './PublisherEditorsPDEModal.module.scss'

const PublisherEditorsPDEModal = (props) => {
  const {
    title,
    publisher,
    isOpen,
    toggle,
    onUpdatePublisher
  } = props

  if (!publisher) {
    return null
  }

  const onClosed = () => {}

  return (
    <Modal className={styles.PublisherEditorsPDEModal} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <Card>
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close' onClick={toggle}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          <PublisherEditorsPDETable publisher={publisher} onUpdatePublisherEditorsPDE={onUpdatePublisher} />
        </CardBody>
        <CardFooter />
      </Card>
    </Modal>
  )
}

PublisherEditorsPDEModal.propTypes = {
  publisher: PropTypes.object,
  onUpdatePublisherEditorsPDE: PropTypes.func,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default PublisherEditorsPDEModal
