import React from "react";
import EventPerformanceAlert from "components/modals/AddEventFormModal/EventPerformanceAlert";

const EventsStatusFormatter = (props) => {
  const { row: event } = props;
  const { performances } = event;

  let d = [];
  performances.forEach((performance) => {
    d.push(new Date(performance.date));
  });
  d.sort((a, b) => b - a);

  return (
    <div>
      <EventPerformanceAlert id={event._id} eventStartDate={d[0]} />
    </div>
  );
};

export default EventsStatusFormatter;
