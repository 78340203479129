import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal
} from 'reactstrap'

import BooksellerExternalUsersModalTable from 'components/tables/BooksellerExternalUsersModalTable'

import styles from './BooksellerExternalUsersModal.module.scss'
const BooksellerExternalUsersModal = (props) => {
  const {
    title,
    bookseller,
    isOpen,
    toggle,
    onUpdateBooksellerExternalUsers
  } = props
  if (!bookseller) {
    return null
  }

  const onClosed = () => {}

  return (
    <Modal className={styles.BooksellerExternalUsersModal} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <Card>
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close' onClick={toggle}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          <BooksellerExternalUsersModalTable bookseller={bookseller} onUpdateBooksellerExternalUsers={onUpdateBooksellerExternalUsers} />
        </CardBody>
        <CardFooter />
      </Card>
    </Modal>
  )
}

BooksellerExternalUsersModal.propTypes = {
  bookseller: PropTypes.object,
  onUpdateBooksellerExternalUsers: PropTypes.func,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default BooksellerExternalUsersModal
