import React from 'react'

import reduxModal from './reduxModal'

const ReduxModalWrapper = ({ modal, component: ModalComponent, ...restProps }) => {
  const Component = reduxModal({
    modal,
    ...restProps
  })(ModalComponent)

  return <Component />
}

export default ReduxModalWrapper
