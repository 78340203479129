import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import {
  renderDateField,
  renderSelectMappingField,
  renderCheckbox,
} from "components/forms/formFields";
import AuthorDataToDropField from "helpers/data/authorFormatHelper";
import PublisherDataToDropField from "helpers/data/publisherFormatHelper";
import PublishersService from "services/PublishersService";
import AuthorsService from "services/AuthorsService";
import { Col, Row } from "reactstrap";
import { MONTH_MAPPING, YEARS_MAPPING } from "../../../constants/dates";

export const PublishersSelectMappingFilter = (props) => {
  const [publishers, setPublishers] = useState({
    isLoading: false,
    values: [],
  });

  const fetchPublishers = () => {
    setPublishers({ isLoading: true, values: [] });
    PublishersService.index()
      .then((response) => {
        const {
          data: { publishers },
        } = response;
        const publisherMapping = PublisherDataToDropField(publishers);
        setPublishers({ isLoading: false, values: publisherMapping });
      })
      .catch(() => {
        setPublishers({ isLoading: false, values: [] });
      });
  };

  useEffect(() => {
    fetchPublishers();
  }, []);

  return <FilterDropDownComponent {...props} {...publishers} />;
};

export const AuthorsSelectMappingFilter = (props) => {
  const [authors, setAuthors] = useState({ isLoading: false, values: [] });

  const fetchPublishers = () => {
    setAuthors({ isLoading: true, values: [] });
    AuthorsService.index()
      .then((response) => {
        const {
          data: { authors },
        } = response;
        const authorsMapping = AuthorDataToDropField(authors);
        setAuthors({ isLoading: false, values: authorsMapping });
      })
      .catch(() => {
        setAuthors({ isLoading: false, values: [] });
      });
  };

  useEffect(() => {
    fetchPublishers();
  }, []);

  return <FilterDropDownComponent {...props} {...authors} />;
};

export const FilterDropDownComponent = (props) => {
  const { filterKey, name, values, isLoading } = props;

  return (
    <div
      className="d-flex flex-row align-items-center mr-2 ml-2"
      style={{ flex: 1 }}
    >
      <label className="m-0 mr-2" htmlFor={filterKey}>
        {name}
      </label>
      <Field
        component={renderSelectMappingField}
        isLoading={isLoading}
        mapping={values}
        name={filterKey}
      />
    </div>
  );
};

export const FilterDateComponent = (props) => {
  const { filterKey, name } = props;

  return (
    <div
      className="d-flex flex-row align-items-center mr-2 ml-2"
      style={{ flex: 1 }}
    >
      <label className="m-0 mr-2" htmlFor={filterKey}>
        {name}
      </label>
      <Field component={renderDateField} name={filterKey} />
    </div>
  );
};

export const FilterCoverPublicationDatComponent = (props) => {
  const { filterKey, name } = props;

  return (
    <div
      className="d-flex flex-row align-items-center mr-2 ml-2"
      style={{ flex: 2 }}
    >
      <label className="m-0 mr-2" htmlFor={filterKey}>
        {name}
      </label>
      <Row className="w-100" style={{ flex: 2 }}>
        <Col>
          <Row>
            <Col>
              <Field
                component={renderSelectMappingField}
                label="Mese"
                name="month"
                mapping={MONTH_MAPPING}
              />
            </Col>
            <Col>
              <Field
                component={renderSelectMappingField}
                label="Anno"
                name="year"
                mapping={YEARS_MAPPING}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const FilterYearComponent = (props) => {
  const { filterKey, name } = props;

  return (
    <div
      className="d-flex flex-row align-items-center mr-2 ml-2"
      style={{ flex: 2 }}
    >
      <label className="m-0 mr-2" htmlFor={filterKey}>
        Anno
      </label>
      <Row className="w-10 mt-3" style={{ flex: 2 }}>
        <Col>
          <Row>
            <Col>
              <Field
                component={renderSelectMappingField}
                name="year"
                mapping={YEARS_MAPPING}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const MEDIA_MAPPING = [
  { value: "-", label: "Tutti" },
  { value: "PDF", label: "Pdf" },
  { value: "File", label: "File" },
  { value: "Video", label: "Video" },
  { value: "IMG", label: "Immagine" },
];

export const FilterMediaComponent = (props) => {
  const { filterKey, name } = props;

  return (
    <div
      className="d-flex flex-row align-items-center mr-2 ml-2"
      style={{ flex: 1 }}
    >
      <label className="m-0 mr-2" htmlFor={filterKey}>
        {name}
      </label>
      <Field
        component={renderSelectMappingField}
        name={filterKey}
        type="select"
        mapping={MEDIA_MAPPING}
      />
    </div>
  );
};
