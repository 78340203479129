import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class BooksellersService {
  async getBooksellers (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }

    return axios.get(`${API_ENDPOINT}/booksellers`, { params: params })
  }

  async index () {
    return axios.get(`${API_ENDPOINT}/booksellers`)
  }

  async get (id) {
    return axios.get(`${API_ENDPOINT}/booksellers/${id}`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/booksellers`, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/booksellers/${id}`, { data: body })
  }

  async delete (id, body) {
    return axios.delete(`${API_ENDPOINT}/booksellers/${id}`, { data: body })
  }

  async performances (id, params = {}) {
    return axios.get(`${API_ENDPOINT}/booksellers/${id}/performances`, { params: params })
  }
}

export default new BooksellersService()
