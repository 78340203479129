import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  Button
} from 'reactstrap'
import styles from './MultipleMediaModal.module.scss'
import FileInput from './FileInput'
import FileToSubmit from './FileToSubmit'

const MultipleMediaModal = ({
  row,
  mediaParser,
  rowTitle,
  isOpen,
  onToggle,
  onClosed,
  onSubmit,
  types,
  defaultType,
  canEdit
}) => {
  const [files, setFiles] = useState([])
  const [allowSubmitEmpty, setAllowSubmitEmpty] = useState(false)
  useEffect(() => {
    if (row) {
      const newFiles = row.media.map(mediaFile => mediaParser(mediaFile))
      setAllowSubmitEmpty(!!newFiles.length)
      setFiles(newFiles)
    }
  }, [row])

  if (!row) {
    return null
  }
  const title = `Aggiungi media a ${rowTitle}`
  const handleInputChange = file => setFiles([...files, file])
  const handleDelete = file => {
    setFiles(prevState =>
      prevState.filter(f => {
        const namesSame = f.file.name === file.file.name
        const typesSame = f.type === file.type

        return !(namesSame && typesSame)
      })
    )
  }

  return (
    <Modal
      className={styles.MultipleMediaModal}
      isOpen={isOpen}
      toggle={onToggle}
      onClosed={onClosed}
    >
      <Card>
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close' onClick={onToggle}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody className='d-flex flex-column justify-content-end'>
          {files.map(file => (
            <FileToSubmit
              key={file}
              file={file}
              handleDelete={handleDelete}
              canEdit={canEdit}
            />
          ))}
          {canEdit && (
            <FileInput
              types={types}
              defaultType={defaultType}
              handleChange={handleInputChange}
            />
          )}
        </CardBody>
        {canEdit && (
          <div className='d-flex flex-column'>
            <Button
              className='col-md-4 btn-primary'
              disabled={!allowSubmitEmpty && !files.length}
              onClick={() => {
                onSubmit(row, files)
                onToggle()
              }}
            >
              Carica file
            </Button>
          </div>
        )}
        <CardFooter />
      </Card>
    </Modal>
  )
}
export default MultipleMediaModal
