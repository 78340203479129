import React, { Component } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import UsersService from 'services/UsersService'
import User from 'models/user'
import { EDITOR_PDE } from 'constants/users'
import PublishersService from 'services/PublishersService'

import DataTable from '../DataTable'
import RowFormatter from './RowFormatter'

import styles from './PublisherEditorsPDETable.module.scss'

const getColumns = (columnsProps) => (
  [
    {
      key: 'name',
      name: 'Editor PDE User',
      sortable: true,
      formatter: (props) => <RowFormatter {...props} {...columnsProps} />,
      filterable: true
    }
  ]
)

class PublisherEditorsPDETable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      publisher: this.props.publisher,
      columnsProps: { publisher: this.props.publisher, onAssociate: this.onAssociate },
      users: [],
      totalRows: 0
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.publisher !== prevState.publisher) {
      return { publisher: nextProps.publisher, columnsProps: { ...prevState.columnsProps, publisher: nextProps.publisher } }
    }

    return null
  }

  onChange = (params) => {
    this.fetchUsers(params)
  }

  fetchUsers = ({ page: { number, size }, formFilterData }) => {
    const filterData = {
      ...formFilterData,
      role: EDITOR_PDE
    }

    UsersService
      .getUsers(number, size, filterData)
      .then((response) => {
        const { data: { results, totalRecords } } = response
        const users = results.map(response => User.fromResponse(response))

        this.setState({ users, totalRows: totalRecords })
      })
      .catch(() => { })
  }

  onAssociate = (user) => {
    const { publisher } = this.props
    const { _id } = user

    if (publisher) {
      if (!publisher.editorsPDE.includes(_id)) {
        const body = { editorsPDE: [...publisher.editorsPDE, _id] }
        // todo: for dev only. An easy way to reset all associated editors
        // const body = { editorsPDE: [] }

        PublishersService
          .update(publisher._id, body)
          .then((response) => {
            const { data: { flash, publisher: publisherData } } = response
            const { onUpdatePublisherEditorsPDE } = this.props

            onUpdatePublisherEditorsPDE && onUpdatePublisherEditorsPDE(publisher._id, publisherData)
            if (flash) {
              toast.success(flash['message'])
            }
          })
          .catch((error) => {
            toast.error(error)
          })
      }
    }
  }

  render () {
    const { users, totalRows, columnsProps } = this.state

    return (
      <DataTable className={styles.PublisherEditorsPDETable}
        columns={getColumns}
        columnsProps={columnsProps}
        rowsPerPageDefaultValue={10}
        data={users}
        totalRowsCount={totalRows}
        onChange={this.onChange}
      />
    )
  }
}

PublisherEditorsPDETable.propTypes = {
  publisher: PropTypes.object.isRequired
}

export default PublisherEditorsPDETable
