// login
export const AUTH_LOGIN_USER_REQUEST = 'AUTH_LOGIN_USER_REQUEST'
export const AUTH_LOGIN_USER_FAILURE = 'AUTH_LOGIN_USER_FAILURE'
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS'
// user
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'

// verify jwt token
export const AUTH_VERIFY_TOKEN_REQUEST = 'AUTH_VERIFY_TOKEN_REQUEST'
export const AUTH_VERIFY_TOKEN_FAILURE = 'AUTH_VERIFY_TOKEN_FAILURE'
export const AUTH_VERIFY_TOKEN_SUCCESS = 'AUTH_VERIFY_TOKEN_SUCCESS'

// logout
export const AUTH_LOGOUT_USER_REQUEST = 'AUTH_LOGOUT_USER_REQUEST'
export const AUTH_LOGOUT_USER_FAILURE = 'AUTH_LOGOUT_USER_FAILURE'
export const AUTH_LOGOUT_USER_SUCCESS = 'AUTH_LOGOUT_USER_SUCCESS'
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER'

// notifications
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST'
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE'
export const NOTIFICATIONS_HIDE_ALL = 'NOTIFICATIONS_HIDE_ALL'
