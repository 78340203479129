import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class PublishersService {
  async getPublishers (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }

    return axios.get(`${API_ENDPOINT}/publishers`, { params: params })
  }

  async index () {
    return axios.get(`${API_ENDPOINT}/publishers`)
  }

  async names () {
    return axios.get(`${API_ENDPOINT}/publishers-public/names`)
  }

  async show (id) {
    return axios.get(`${API_ENDPOINT}/publishers/${id}`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/publishers`, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/publishers/${id}`, { data: body })
  }

  async delete (id, body) {
    return axios.delete(`${API_ENDPOINT}/publishers/${id}`, { data: body })
  }

  async getAuthors (id) {
    return axios.get(`${API_ENDPOINT}/publishers/${id}/authors`)
  }

  async getCovers (id) {
    return axios.get(`${API_ENDPOINT}/publishers/${id}/covers`)
  }

  async getCoversByAuthor (id, authorId) {
    return axios.get(`${API_ENDPOINT}/publishers/${id}/covers/${authorId}`)
  }
}

export default new PublishersService()
