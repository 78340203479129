import React, { useState, useEffect, Fragment } from 'react'
import ExternalTokensService from 'services/ExternalTokensService'
import Loader from 'components/Loader'

import styles from './ExternalTokens.module.scss'
import { Alert, Button } from 'reactstrap'

const ExternalTokens = () => {
  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchToken()
  }, [])

  function fetchToken () {
    ExternalTokensService
      .get()
      .then(({ data }) => {
        const token = data.token

        setToken(token)
        setLoading(false)
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }

  function deleteToken () {
    setLoading(true)

    ExternalTokensService
      .delete(token)
      .then(() => {
        fetchToken()
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }

  function generateToken () {
    setLoading(true)

    ExternalTokensService
      .create()
      .then(({ data }) => {
        const token = data.token

        setToken(token)
        setLoading(false)
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }

  return (
    <div className={styles.tokens}>
      <section className={styles.Container}>
        <h2>API Access Token</h2>
        <section>
          <p>
            Qui puoi trovare informazioni sui token. Il token ti consente di accedere all'applicazione senza essere stato registrato
          </p>
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              {error && <Alert color='danger'>{error.message}</Alert>}
              {token ? (
                <Fragment>
                  <div className='d-flex mb-3'>
                    <Button className='ml-auto mr-3' color='success' onClick={generateToken}>Refresh</Button>
                    <Button color='danger' onClick={deleteToken}>Delete</Button>
                  </div>

                  <div className='p-2 border border-dark' style={{ borderRadius: '4px' }}>
                    {token}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <p>
                    Non hai ancora alcun token. Premi Genera e inizia a usare
                  </p>
                  <Button color='success' onClick={generateToken}>Genera</Button>
                </Fragment>
              )}
            </Fragment>
          )}
        </section>
      </section>
    </div>
  )
}

export default ExternalTokens
