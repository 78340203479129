import React from 'react'
import cx from 'classnames'
import { Alert, Button } from 'reactstrap'

import styles from './Notification.module.scss'

const Notification = (props) => {
  const {
    _id,
    event,
    message,
    onRemove
  } = props

  const color = event.toLowerCase() === 'deleted' ? 'danger' : 'primary'

  return (
    <div style={{ overflow: 'hidden' }}>
      <Alert className={cx('dropdown-item', styles.Notification)} color={color}>
        <span>{message}</span>
        <Button close onClick={() => onRemove(_id)} />
      </Alert>
    </div>

  )
}

export default Notification
