import React from 'react'
import styles from './Switch.module.scss'
import { Input } from 'reactstrap'

const Switch = (props) => {
  return (
    <label className={styles.SwitchWrapper}>
      <Input {...props} type='checkbox' checked={props.value} />
      <div className={styles.Switch} />
    </label>
  )
}

export default Switch
