import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class UsersService {
  async getUsers (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }

    return axios.get(`${API_ENDPOINT}/users`, { params: params })
  }

  async index () {
    return axios.get(`${API_ENDPOINT}/users`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/users`, { data: body })
  }

  async signup (body) {
    return axios.post(`${API_ENDPOINT}/signup`, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/users/${id}`, { data: body })
  }

  async delete (id) {
    return axios.delete(`${API_ENDPOINT}/users/${id}`)
  }
}

export default new UsersService()
