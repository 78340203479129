import React from 'react'
import Tooltip from 'components/Tooltip'

const legendStyles = {
  item: {
    margin: '0 1rem'
  },
  icon: {
    width: '24px',
    height: '24px',
    border: '1px solid transparent',
    borderRadius: '50%'
  },
  standard: {
    backgroundColor: '#4dbd74'
  },
  possible: {
    backgroundColor: '#ffc107'
  },
  risk: {
    backgroundColor: '#f86c6b'
  }
}

const LegendsTexts = {
  standard: 'Performance will start in more than 5 working days',
  possible: 'Performance will start in 3 or more working days',
  risk: 'Performance will start in less of 3 working days'
}

const LegendInfoTooltip = ({ id, text }) => {
  return (
    <Tooltip id={id} placement='top' text={text}>
      <div className='ml-1' style={{ cursor: 'pointer', userSelect: 'none' }}>{'(?)'}</div>
    </Tooltip>
  )
}

const EventPerformanceLegend = () => {
  return (
    <div className='card mr-3 ml-3'>
      <div className='card-body d-flex flex-row align-items-center justify-content-end'>
        <div className='d-flex flex-row justify-content-start align-items-center mr-2'>
          <div style={{ ...legendStyles.icon, ...legendStyles.standard }} />
          <span className='ml-1'>{'Standard'}</span>
          <LegendInfoTooltip id='standard' text={LegendsTexts.standard} />
        </div>
        <div className='d-flex flex-row justify-content-start align-items-center mr-2'>
          <div style={{ ...legendStyles.icon, ...legendStyles.possible }} />
          <span className='ml-1'>{'Possible'}</span>
          <LegendInfoTooltip id={'possible'} text={LegendsTexts.possible} />
        </div>
        <div className='d-flex flex-row justify-content-start align-items-center mr-2'>
          <div style={{ ...legendStyles.icon, ...legendStyles.risk }} />
          <span className='ml-1'>{'Risk'}</span>
          <LegendInfoTooltip id={'risk'} text={LegendsTexts.risk} />
        </div>
      </div>
    </div>
  )
}
export default EventPerformanceLegend
