import React, { Component } from "react";
import { Alert, Col, Row } from "reactstrap";

import SimpleFormView from "containers/views/SimpleFormView";
import SignUpForm from "../../../components/forms/SignUpForm";
import UsersService from "../../../services/UsersService";
import { NavLink } from "react-router-dom";
import { BOOK_SELLER, EDITOR_PDE } from "../../../constants/users";

class SignUpView extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      userCreated: null,
      kind: null,
    };
  }

  onSubmit = async (values) => {
    UsersService.signup(values)
      .then(({ data }) => {
        this.setState((state) => {
          return {
            ...state,
            userCreated: data,
          };
        });
      })
      .catch((error) => {
        const { response } = error;
        response &&
          this.setState((state) => {
            return {
              ...state,
              error: response.data,
            };
          });
      });
  };

  renderError = (error) => {
    if (!error) {
      return null;
    }

    const { details } = error;
    return (
      <Row>
        <Col>
          <Alert color="danger">{details}</Alert>
        </Col>
      </Row>
    );
  };

  renderUserCreated = (value) => {
    if (!value) {
      return null;
    }

    const { message } = value;
    return (
      <Row>
        <Col>
          <Alert color="primary">{message}</Alert>
        </Col>
      </Row>
    );
  };

  render() {
    const { isRestoring, kind } = this.props;
    const { error, userCreated } = this.state;

    const role = kind === "editor" ? EDITOR_PDE : BOOK_SELLER;
    return (
      <SimpleFormView isLoading={isRestoring} loadingText="Registration...">
        <h1>Registrati</h1>
        <p className="text-muted">Crea il tuo profilo</p>
        {this.renderError(error)}
        {this.renderUserCreated(userCreated)}
        {!error && !userCreated && (
          <SignUpForm onSubmit={this.onSubmit} initialValues={{ role: role }} />
        )}
        <div className="row flex-row">
          <strong>
            <NavLink
              className="d-flex align-items-center text-decoration-none mt-4 align-self-start flex-grow-1"
              to="/signin"
              style={{ lineHeight: "16px" }}
            >
              Sei già registrato? Clicca qui per accedere.
              <i className="fa fa-arrow-right fa-xs ml-1" />
            </NavLink>
          </strong>
          {/* <NavLink className='d-flex align-items-center text-decoration-none mt-4 align-self-end' to='/signup' style={{ lineHeight: '16px' }}>
            Registrati
            <i className='fa fa-arrow-right fa-xs ml-1' />
          </NavLink> */}
        </div>
      </SimpleFormView>
    );
  }
}

export default SignUpView;
