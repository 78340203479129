import React from "react";

export default ({ value, row }) => {
  const { author, authors } = row;
  if (author) {
    // return (
    //   <div>
    //     {author
    //       ? `<a href="/authors/${author["_id"]}">${author["name"]} ${author["surname"]}</a>`
    //       : "Unknown author"}
    //   </div>
    // );
    return (
      <div>
        {author
          ? `<span>${author["name"]} ${author["surname"]}</span>`
          : "Unknown author"}
      </div>
    );
  }

  if (authors) {
    return (
      <div
        className="text-left w-100"
        style={{ maxHeight: "100px", overflowY: "scroll" }}
      >
        {authors.map(({ _id, name, surname }) => (
          <div key={_id}>
            {/* <a href={`/authors/${_id}`}>
              {name} {surname}
            </a> */}
            <span>
              {name} {surname}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};
