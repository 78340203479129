import React from 'react'

import { PrivateRoute } from './PrivateRoute'
import withAuth from 'containers/auth.container'

const StaffRoute = props => {
  const { isStaff } = props

  return (
    <PrivateRoute
      {...props}
      isAuthenticated={isStaff}
    />
  )
}

export default withAuth(StaffRoute)
