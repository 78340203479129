class Author {
  constructor (authorData) {
    this._id = authorData._id
    this.name = authorData.name
    this.surname = authorData.surname
    this.publisher = authorData.publisher
    this.description = authorData.description
    this.photo = authorData.photo
    this.birthDate = authorData.birthDate
    this.creatorId = authorData.creatorId
    this.covers = authorData.covers
  }

  static fromResponse = (authorData) => {
    return new Author(authorData)
  }
}

export default Author
