export const MONTH_MAPPING = [
  { value: 0, label: 'Gennaio' },
  { value: 1, label: 'Febbraio' },
  { value: 2, label: 'Marzo' },
  { value: 3, label: 'Aprile' },
  { value: 4, label: 'Maggio' },
  { value: 5, label: 'Giugno' },
  { value: 6, label: 'Luglio' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Settembre' },
  { value: 9, label: 'Ottobre' },
  { value: 10, label: 'Novembre' },
  { value: 11, label: 'Dicembre' }
]

const currentYear = (new Date()).getFullYear()
const range = (start, stop, step) => {
  return Array
    .from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
}

export const YEARS_MAPPING = range(currentYear, currentYear - 200, -1)
  .map(y => ({ value: y, label: y }))
