import React from 'react'
import { Switch } from 'react-router-dom'
import ProfileSettings from './ProfileSettings'
import PrivateRoute from '../components/routes/PrivateRoute'
import Profile from './Profile'

const ProfileRouter = (props) => {
  const { path } = props.match

  return (
    <Profile>
      <Switch>
        <PrivateRoute path={`${path}/settings`} component={ProfileSettings} allowedRoles='all' />
      </Switch>
    </Profile>
  )
}

export default ProfileRouter
