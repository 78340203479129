import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Field, formPropTypes, reduxForm } from 'redux-form'
import { Col, Row } from 'reactstrap'
import countryList from 'react-select-country-list'

import { renderFormGroupTextField, renderSelectMappingField } from 'components/forms/formFields'
import { required, zipCode } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'
import FormModal from '../FormModal'

import styles from '../FormModalBase.module.scss'
import booksellerModalStyles from './AddBooksellerFormModal.module.scss'
import { email, url } from '../../forms/validators'
import { CHANNEL_TYPES } from '../../../constants/booksellers.js'

const AddBooksellerFormModal = (props) => {
  const { title, error, disabled } = props
  return (
    <FormModal {...props} className={cx(styles.FormModalBase, booksellerModalStyles.BooksellerFormModal)} formTitle={title}>
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Cognome'
            name='surname'
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Email'
            name='email'
            type='email'
            disabled={disabled}
            validate={disabled ? [email] : []}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Telefono'
            name='telephone'
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Ragione Sociale'
            name='companyName'
            validate={[required]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Indirizzo'
            name='address'
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Nazione'
            name='country'
            mapping={countryList().getData()}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Provincia'
            name='province'
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Città'
            name='city'
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='CAP'
            name='zipCode'
            validate={[zipCode]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Codice'
            name='code'
            validate={[required]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Sito web'
            name='website'
            validate={disabled ? [] : [url]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Facebook'
            name='facebook'
            validate={disabled ? [] : [url]}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Channel'
            name='channel'
            validate={[]}
            disabled={disabled}
            mapping={CHANNEL_TYPES}
          />
        </Col>
      </Row>
    </FormModal>
  )
}

AddBooksellerFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

AddBooksellerFormModal.defaultProps = {
  title: 'Editori'
}

export default reduxForm({
  form: 'AddBooksellerFormModal'
})(AddBooksellerFormModal)
