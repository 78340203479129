import React, { Component } from "react";
import cx from "classnames";
import Pagination from "react-js-pagination";
import { Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";

import handleFormSubmitError from "helpers/forms/handleFormSubmitError";
import SimpleTable from "components/tables/SimpleTable";
import createFormModal from "components/tables/createFormModal";
import createDataTableFilter from "components/tables/createDataTableFilter";
import { dataTablePropTypes } from "components/tables/dataTablePropTypes";

import styles from "./DataTable.module.scss";

class DataTable extends Component {
  constructor(props) {
    super(props);

    const {
      data,
      columns,
      columnsProps,
      totalRowsCount,
      selectPagesOptions,
      rowsPerPageDefaultValue,
    } = this.props;

    this.state = {
      initialRows: data,
      data: data,
      columns: columns,
      columnsProps: columnsProps,
      totalRowsCount: totalRowsCount,
      selectPagesOptions: selectPagesOptions,
      currentPage: 1,
      rowsPerPage: rowsPerPageDefaultValue,
      isFormModalOpened: false,
      formModalInitialData: null,
      isImportModalOpened: false,
      importModalInitialData: null,
      formFilterData: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;

    if (prevState.initialRows !== nextProps.data) {
      state = { initialRows: nextProps.data, data: nextProps.data };
    }

    if (prevState.totalRowsCount !== nextProps.totalRowsCount) {
      state = { ...state, totalRowsCount: nextProps.totalRowsCount };
    }

    if (prevState.columnsProps !== nextProps.columnsProps) {
      state = { ...state, columnsProps: nextProps.columnsProps };
    }

    return state;
  }

  componentDidMount() {
    this.onStateChanged();
  }

  onStateChanged = () => {
    const { onChange } = this.props;
    const { currentPage, rowsPerPage, formFilterData } = this.state;
    onChange &&
      onChange({
        page: {
          number: currentPage,
          size: rowsPerPage,
        },
        formFilterData: formFilterData,
      });
  };

  onSubmitFilter = (formFilterData) => {
    this.setState({ formFilterData: formFilterData, currentPage: 1 }, () => {
      this.onStateChanged();
    });
  };

  onChangeFilter = (formFilterData) => {
    this.setState({ formFilterData: formFilterData, currentPage: 1 }, () => {
      this.onStateChanged();
    });
  };

  onResetFilter = () => {
    this.onSubmitFilter({});
  };

  setCurrentPage = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.onStateChanged();
    });
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      this.onStateChanged();
    });
  };

  onShowData = () => {};

  onEditData = async (data) => {
    console.log("EDIT --> ", data);
    const { prepareModalData } = this.props;
    this.onToggleFormModal(
      null,
      "update",
      prepareModalData ? await prepareModalData(data) : data
    );
  };

  onRemoveData = (data) => {
    const { prepareModalData } = this.props;
    this.onToggleFormModal(
      null,
      "delete",
      prepareModalData ? prepareModalData(data) : data
    );
  };

  onToggleFormModal = (event, mode = null, formModalInitialData = null) => {
    this.setState({
      isFormModalOpened: !this.state.isFormModalOpened,
      formModalMode: mode,
      formModalInitialData: formModalInitialData,
    });
  };

  wrapFormSubmitRequest = (request) => {
    if (typeof request.then !== "undefined") {
      return request
        .then(() => {
          this.onToggleFormModal();
          this.onStateChanged();
        })
        .catch((error) => {
          handleFormSubmitError(error);
        });
    }
  };

  onFormModalClosed = () => {
    this.setState({ formModalInitialData: null });
  };

  onToggleImportModal = (event, mode = null, importModalInitialData = null) => {
    this.setState({
      isImportModalOpened: !this.state.isImportModalOpened,
      importModalMode: mode,
      importModalInitialData: importModalInitialData,
    });
  };

  wrapImportSubmitRequest = (request) => {
    console.log("request", request);
    if (typeof request.then !== "undefined") {
      return request
        .then(() => {
          this.onToggleImportModal();
          this.onStateChanged();
        })
        .catch((error) => {
          handleFormSubmitError(error);
        });
    }
  };

  onImportModalClosed = () => {
    this.setState({ importModalInitialData: null });
  };

  render() {
    const {
      title,
      importTitle,
      exportTitle,
      selectable,
      selectedRows,
      onSelectedRowChange,
      totalRowsCount,
      formModalProps,
      importModalProps,
      handleExport,
      createTableActions,
      allowToChangeRowsPerPage,
    } = this.props;

    const {
      data,
      columns,
      columnsProps,
      rowsPerPage,
      currentPage,
      selectPagesOptions,
      isFormModalOpened,
      formModalMode,
      formModalInitialData,
      formFilterData,
      isImportModalOpened,
      importModalMode,
      importModalInitialData,
    } = this.state;

    const formModalMergedProps = {
      ...formModalProps,
      ...(formModalInitialData && { initialValues: formModalInitialData }),
      disabled: formModalMode === "delete",
      mode: formModalMode,
      destroyOnUnmount: true,
      enableReinitialize: true,
      isOpen: isFormModalOpened,
      onClose: this.onFormModalClosed,
      toggle: this.onToggleFormModal,

      wrapFormSubmitRequest: this.wrapFormSubmitRequest,
    };
    const formModal = formModalProps && createFormModal(formModalMergedProps);

    const importModalMergedProps = {
      ...importModalProps,
      ...(importModalInitialData && { initialValues: importModalInitialData }),
      disabled: importModalMode === "delete",
      mode: importModalMode,
      destroyOnUnmount: true,
      enableReinitialize: true,
      isOpen: isImportModalOpened,
      onClose: this.onImportModalClosed,
      toggle: this.onToggleImportModal,
      wrapFormSubmitRequest: this.wrapImportSubmitRequest,
    };

    const importModal =
      importModalProps && createFormModal(importModalMergedProps);

    const simpleTableColumns = Array.isArray(columns)
      ? columns.filter((c) => !c.hidden)
      : columns({
          onShow: this.onShowData,
          onEdit: this.onEditData,
          onRemove: this.onRemoveData,
          ...columnsProps,
        }).filter((c) => !c.hidden);

    const dataTableFilter = createDataTableFilter({
      className: styles.FilterForm,
      columns: columns,
      onSubmitFilter: this.onSubmitFilter,
      // onChangeFilter: this.onChangeFilter,
      onResetFilter: this.onResetFilter,
    });

    return (
      <div className={cx(styles.DataTable, this.props.className)}>
        {dataTableFilter}

        <Card className={styles.CardTable}>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <i className="fa fa-align-justify" />
              <div>
                {formModalProps && (
                  <Button
                    color="success"
                    size="xs"
                    onClick={() => this.onToggleFormModal(null, "create")}
                  >
                    <i className="fa fa-plus mr-2" />
                    {title}
                  </Button>
                )}
                {importModalProps && (
                  <Button
                    color="info"
                    size="xs"
                    className="ml-2"
                    onClick={() => this.onToggleImportModal(null, "import")}
                  >
                    <i className="fa fa-upload mr-2" />
                    {importTitle}
                  </Button>
                )}
                {handleExport && (
                  <Button
                    color="warning"
                    size="xs"
                    className="ml-2"
                    onClick={() => handleExport(formFilterData)}
                  >
                    <i className="fa fa-download mr-2" />
                    {exportTitle}
                  </Button>
                )}
                {createTableActions && createTableActions()}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className={styles.TableContainer}>
              <SimpleTable
                selectable={selectable}
                data={data}
                columns={simpleTableColumns}
                onSelectedRowChange={onSelectedRowChange}
                selectedRows={selectedRows}
                className="m-0"
              />
            </div>
          </CardBody>
          <CardFooter>
            {allowToChangeRowsPerPage && (
              <div className="mt-2 d-flex justify-content-between">
                <div>
                  <span className="h5 mr-2">Rows per page:</span>
                  <select
                    className="form-control d-inline-block w-auto select-xs"
                    value={rowsPerPage}
                    onChange={({ target: { value } }) =>
                      this.setRowsPerPage(value)
                    }
                  >
                    {selectPagesOptions.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={rowsPerPage}
              totalItemsCount={totalRowsCount}
              pageRangeDisplayed={5}
              innerClass="pagination m-0"
              activeClass="active"
              itemClass="page-item"
              linkClass="page-link"
              onChange={(page) => this.setCurrentPage(page)}
            />
          </CardFooter>
        </Card>
        {formModal}
        {importModal}
      </div>
    );
  }
}

DataTable.propTypes = {
  ...dataTablePropTypes,
};

DataTable.defaultProps = {
  selectPagesOptions: [10, 15, 50, 100],
  rowsPerPageDefaultValue: 50,
  title: "Aggiungi",
  importTitle: "Importa da XLS",
  exportTitle: "Esporta in XLS",
  allowToChangeRowsPerPage: false,
};

export default DataTable;
