export const CHANNEL_TYPES = {
  'Altri': 'Altri',
  'Catene Centralizzate': 'Catene Centralizzate',
  'Fumetterie': 'Fumetterie',
  'GDO': 'GDO',
  'Grossisti GDO': 'Grossisti GDO',
  'Indipendenti e altre catene': 'Indipendenti e altre catene',
  'Ingrosso': 'Ingrosso',
  'Online': 'Online'
}
