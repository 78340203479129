import React from 'react'

import ProfileSettingsRouter from './ProfileSettingsRouter'

const ProfileSettings = (props) => {
  return (
    <ProfileSettingsRouter {...props} />
  )
}

export default ProfileSettings
