import React from 'react'

const CoverAuthorList = ({ authors, className }) => {
  return (
    <ul className={className}>
      {authors.length === 0 ? (
        <span className='d-block text-center'>Nessun autore trovato</span>
      ) : (
        authors.map(({ _id, name, surname }) => {
          return (
            <li key={_id}>
              { `${name} ${surname}` }
            </li>
          )
        }
        )
      )}
    </ul>
  )
}

CoverAuthorList.defaultProps = {
  authors: []
}

export default CoverAuthorList
