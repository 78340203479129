import React from 'react'
import cx from 'classnames'
import Select from 'react-select'
import SelectVirtualized from './SelectVirtualized'

import styles from './selectStyles'

export default ({ virtualized, ...props }) => {
  const { disabled, invalid } = props

  const C = virtualized ? SelectVirtualized : Select

  const defaultValue = { ...props.value }

  return (
    <C
      {...props}
      className={cx('react-select-container', { 'react-select-invalid': invalid })}
      classNamePrefix='react-select'
      styles={styles}
      defaultValue={defaultValue}
      isDisabled={disabled}
      components={{
        DropdownIndicator: null
      }}
    />
  )
}
