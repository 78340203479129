export const ADMIN = 'admin'
export const EDITOR_PDE = 'editor_pde'
export const ONLINE_SELLER = 'online_seller'
export const PUBLICATION_MANAGER = 'publication_manager'
export const BOOK_SELLER = 'book_seller'

export const USER_ROLES = {
  [ADMIN]: 'Amministatore',
  [EDITOR_PDE]: 'Editori Esterni',
  [ONLINE_SELLER]: 'Rivenditore Online',
  [PUBLICATION_MANAGER]: 'Gestore Pubblicazioni',
  [BOOK_SELLER]: 'Venditore di libri'
}

export const USER_ROLES_FROM_ADMIN = {
  [ADMIN]: 'Amministatore',
  [ONLINE_SELLER]: 'Rivenditore Online',
  [PUBLICATION_MANAGER]: 'Gestore Pubblicazioni'
}

export const SIGN_UP_USER_ROLES = {
  [EDITOR_PDE]: 'Editori Esterni',
  [BOOK_SELLER]: 'Librai'
}

export const SIGN_UP_EDITOR_ROLES = {
  [EDITOR_PDE]: 'Editori Esterni'
}

export const SIGN_UP_BOOKESELLER_ROLES = {
  [BOOK_SELLER]: 'Librai'
}

export const PERMISSIONS = {
  events: 'containers.views.Events',
  agents: 'Agents'
}

export const USER_STATUS = {
  true: 'Yes',
  false: 'No'
}
