import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'

import AuthService from 'services/AuthService'

const ProfileDropdownMenu = () => {
  const [opened, toggleOpen] = useState(false)

  const handleClick = ({ target }) => {
    if (!target.classList.contains('dropdown-item')) {
      toggleOpen(false)
    }
  }

  const logout = () => {
    AuthService.logout()
  }

  useEffect(() => {
    if (opened) {
      document.addEventListener('mousedown', handleClick)
    } else {
      document.removeEventListener('mousedown', handleClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [opened])

  return (
    <Fragment>
      <span className='nav-link' style={{ cursor: 'pointer' }} onClick={(e) => {
        // e.preventDefault()
        toggleOpen(!opened)
      }}>
        <i className='fa fa-user' style={{ color: '#ffffff' }} />
      </span>

      <div className={cx('dropdown-menu dropdown-menu-right', { show: opened })}>
        <div className='dropdown-header text-center'>
          <strong>Impostazioni</strong>
        </div>
        <NavLink className='dropdown-item' to='/profile/settings/profile'>
          <i className='fa fa-user' />
          Profile
        </NavLink>
        <NavLink className='dropdown-item' to='/profile/settings/security'>
          <i className='fa fa-wrench' />
          Password
        </NavLink>
        <div className='dropdown-divider' />
        <a className='dropdown-item' href='#' onClick={logout}>
          <i className='fas fa-sign-out-alt' /> Logout</a>
      </div>
    </Fragment>
  )
}

ProfileDropdownMenu.propTypes = {
  className: PropTypes.string
}

export default ProfileDropdownMenu
