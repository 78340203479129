import React from "react";
import { Link } from "react-router-dom";
import Cover from "../../../models/cover";
import fileDownload from "js-file-download";

const download = (url) => {
  // window.location.href = url;
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = url.split("/").pop();
      // link.click();
      // const file = window.URL.createObjectURL(blob);
      // window.location.assign(file);
      fileDownload(blob, url.split("/").pop());
    })
    .catch(console.error);
};

export default ({ value, row }) => {
  return (
    <div>
      <>
        {value && (
          <a
            onClick={() => download(value)}
            role="button"
            style={{ cursor: "pointer" }}
          >
            <i className={`fa fa-file`} style={{ color: "#63c2de" }} />
          </a>
        )}
      </>
    </div>
  );
};
