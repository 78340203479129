import React, { Fragment, useState } from 'react'

import { Formatters } from 'components/data-grid-table'
import BooksCatalogService from 'services/BooksCatalogService'
import Books from 'models/book'
import { FilterMediaComponent } from 'components/data-grid-table/filters/components'

import DataTable from './DataTable'
import withAuth from '../../containers/auth.container'
import { BOOK_SELLER, EDITOR_PDE, ADMIN } from '../../constants/users'
import MultipleMediaModal from '../modals/MultipleMediaModal'
import FileUploadService from '../../services/FileUploadService'
import { MediaFormatter } from '../data-grid-table/formatters'

const getColumns = (role, outerProps) => props => {
  const columns = [
    {
      key: 'EAN',
      name: 'EAN',
      filterable: true
    },
    {
      key: 'title',
      name: 'Titolo',
      filterable: true
    },
    {
      key: 'author',
      name: 'Autore',
      filterable: true
    },
    {
      key: 'editor',
      name: 'Editore',
      filterable: true
    },
    {
      key: 'price',
      name: 'Prezzo',
      filterable: true
    },
    {
      key: 'format',
      name: 'Formato',
      filterable: true
    },
    {
      key: 'media',
      name: 'Extra Media',
      cellClass: 'justify-content-center',
      filterable: true,
      filterRenderComponent: FilterMediaComponent,
      formatter: MediaFormatter
    },
    {
      key: 'actions',
      name: 'Azioni',
      formatter: Formatters.UpcomingBooksFormatter({ ...props, ...outerProps }),
      cellClass: 'justify-content-center'
    }
  ]
  return columns
}

const UpcomingBooksDataTable = ({ user, isStaff, isEditorPDE, ...rest }) => {
  const [books, setBooks] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [multipleMediaModalOpened, setMultipleMediaModalOpened] = useState(
    false
  )
  const [selectedRow, setSelectedRow] = useState(null)

  const getterByRole = {
    [BOOK_SELLER]: BooksCatalogService.getUpcomingBooks,
    [ADMIN]: BooksCatalogService.getUpcomingBooks,
    [EDITOR_PDE]: BooksCatalogService.getMyUpcomingBooks
  }
  const fetch = async ({ page: { number, size }, formFilterData }) => {
    try {
      const {
        data: { results, totalRecords }
      } = await getterByRole[user.role](number, size, formFilterData)
      const booksList = results.map(bookData => Books.fromResponse(bookData))
      setBooks(booksList)
      setTotalRows(totalRecords)
    } catch (e) {}
  }

  const onChange = params => fetch(params)

  const updateMedia = async (row, files) => {
    const newMedia = []
    if (files.length) {
      for (const file of files) {
        try {
          const {
            data: { uploadedFileData }
          } = await FileUploadService.uploadToRollout(file.file)
          newMedia.push({
            fileType: file.type,
            url: uploadedFileData.url,
            name: file.file.name
          })
        } catch (e) {
          if (e.message === 'Incorrect file format') {
            // it means, that it's already existed file
            // So just push it back to not to lose
            newMedia.push({
              fileType: file.type,
              url: file.url,
              name: file.file.name
            })
          } else {
            throw new Error(e)
          }
        }
      }
    }
    const { data } = await BooksCatalogService.updateUpcomingBook(row._id, {
      media: newMedia
    })
    setBooks(prevBooks =>
      prevBooks.map(book =>
        book._id === data._id ? Books.fromResponse(data) : book
      )
    )
  }

  const columnsOuterProps = {
    openAddMediaModal: row => {
      setMultipleMediaModalOpened(true)
      setSelectedRow(row)
    }
  }

  const parseMedia = media => ({
    file: {
      name: media.name
    },
    type: media.fileType,
    url: media.url
  })
  const types = {
    Video: 'video/*',
    PDF: '.pdf',
    IMG: 'image/*',
    File: '*'
  }
  return (
    <Fragment>
      <DataTable
        columns={getColumns(user.role, columnsOuterProps)}
        data={books}
        totalRowsCount={totalRows}
        onChange={onChange}
      />
      <MultipleMediaModal
        isOpen={multipleMediaModalOpened}
        onSubmit={updateMedia}
        rowTitle={selectedRow ? selectedRow.title : ''}
        row={selectedRow}
        types={types}
        mediaParser={parseMedia}
        canEdit={isStaff || isEditorPDE}
        onToggle={() => setMultipleMediaModalOpened(!multipleMediaModalOpened)}
      />
    </Fragment>
  )
}
export default withAuth(UpcomingBooksDataTable)
