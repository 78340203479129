import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Field } from 'redux-form'

import { renderFileInput, renderFormGroupTextField, renderSelectMappingField } from 'components/forms/formFields'
import { required } from 'components/forms/validators'

import Accordion from '../AddEventFormModal/Accordion'
import coverFormModalStyles from './AddCoverFormModal.module.scss'

export const renderExtraPages = (props) => {
  const {
    fields,
    disabled,
    imageTypesMapping,
    additionalImages,
    onAddExtraPage,
    onRemoveExtraPage,
    onChangeType,
    onChangeFile
  } = props

  const filterImageTypes = (images, typesMapping) => {
    if (images && images.length > 0) {
      const selected = images.reduce((memo, { type }) => {
        if (!memo.includes(type)) {
          memo.push(type)
        }
        return memo
      }, [])

      if (selected.length > 0) {
        return Object.keys(typesMapping).reduce((memo, type) => {
          if (type !== 'in' && type !== 'pt') {
            if (!selected.includes(type)) {
              memo[type] = typesMapping[type]
            }
          } else {
            memo[type] = typesMapping[type]
          }

          return memo
        }, {})
      }
    }

    return typesMapping
  }

  const addExtraPage = () => {
    if (onAddExtraPage) {
      onAddExtraPage(fields)
    }
  }

  const removeExtraPage = (index) => {
    if (onRemoveExtraPage) {
      onRemoveExtraPage(fields, index)
    }
  }

  return (
    <div>
      <div className='d-flex mb-2'>
        <Button className='mr-auto' color='success' size='xs' onClick={addExtraPage}>
          <i className='fa fa-plus mr-2' />
          Aggiungi pagina
        </Button>
      </div>
      {fields.map((extraImage, index) => {
        const { isNew } = additionalImages[index]
        return (
          <Accordion className={coverFormModalStyles.Accordion} key={index} editable={!disabled} expandOnMound={isNew} header='Immagini aggiuntive'>
            <Row>
              <Col>
                <div className='w-100 d-flex mb-2 justify-content-end'>
                  <Button color='danger' size='xs' disabled={disabled} onClick={() => removeExtraPage(index)}>
                    <i className='fas fa-trash' style={{ color: '#ffffff' }} />
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={renderFormGroupTextField}
                  label='Nome file'
                  name={`${extraImage}.fileName`}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={renderSelectMappingField}
                  label='Tipo'
                  name={`${extraImage}.imageType`}
                  onChange={(value) => onChangeType(index, value)}
                  mapping={filterImageTypes(additionalImages, imageTypesMapping)}
                  validate={disabled ? [] : [required]}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  label='Immagine'
                  component={renderFileInput}
                  name={`${extraImage}.image`}
                  accept='image/jpg, image/jpeg'
                  onChange={(event) => onChangeFile(index, event.target.value)}
                  validate={disabled ? [] : [required]}
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Accordion>
        )
      })}
    </div>
  )
}
