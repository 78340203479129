import React, { useCallback, useEffect, useState } from "react";
import { compact } from "lodash";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row } from "reactstrap";
import {
  Field,
  FieldArray,
  formPropTypes,
  formValueSelector,
  reduxForm,
} from "redux-form";

import { COMMERCIAL } from "constants/organizationTypes";
import { required } from "components/forms/validators";
import {
  renderFormGroupTextField,
  renderSelectMappingField,
} from "components/forms/formFields";
import ResponseError from "components/ResponseError";
import CoversService from "services/CoversService";
import ModalsService from "services/ModalsService";
import BooksellersService from "services/BooksellersService";
import EventPerformance from "models/eventPerformance";

import { renderEventPerformances } from "./renderEventPerformances";

import FormModal from "../FormModal";
import styles from "../FormModalBase.module.scss";
import CoverAuthorList from "./CoverAuthorsList";
import eventModalStyles from "./AddEventFormModal.module.scss";

const AddEventFormModal = (props) => {
  const { title, error, disabled, isOpen, selectedCoverId, performances } =
    props;

  const [covers, setCovers] = useState({
    isLoading: false,
    covers: [],
    mapping: [],
    isLoaded: false,
  });
  const [authors, setAuthors] = useState([]);
  const [booksellers, setBooksellers] = useState({
    isLoading: true,
    data: [],
    mapping: [],
    isLoaded: false,
  });

  const searchEan = useCallback(
    (search) => {
      setCovers({ isLoading: true, isLoaded: false, covers: [], mapping: [] });
      CoversService.getCovers(
        1,
        40,
        Object.assign({ rollouts: true }, { ...search })
      )
        .then((response) => {
          const {
            data: { results },
          } = response;

          const coversMapping = results.reduce((memo, { _id, ean }) => {
            memo[_id] = ean;
            return memo;
          }, {});

          setCovers({
            isLoading: false,
            isLoaded: true,
            covers: results,
            mapping: coversMapping,
          });
        })
        .catch(() => {
          setCovers({
            isLoading: false,
            isLoaded: false,
            covers: [],
            mapping: [],
          });
        });
    },
    [setCovers]
  );

  const eanChange = useCallback(
    (value) => {
      if (value && value.length >= 3 && !covers.isLoading) {
        searchEan({ ean: value });
      }
    },
    [searchEan, covers]
  );

  const onAddPerformance = useCallback((fields) => {
    fields.push(new EventPerformance());
  }, []);

  const onRemovePerformance = useCallback((fields, index) => {
    ModalsService.open("confirmEventPerformanceDeleteModal", {
      title: "Elimina data",
      text: "Elimina data?",
      onSubmit: () => {
        fields.remove(index);
        ModalsService.close("confirmEventPerformanceDeleteModal");
      },
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setBooksellers({
        isLoading: true,
        isLoaded: false,
        data: [],
        mapping: [],
      });
      BooksellersService.index()
        .then((response) => {
          const {
            data: { booksellers },
          } = response;

          const booksellersMapping = booksellers.reduce(
            (memo, { companyName, code, address, city }) => {
              const parts = [
                code,
                compact([companyName, address, city]).join(", "),
              ];
              memo[code] = parts.join(": ");
              return memo;
            },
            {}
          );

          setBooksellers({
            isLoading: false,
            isLoaded: true,
            data: booksellers,
            mapping: booksellersMapping,
          });
        })
        .catch(() => {
          setBooksellers({
            isLoading: false,
            isLoaded: false,
            data: [],
            mapping: [],
          });
        });
    } else {
      setAuthors([]);
      setCovers({ isLoading: false, isLoaded: false, covers: [], mapping: [] });
      setBooksellers({
        isLoading: false,
        isLoaded: false,
        data: [],
        mapping: [],
      });
    }
  }, [isOpen, setBooksellers, setCovers, setAuthors]);

  useEffect(() => {
    if (selectedCoverId && covers.isLoaded) {
      const selected = covers.covers.find(({ _id }) => _id === selectedCoverId);

      if (selected) {
        if (selected.title) {
          props.change("title", selected.title);
        }

        if (selected.authors) {
          setAuthors(selected.authors);
        }
      }
    } else if (selectedCoverId && !covers.isLoaded && !covers.isLoading) {
      searchEan({ _id: selectedCoverId });
    }
  }, [selectedCoverId, covers, setAuthors, props, searchEan]);

  return (
    <FormModal
      {...props}
      formTitle={title}
      className={cx(styles.FormModalBase, eventModalStyles.EventFormModal)}
    >
      {error && <ResponseError error={error} />}
      <Row className={eventModalStyles.EventData}>
        <Col>
          <Field
            component={renderSelectMappingField}
            label="EAN"
            name="cover"
            mapping={covers.mapping}
            isLoading={covers.isLoading}
            validate={disabled ? [] : [required]}
            disabled={disabled}
            onInputChange={(value) => {
              eanChange(value);
            }}
          />
        </Col>
        <Col>
          <FormGroup>
            <Label>Autori</Label>
            <CoverAuthorList
              className={eventModalStyles.AuthorsList}
              authors={authors}
            />
          </FormGroup>
        </Col>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label="Titolo"
            name="title"
            validate={[required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldArray
            name="performances"
            performances={performances}
            booksellers={booksellers}
            disabled={disabled}
            onAddPerformance={onAddPerformance}
            onRemovePerformance={onRemovePerformance}
            component={renderEventPerformances}
          />
        </Col>
      </Row>
    </FormModal>
  );
};

AddEventFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string,
};

AddEventFormModal.defaultProps = {
  title: "Evento",
};

const selector = formValueSelector("addEventModalForm");

const mapStateToProps = (state) => {
  const cover = selector(state, "cover");
  const performances = selector(state, "performances");
  const organizationType = selector(state, "organizationType");

  return {
    selectedCoverId: cover,
    performances: performances,
    isCommercial: organizationType === COMMERCIAL,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     change: (form, field, value) => { dispatch(change(form, field, value)) }
//   }
// }

export default reduxForm({
  form: "addEventModalForm",
})(connect(mapStateToProps)(AddEventFormModal));
