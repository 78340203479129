import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_REMOVE
  // NOTIFICATIONS_HIDE_ALL
} from '../../constants'

import Notification from 'models/notification'

const initialState = {
  notifications: []
}

export default function notificationsReducer (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST: {
      return state
    }

    case NOTIFICATIONS_SUCCESS: {
      const notifications = action.payload.notifications.map(notification => {
        return new Notification(notification)
      })
      return {
        notifications: notifications
      }
    }

    case NOTIFICATIONS_FAILURE: {
      const responseError = action.payload.error
      const response = responseError.response
      let error = null

      if (response && response.data) {
        error = response.data
      } else {
        error = action.payload
      }

      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        error: error
      }
    }

    case NOTIFICATIONS_REMOVE: {
      const { id } = action.payload
      const { notifications } = state

      return {
        notifications: notifications.filter(({ _id }) => _id !== id)
      }
    }

    default:
      return state
  }
}
