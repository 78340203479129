class Notification {
  constructor (notificationData) {
    this._id = notificationData._id
    this.event = notificationData.event
    this.message = notificationData.message
    this.data = notificationData.payload
  }

  static fromResponse = (notificationData) => {
    return new Notification(notificationData)
  }
}

export default Notification
