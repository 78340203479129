import React from 'react'
import { Link } from 'react-router-dom'
import Cover from '../../../models/cover'

const faType = fileType => {
  if (fileType === 'File') {
    return 'fa-file'
  }
  if (fileType === 'Video') {
    return 'fa-video'
  }
  if (fileType === 'IMG') {
    return 'fa-image'
  }
  if (fileType === 'PDF') {
    return 'fa-file-pdf'
  }
}

export default ({ value, row }) => {
  return (
    <div>
      {value.map(media => (
        <>
          <i
            className={`fa ${faType(media.fileType)}`}
            style={{ color: '#63c2de' }}
          />
          <br />
        </>
      ))}
    </div>
  )
}
