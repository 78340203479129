import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class EventPerformanceService {
  async approve (id) {
    return axios.post(`${API_ENDPOINT}/performances/${id}/approve`)
  }

  async publish (id) {
    return axios.post(`${API_ENDPOINT}/performances/${id}/publish`)
  }
}

export default new EventPerformanceService()
