import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

import { extractFileName } from "helpers/responseHelper";
import CoversService from "services/CoversService";
import Cover from "models/cover";
import { Formatters } from "components/data-grid-table";
import AddCoverFormModal from "components/modals/AddCoverFormModal";
import Tooltip from "components/Tooltip";
import DataTable from "components/tables/DataTable";
import withAuth from "containers/auth.container";
import {
  FilterCoverPublicationDatComponent,
  PublishersSelectMappingFilter,
  FilterMediaComponent,
} from "components/data-grid-table/filters/components";
import ConfirmationModal from "../modals/ConfirmationModal";
import ReduxModalWrapper from "../modals/reduxModal/ReduxModalWrapper";
import MultipleMediaModal from "../modals/MultipleMediaModal";
import FileUploadService from "../../services/FileUploadService";
import { MediaFormatter } from "../data-grid-table/formatters";

const getColumns =
  (outerProps) =>
  (props = {}) => {
    const { isOnlineSeller, isBookSeller, ...restProps } = props;

    const columns = [
      {
        key: "imageUrl",
        name: "Immagine",
        formatter: Formatters.ImageCoverFormatter,
        cellClass: "justify-content-center",
      },
      {
        key: "title",
        name: "Titolo",
        filterable: true,
      },
      {
        key: "authorsStr",
        name: "Autori",
        sortable: true,
        filterable: false,
      },
      {
        key: "publisher",
        name: "Editore",
        formatter: Formatters.PublisherDataFormatter,
        filterRenderComponent: PublishersSelectMappingFilter,
        filterable: true,
      },
      {
        key: "publishDate",
        name: "Data di pubblicazione",
        formatter: Formatters.DayMonthYearFormatter({ ...restProps }),
        filterRenderComponent: FilterCoverPublicationDatComponent,
        filterable: true,
        cellClass: "justify-content-center",
      },
      {
        key: "ean",
        name: "EAN",
        cellClass: "justify-content-center",
        filterable: true,
      },
      {
        key: "media",
        name: "Extra Media",
        cellClass: "justify-content-center",
        filterable: true,
        filterRenderComponent: FilterMediaComponent,
        formatter: MediaFormatter,
      },
    ];
    if (!isOnlineSeller) {
      columns.push({
        key: "actions",
        name: "Azioni",
        formatter: Formatters.ActionsFormatter({ ...restProps, ...outerProps }),
        cellClass: "justify-content-center",
      });
    }

    return columns;
  };

const CoverDataTable = ({
  isOnlineSeller,
  isStaff,
  isBookSeller,
  isEditorPDE,
}) => {
  const [covers, setCovers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedCovers, setSelectedCovers] = useState([]);
  const [multipleMediaModalOpened, setMultipleMediaModalOpened] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchCovers = ({ page: { number, size }, formFilterData }) => {
    let searchData = {
      ...formFilterData,
    };

    CoversService.getCovers(number, size, searchData)
      .then((coversResponse) => {
        const {
          data: { results, totalRecords },
        } = coversResponse;
        const covers = results.map((response) => Cover.fromResponse(response));
        setCovers(covers);
        setTotalRows(totalRecords);
      })
      .catch(() => {});
  };

  const onChange = (params) => {
    fetchCovers(params);
  };

  const handleSubmit = (action) => (formValues) => {
    const { month, year } = formValues;
    let body = {
      ...formValues,
    };

    if (year && month) {
      body = {
        ...body,
        publicationDate: new Date(Date.UTC(year, month)),
      };
    } else {
      delete body["publicationDate"];
    }

    let request = CoversService[action];

    if (request) {
      if (action === "create") {
        request = request(body);
      }

      if (action === "update" || action === "delete") {
        const { _id } = body;
        request = request(_id, body);
      }

      return request;
    }
  };

  const onPublish = (params) => {
    const body = { ...params };
    const { _id } = body;

    CoversService.publish(_id, body)
      .then((response) => {
        const { data } = response;
        const newCovers = [...covers];
        const coverIndex = newCovers.findIndex((cover) => cover._id === _id);

        if (coverIndex !== -1) {
          newCovers[coverIndex] = new Cover(data);
          setCovers(newCovers);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getFormModalModeProps = (mode) => {
    if (mode === "create") {
      return {
        submitButtonLabel: "Crea",
        title: "Crea copertina",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "update") {
      return {
        submitButtonLabel: "Aggiorna",
        title: "Aggiorna copertina",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "delete") {
      return {
        submitButtonLabel: "Elimina",
        title: "Elimina copertina",
        onSubmit: handleSubmit(mode),
      };
    }

    return {
      submitButtonLabel: "Salva",
      title: "Copertina",
    };
  };

  const prepareModalData = async (data) => {
    const response = await CoversService.get(data._id);
    const {
      data: { cover },
    } = response;
    delete cover.authors;
    delete cover.publisher;
    delete cover.media;
    return {
      ...data,
      publisher: data.publisher ? data.publisher._id : null,
      authors: data.authors ? data.authors.map(({ _id }) => _id) : [],
    };
  };

  const isRowSelected = ({ _id }) => {
    return selectedCovers.includes(_id);
  };

  const onRowSelect = ({ _id }) => {
    if (!selectedCovers.includes(_id)) {
      setSelectedCovers([...selectedCovers, _id]);
    } else {
      setSelectedCovers(selectedCovers.filter((id) => id !== _id));
    }
  };

  const onExport = () => {
    setSelectedCovers([]);

    CoversService.download(...selectedCovers).then((response) => {
      const filename = extractFileName(response.headers["content-disposition"]);
      saveAs(response.data, filename);
    });
  };

  const updateMedia = async (row, files) => {
    const newMedia = [];
    if (files.length) {
      for (const file of files) {
        try {
          const {
            data: { uploadedFileData },
          } = await FileUploadService.upload(file.file);
          newMedia.push({
            fileType: file.type,
            url: uploadedFileData.url,
            name: file.file.name,
          });
        } catch (e) {
          if (e.message === "Incorrect file format") {
            // it means, that it's already existed file
            // So just push it back to not to lose
            newMedia.push({
              fileType: file.type,
              url: file.url,
              name: file.file.name,
            });
          } else {
            throw new Error(e);
          }
        }
      }
    }
    const { data } = await CoversService.updateMedia(row._id, {
      media: newMedia,
    });
    setCovers((prevCovers) =>
      prevCovers.map((cover) =>
        cover._id === data._id ? Cover.fromResponse(data) : cover
      )
    );
  };

  const columnsOuterProps = {
    onMedia: (row) => {
      setMultipleMediaModalOpened(true);
      setSelectedRow(row);
    },
    canMedia: isStaff || isEditorPDE || isBookSeller,
    canInfo: false,
    canRemove: false,
    canEdit: isStaff,
  };
  const parseMedia = (media) => ({
    file: {
      name: media.name,
    },
    type: media.fileType,
    url: media.url,
  });
  const types = {
    Video: "video/*",
    PDF: ".pdf",
    IMG: "image/*",
    File: "*",
  };
  return (
    <Fragment>
      <DataTable
        columns={getColumns(columnsOuterProps)}
        selectable={isOnlineSeller || isStaff}
        onSelectedRowChange={(coverIds) => setSelectedCovers(coverIds)}
        selectedRows={selectedCovers}
        createTableActions={() => {
          if (isOnlineSeller || isStaff) {
            return (
              <Tooltip id="test" placement="top" text="Esporta in Excel">
                <Button
                  className="ml-2"
                  color="success"
                  size="xs"
                  onClick={onExport}
                  disabled={selectedCovers.length === 0}
                >
                  <i
                    className="fas fa-download"
                    style={{ color: "#ffffff", pointerEvents: "none" }}
                  />
                </Button>
              </Tooltip>
            );
          }
          return null;
        }}
        prepareModalData={prepareModalData}
        columnsProps={{
          onPublish: onPublish,
          isSelected: isRowSelected,
          onSelect: onRowSelect,
          isOnlineSeller,
          isBookSeller,
        }}
        data={covers}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={
          !isBookSeller
            ? {
                formModalComponent: AddCoverFormModal,
                getFormModalModeProps: getFormModalModeProps,
              }
            : null
        }
      />
      <MultipleMediaModal
        isOpen={multipleMediaModalOpened}
        onSubmit={updateMedia}
        rowTitle={selectedRow ? selectedRow.title : ""}
        row={selectedRow}
        types={types}
        canEdit={isStaff || isEditorPDE}
        mediaParser={parseMedia}
        onToggle={() => setMultipleMediaModalOpened(!multipleMediaModalOpened)}
      />
      <ReduxModalWrapper
        modal="confirmDeleteAdditionalPage"
        component={ConfirmationModal}
      />
    </Fragment>
  );
};
export default withAuth(CoverDataTable);
