import React, { Fragment, useState } from "react";

import { Formatters } from "components/data-grid-table";
import Author from "models/author";
import DataTable from "./DataTable";
import AddAuthorFormModal from "components/modals/AddAuthorFormModal/AddAuthorFormModal";
import AuthorsService from "services/AuthorsService";
import ModalsService from "services/ModalsService";
import { PublishersSelectMappingFilter } from "components/data-grid-table/filters/components";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import ConfirmationModal from "components/modals/ConfirmationModal";

import CancelSubmitionError from "components/forms/errors/CancelSubmitionError";
import { BOOK_SELLER } from "../../constants/users";
import withAuth from "../../containers/auth.container";

const getColumns = (role) => (props) => {
  const columns = [
    {
      key: "surname",
      name: "Cognome",
      sortable: true,
      filterable: true,
    },
    {
      key: "name",
      name: "Nome",
      sortable: true,
      filterable: true,
    },
    {
      key: "publisher",
      name: "Editori",
      formatter: Formatters.PublisherDataFormatter,
      filterRenderComponent: PublishersSelectMappingFilter,
      sortable: true,
      filterable: true,
    },
    {
      key: "actions",
      name: "Azioni",
      formatter: Formatters.ActionsFormatter(props),
      cellClass: "justify-content-center",
    },
  ];
  if (role === BOOK_SELLER) {
    columns.pop();
  }
  return columns;
};

const AuthorDataTable = ({ user }) => {
  const [authors, setAuthors] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const fetchAuthors = ({ page: { number, size }, formFilterData }) => {
    AuthorsService.getAuthors(number, size, formFilterData)
      .then((response) => {
        const {
          data: { results, totalRecords },
        } = response;
        const authors = results.map((response) =>
          Author.fromResponse(response)
        );

        setAuthors(authors);
        setTotalRows(totalRecords);
      })
      .catch(() => {});
  };

  const onChange = (params) => {
    fetchAuthors(params);
  };

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues };

      let request = AuthorsService[action];

      if (request) {
        if (action === "create") {
          request = request(body);
        }

        if (action === "update") {
          const { _id } = body;
          request = request(_id, body);
        }

        if (action === "delete") {
          const { _id } = body;

          request = new Promise((resolve, reject) => {
            AuthorsService.get(_id)
              .then((response) => {
                const {
                  data: { author },
                } = response;
                const { covers, performances } = author;

                if (covers.length > 0 || performances.length > 0) {
                  ModalsService.open("confirmAuthorDeleteModal", {
                    title: "Elimina autore",
                    text: "Ci sono copertine ed eventi associati a questo autore. Proseguendo verranno eliminati.\n\nConfermi questa azione?",
                    onSubmit: () => {
                      AuthorsService[action](_id, body)
                        .then((response) => {
                          ModalsService.close("confirmAuthorDeleteModal");
                          resolve(response);
                        })
                        .catch((error) => {
                          ModalsService.close("confirmAuthorDeleteModal");
                          resolve(error);
                        });
                    },
                    onCancel: () => {
                      reject(new CancelSubmitionError());
                    },
                  });
                } else {
                  resolve(AuthorsService[action](_id, body));
                }
              })
              .catch(reject);
          });
        }

        return request;
      }
    };
  };

  const getFormModalModeProps = (mode) => {
    if (mode === "create") {
      return {
        title: "Crea autore",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "update") {
      return {
        title: "Aggiorna autore",
        onSubmit: handleSubmit(mode),
      };
    }

    if (mode === "delete") {
      return {
        title: "Elimina autore",
        onSubmit: handleSubmit(mode),
      };
    }

    return {
      submitButtonLabel: "Salva",
      title: "Autore",
    };
  };

  const prepareModalData = (data) => {
    const { publisher } = data;

    return {
      ...data,
      publisher: publisher ? publisher._id : null,
    };
  };

  const formModalProps =
    user.role === BOOK_SELLER
      ? null
      : {
          formModalComponent: AddAuthorFormModal,
          getFormModalModeProps: getFormModalModeProps,
        };

  return (
    <Fragment>
      <DataTable
        columns={getColumns(user.role)}
        data={authors}
        prepareModalData={prepareModalData}
        totalRowsCount={totalRows}
        onChange={onChange}
        formModalProps={formModalProps}
      />
      <ReduxModalWrapper
        modal="confirmAuthorDeleteModal"
        component={ConfirmationModal}
      />
    </Fragment>
  );
};
export default withAuth(AuthorDataTable);
