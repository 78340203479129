import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class AuthorsService {
  async getAuthors (pageNumber = 1, pageSize = 20, search = {}) {
    const params = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      search: search
    }

    return axios.get(`${API_ENDPOINT}/authors`, { params: params })
  }

  async index () {
    return axios.get(`${API_ENDPOINT}/authors`)
  }

  async get (id) {
    return axios.get(`${API_ENDPOINT}/authors/${id}`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/authors`, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/authors/${id}`, { data: body })
  }

  async delete (id, body) {
    return axios.delete(`${API_ENDPOINT}/authors/${id}`, { data: body })
  }

  async covers (id) {
    return axios.get(`${API_ENDPOINT}/authors/${id}/covers`)
  }
}

export default new AuthorsService()
