import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
  const { row: { _id }, value } = props

  return value ? <div style={
    { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }
  }>
    {value.split(';').map(publisherId => (
      <Link to={`/publishers/${_id}`}>{publisherId}</Link>
    ))}
  </div> : null
}
