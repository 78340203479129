import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './HeadingIcon.module.scss'

const propTypes = {
  icon: PropTypes.string,
  classNames: PropTypes.string
}

const HeadingIcon = ({ icon, classNames }) => {
  return (
    <div className={cx(styles.HeadingIcon, classNames)}>
      <i className={icon} />
    </div>
  )
}

HeadingIcon.propTypes = propTypes

export default HeadingIcon
