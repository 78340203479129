import React from 'react'
import PropTypes from 'prop-types'
import withAuth from 'containers/auth.container'

import { Formatters } from '../index'

export default (outerProps) => {
  const ActionFormatter = Formatters.ActionsFormatter(outerProps)
  const BooksellerActionsFormatter = withAuth((props) => {
    const { user, isEditorPDE, row: { creatorId } } = props

    const canManage = !isEditorPDE || (isEditorPDE && user._id === creatorId)
    return <ActionFormatter {...props} canEdit={canManage} canInfo={canManage} canRemove={canManage} />
  })

  BooksellerActionsFormatter.propTypes = {
    row: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    canEdit: PropTypes.bool,
    canRemove: PropTypes.bool
  }

  return BooksellerActionsFormatter
}
