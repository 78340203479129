import React from 'react'

export default ({ value }) => {
  const badgeClass = value === 'admin' ? 'success' : 'secondary'
  return (
    <div>
      <span className={`badge badge-${badgeClass}`} style={{ fontSize: '100%' }}>{value}</span>
    </div>
  )
}
