import { SubmissionError } from 'redux-form'

import CancelSubmitionError from 'components/forms/errors/CancelSubmitionError'

export default (error) => {
  if (!(error instanceof CancelSubmitionError)) {
    let responseError = null
    let fieldErrors = null

    if (error.response && error.response.data) {
      responseError = error.response.data
      fieldErrors = responseError['fieldErrors']
    } else {
      responseError = error
    }

    throw new SubmissionError({
      _error: responseError,
      ...fieldErrors
    })
  }
}
