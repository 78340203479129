import React from 'react'
import NavigationMenu from './NavigationMenu'

const Sidebar = ({ isStaff, isEditorPDE, isPublicationManager, isOnlineSeller, isBookSeller }) => {
  return (
    <div className='sidebar'>
      <nav className='sidebar-nav'>
        <NavigationMenu isStaff={isStaff}
          isEditorPDE={isEditorPDE}
          isPublicationManager={isPublicationManager}
          isOnlineSeller={isOnlineSeller}
          isBookSeller={isBookSeller}
        />
      </nav>
    </div>
  )
}

export default Sidebar
