import React from 'react'
import {
  Field,
  formPropTypes,
  reduxForm
} from 'redux-form'

import { renderFormGroupTextField } from './formFields'
import { required } from './validators'

const UpdateProfileForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label='Nome'
        name='name'
        placeholder='Inserisci il nome'
        component={renderFormGroupTextField}
        validate={[required]}
      />
      <Field
        label='Cognome'
        name='surname'
        placeholder='Inserisci il cognome'
        component={renderFormGroupTextField}
        validate={[required]}
      />

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Aggiorna
      </button>
    </form>
  )
}

UpdateProfileForm.propTypes = formPropTypes

export default reduxForm({
  form: 'updateProfileForm'
})(UpdateProfileForm)
