class Cover {
  constructor(coverData) {
    this._id = coverData._id;
    this.title = coverData.title;
    this.ean = coverData.ean;
    this.status = coverData.status;
    this.edition = coverData.edition;
    this.description = coverData.description;
    this.authors = coverData.authors;
    this.authorsStr = coverData.authorsStr;
    this.publisher = coverData.publisher;
    this.additionalImages = coverData.additionalImages || [];
    this.publicationDate = coverData.publishDate;
    this.creatorId = coverData.creatorId;

    this.publishOnPDE = coverData.publishOnPDE;
    this.deleted = coverData.deleted;
    this.editor = coverData.editor;
    this.imageUrl = coverData.imageUrl;
    this.language = coverData.language;
    this.pageNumber = coverData.pageNumber;
    this.price = coverData.price;
    this.publishDate = coverData.publishDate;
    this.series = coverData.series;

    this.genre = coverData.genre;
    this.metaDescription = coverData.metaDescription;
    this.metaTitle = coverData.metaTitle;
    this.ogDescription = coverData.ogDescription;
    this.ogTitle = coverData.ogTitle;
    this._media = coverData.media;
    this.translator = coverData.translator;
  }

  get media() {
    return this._media || [];
  }
  static fromResponse = (coverData) => {
    return new Cover(coverData);
  };
}

export default Cover;
