import React, { Fragment } from 'react'
import PrivateRoute from '../../components/routes/PrivateRoute'
import ProfileSettings from './ProfileSettings'
import { Switch } from 'react-router-dom'

import withAuth from 'containers/auth.container'
import Security from './Security'
import Notifications from './Notifications'
import Profile from './Profile/Profile'
import ExternalTokens from './ExternalTokens'

const ProfileSettingsRouter = (props) => {
  const { path } = props.match
  const { isStaff } = props

  return (
    <Switch>
      <PrivateRoute path={`${path}/profile`} component={Profile} allowedRoles='all' />
      <PrivateRoute path={`${path}/security`} component={Security} allowedRoles='all' />
      {isStaff && (
        <Fragment>
          <PrivateRoute path={`${path}/notifications`} component={Notifications} />
          <PrivateRoute path={`${path}/tokens`} component={ExternalTokens} />
        </Fragment>

      )}
      <PrivateRoute path={`${path}/`} component={ProfileSettings} allowedRoles='all' />
    </Switch>
  )
}

export default withAuth(ProfileSettingsRouter)
