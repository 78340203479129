import EventPerformance from "./eventPerformance";

class Event {
  constructor(eventData) {
    this._id = eventData._id;
    if (eventData.cover) {
      this.cover = eventData.cover;
    } else {
      this.cover = eventData.coverData;
    }
    this.title = eventData.title;
    this.creatorId = eventData.creatorId;

    this.performances = eventData.performances
      ? eventData.performances.map(
          (performance) => new EventPerformance(performance)
        )
      : [];
  }

  static fromResponse = (eventData) => {
    return new Event(eventData);
  };
}

export default Event;
