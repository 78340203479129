import React, { Component } from "react";
import { Button } from "reactstrap";
import { saveAs } from "file-saver";

import PageContainer from "components/layouts/PageContainer";
import Heading from "components/Heading";
import SimpleTable from "components/tables/SimpleTable";
import ConfirmationModal from "components/modals/ConfirmationModal";
import DateFormatter from "components/data-grid-table/formatters/DateFormatter";
import ReduxModalWrapper from "components/modals/reduxModal/ReduxModalWrapper";
import AddEventFormModal from "components/modals/AddEventFormModal/AddEventFormModal";
import Tooltip from "components/Tooltip";
import EventsService from "services/EventsService";
import EventPerformanceService from "services/EventPerformanceService";
import ModalsService from "services/ModalsService";
import Event from "models/event";
import EventPerformance from "models/eventPerformance";
import withAuth from "containers/auth.container";

import { PerformanceStatusFormatter } from "./formatters";
import { extractFileName } from "helpers/responseHelper";

import styles from "./EventDetailsView.module.scss";
import handleFormSubmitError from "../../../helpers/forms/handleFormSubmitError";
import EventPerformanceLegend from "../../../components/modals/AddEventFormModal/EventPerformanceLegend";

const getColumns = (outerProps) => [
  {
    key: "title",
    name: "Evento",
    formatter: (props) => (
      <div>
        <p>{`${props.row.title}`}</p>
        <p>{`${props.row.description || ""}`}</p>
      </div>
    ),
  },
  {
    key: "date",
    name: "Data",
    formatter: DateFormatter,
  },
  {
    key: "time",
    name: "Ora",
  },
  {
    key: "address",
    name: "Indirizzo",
  },
  {
    key: "requiredBookCopies",
    name: "N° Copie",
    formatter: ({ value }) => <span>{`${value} copie`}</span>,
  },
  {
    key: "status",
    name: "Stato",
    formatter: (props) => (
      <PerformanceStatusFormatter {...props} {...outerProps} />
    ),
    cellClass: "justify-content-center",
  },
];

const modals = [
  <ReduxModalWrapper
    modal="EditEventFormModal"
    component={AddEventFormModal}
  />,
  <ReduxModalWrapper
    modal="confirmEventPerformanceDeleteModal"
    component={ConfirmationModal}
  />,
];

class EventDetailsView extends Component {
  state = {
    isLoading: false,
    event: null,
  };

  componentDidMount() {
    const { id } = this.props;

    this.setState({ isLoading: true });

    EventsService.get(id)
      .then((response) => {
        const {
          data: { event },
        } = response;
        this.setState({ event: new Event(event), isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  onApprove = (performance) => {
    EventPerformanceService.approve(performance._id).then((response) => {
      const {
        data: { performance },
      } = response;

      const event = new Event(this.state.event);
      const performances = event.performances.slice();
      const idx = performances.findIndex(({ _id }) => _id === performance._id);
      performances[idx] = new EventPerformance(performance);
      event.performances = performances;

      this.setState({ event: event });
    });
  };

  onPublish = (performance) => {
    EventPerformanceService.publish(performance._id).then((response) => {
      const {
        data: { performance },
      } = response;

      const event = new Event(this.state.event);
      const performances = event.performances.slice();
      const idx = performances.findIndex(({ _id }) => _id === performance._id);
      performances[idx] = new EventPerformance(performance);
      event.performances = performances;

      this.setState({ event: event });
    });
  };

  onEditEvent = () => {
    const event = this.state.event;

    ModalsService.open("EditEventFormModal", {
      title: "Update Event",
      initialValues: { ...event, cover: event.cover._id },
      onSubmit: (body) => {
        return EventsService.update(event._id, body)
          .then((response) => {
            const {
              data: { event },
            } = response;
            ModalsService.close("EditEventFormModal");
            this.setState({ event: new Event(event) });
          })
          .catch((error) => {
            handleFormSubmitError(error);
          });
      },
      onCancel: () => {
        ModalsService.close("EditEventFormModal");
      },
    });
  };

  onExport = () => {
    EventsService.download(this.props.id).then((response) => {
      const filename = extractFileName(response.headers["content-disposition"]);
      saveAs(response.data, filename);
    });
  };

  render() {
    const { event, isLoading } = this.state;

    if (!isLoading && !event) {
      return null;
    }

    return (
      <PageContainer animate loading={isLoading}>
        {() => {
          const { title, cover, performances, creatorId } = event;

          return (
            <div className={styles.EventDetailsPage}>
              <section>
                <Heading icon="fa fa-user fa-xs">
                  <span>{`Evento "${title}"`}</span>
                </Heading>
                <div className={styles.AuthorDetails}>
                  {cover && (
                    <>
                      <img src={cover.image} width={400} alt="" />
                      <div
                        className={styles.Description}
                        dangerouslySetInnerHTML={{ __html: cover.description }}
                      />
                    </>
                  )}
                </div>
              </section>
              <section>
                <EventPerformanceLegend />
              </section>
              <section>
                <Heading icon="fas fa-list fa-xs">Lista eventi</Heading>
                <div className="pr-4 pl-4">
                  <SimpleTable
                    data={performances}
                    columns={getColumns({
                      creatorId: creatorId,
                      onPublish: this.onPublish,
                      onApprove: this.onApprove,
                    })}
                  />
                </div>
              </section>
              <div className={styles.ButtonGroup}>
                <Button color="warning" size="xs" onClick={this.onEditEvent}>
                  <i className="fa fa-pen" style={{ color: "#ffffff" }} />
                </Button>
                <Tooltip id="test" placement="top" text="Esporta in Excel">
                  <Button color="success" size="xs" onClick={this.onExport}>
                    <i
                      className="fas fa-download"
                      style={{ color: "#ffffff" }}
                    />
                  </Button>
                </Tooltip>
              </div>
              {modals}
            </div>
          );
        }}
      </PageContainer>
    );
  }
}

export default withAuth(EventDetailsView);
