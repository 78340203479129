import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { updateUserData } from 'store/actions/auth'
import User from 'models/user'
import UpdateProfileForm from 'components/forms/UpdateProfileForm'
import handleFormSubmitError from 'helpers/forms/handleFormSubmitError'
import UsersService from 'services/UsersService'

import styles from './Profile.module.scss'
import LocalStorageService, { USER } from 'services/LocalStorageService'

const Profile = ({ user, updateUserData }) => {
  const submitChanges = (values) => {
    return UsersService.update(user._id, values)
      .then((response) => {
        const { data } = response
        const user = new User(data)

        LocalStorageService.setItem(USER, JSON.stringify(user))
        updateUserData(user)
        toast.success('Profilo aggiornato')
      })
      .catch((error) => {
        handleFormSubmitError(error)
      })
  }

  return (
    <div className={styles.profile}>
      <section className={styles.container}>
        <h2>Profilo</h2>
        <section className={styles.form}>
          <h4>Aggiorna Profilo</h4>
          <UpdateProfileForm onSubmit={submitChanges} initialValues={{ name: user.name, surname: user.surname }} />
        </section>
      </section>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user
  }
}

export default connect(mapStateToProps, { updateUserData })(Profile)
