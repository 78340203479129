import React from 'react'
import cx from 'classnames'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Modal
} from 'reactstrap'

import { fromModalPropTypes } from './formModalPropTypes'

import styles from './FormModal.module.scss'

const FormModal = (props) => {
  const {
    formTitle,
    children,
    isOpen,
    toggle,
    handleSubmit,
    submitting,
    onClose,
    reset,
    className
  } = props

  const resetForm = () => {
    toggle()
    reset()
  }

  const onClosed = () => {
    onClose && onClose()
    reset()
  }

  return (
    <Modal className={cx(styles.FormModal, className)} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <Card>
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(e)
        }}>
          <CardHeader className='d-flex align-items-center'>
            <i className='fa fa-align-justify' />
            <span>{formTitle}</span>
          </CardHeader>
          <CardBody>
            {children}
          </CardBody>
          <CardFooter>
            <ButtonGroup>
              <Button className='mr-3' color='success' size='xs' disabled={submitting}>
                <i className='fa fa-check' style={{ color: '#ffffff' }} />
              </Button>
              <Button color='danger' size='xs' onClick={resetForm} disabled={submitting}>
                <i className='fa fa-times' style={{ color: '#ffffff' }} />
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Form>
      </Card>
    </Modal>
  )
}

FormModal.propTypes = {
  ...fromModalPropTypes
}

FormModal.defaultProps = {
  submitButtonLabel: 'Submit',
  cancelButtonLabel: 'Cancel',
  formTitle: 'Form'
}

export default FormModal
