import React from "react";
import cx from "classnames";
import { FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";
import NumberFormat from "react-number-format";

import Select from "components/Select";
import DayPickerInput from "components/DayPickerInput";
import UploadFileInput from "../UploadFileInput";
import Switch from "../Switch";
import FileInput from "../FileInput";
import TagsInput from "react-tagsinput";
import { change } from "redux-form";

export const renderTextField = ({
  input,
  placeholder,
  label,
  icon,
  type,
  disabled,
  meta: { touched, error },
}) => {
  const invalid = error && touched;
  const { name } = input;

  return (
    <InputGroup className="input-group mb-3">
      {label && <label htmlFor={name}>{label}</label>}
      {icon && (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={`icon-${icon}`} />
          </span>
        </div>
      )}
      <Input
        className="form-control"
        {...input}
        id={name}
        type={type}
        invalid={invalid}
        placeholder={placeholder}
        disabled={disabled}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  );
};

export const renderFormGroupTextField = ({
  input,
  placeholder,
  label,
  icon,
  type,
  disabled,
  meta: { touched, error },
}) => {
  const invalid = error && touched;
  const { name } = input;

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup>
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={`icon-${icon}`} />
            </span>
          </div>
        )}
        <Input
          className="form-control"
          {...input}
          id={name}
          type={type}
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  );
};

export const renderNumberFormatInput = ({
  input,
  placeholder,
  label,
  disabled,
  meta: { touched, error },
}) => {
  const invalid = error && touched;
  const { name, onChange, onBlur } = input;

  const convertValue = ({ target }) => {
    const { value } = target;
    return parseFloat(value.replace(/\./g, "").replace(/,/, "."));
  };

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup>
        <NumberFormat
          customInput={Input}
          {...input}
          id={name}
          type="input"
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          onChange={(e) => onChange(convertValue(e))}
          onBlur={(e) => onBlur(convertValue(e))}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  );
};

export const renderSelectMappingField = ({
  label,
  virtualized,
  mapping,
  isLoading,
  placeholder,
  input,
  disabled,
  isMulti,
  onInputChange,
  meta: { touched, error },
}) => {
  let options = Array.isArray(mapping)
    ? mapping
    : Object.keys(mapping).reduce((memo, key) => {
        memo.push({ value: key, label: mapping[key] });
        return memo;
      }, []);

  const invalid = error && touched;
  const { name, value, onChange, onBlur } = input;

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <div className="InputContainer">
        <Select
          {...input}
          id={name}
          virtualized={virtualized}
          isMulti={isMulti}
          options={options}
          invalid={invalid}
          isLoading={isLoading}
          placeholder={placeholder}
          disabled={disabled}
          value={
            isMulti
              ? options.filter((option) => value.includes(option.value))
              : options.find((option) => option.value === value) || ""
          }
          onChange={(value) => {
            const inputValue = isMulti
              ? value
                ? value.map(({ value }) => value)
                : ""
              : value.value;
            onChange(inputValue);
          }}
          onInputChange={(value) => {
            onInputChange && onInputChange(value);
          }}
          onBlur={() => onBlur(value)}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </div>
    </FormGroup>
  );
};

export const renderFileUploadInput = (props) => {
  const {
    meta: { touched, error },
    label,
    input,
    preview,
  } = props;
  const invalid = error && touched;
  const { name } = input;

  return (
    <FormGroup>
      <Label>{label}</Label>
      <UploadFileInput
        {...props}
        id={name}
        invalid={invalid}
        preview={preview}
      />
    </FormGroup>
  );
};

export const renderFileInput = (props) => {
  const {
    meta: { touched, error },
    label,
    input,
    disabled,
    accept,
  } = props;
  const invalid = error && touched;
  const { name, value, ...inputProps } = input;

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <FileInput
        {...inputProps}
        id={name}
        type="file"
        name={name}
        value={value}
        invalid={invalid}
        accept={accept}
        disabled={disabled}
        onBlur={() => {}}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export const renderDateField = ({ input, label, disabled, meta, utc }) => {
  const { touched, error } = meta;
  const invalid = error && touched;
  const { name, value } = input;

  const inputClassNames = cx("form-control", { "is-invalid": invalid });

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <div className="InputContainer">
        <DayPickerInput
          id={name}
          value={value}
          invalid={invalid}
          utc={utc}
          inputProps={{
            ...input,
            disabled: disabled,
            className: inputClassNames,
            autoComplete: "off",
          }}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </div>
    </FormGroup>
  );
};

export const renderSwitch = ({ input, label, name }) => {
  return (
    <FormGroup className="d-flex align-items-center">
      {label && (
        <Label for={name} className="mr-4">
          {label}
        </Label>
      )}
      <Switch {...input} />
    </FormGroup>
  );
};

export const renderCheckbox = ({ input, label, name }) => {
  return (
    <FormGroup className="d-flex align-items-center">
      {label && <Label for={name}>{label}</Label>}
      <input {...input} type="checkbox" checked={input.value} />
    </FormGroup>
  );
};

export const renderFormGroupTagsInput = ({ input, label, meta }) => {
  const { name, value } = input;
  const { dispatch, form } = meta;
  return (
    <FormGroup className="d-flex align-items-center">
      {label && <Label for={name}>{label}</Label>}
      <TagsInput
        value={value ? value.split(";") : []}
        onChange={(tags) => {
          dispatch(change(form, name, tags.join(";")));
        }}
      />
    </FormGroup>
  );
};
