import React from "react";
import { Button, Col, Row } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Field } from "redux-form";

import {
  renderDateField,
  renderFormGroupTextField,
  renderSelectMappingField,
} from "components/forms/formFields";
import { required, number } from "components/forms/validators";
import { ORGANIZATIONS_TYPES, COMMERCIAL } from "constants/organizationTypes";
import { BOOK_HANDLING } from "constants/bookHandling";
import { PROVINCES } from "constants/provinces";

import Accordion from "./Accordion";
import styles from "./AddEventFormModal.module.scss";
import EventPerformanceAlert from "./EventPerformanceAlert";
import EventPerformanceLegend from "./EventPerformanceLegend";
import { constant } from "lodash";

export const renderEventPerformances = ({
  fields,
  booksellers,
  performances,
  disabled,
  onAddPerformance,
  onRemovePerformance,
}) => {
  const addPerformance = () => {
    if (onAddPerformance) {
      onAddPerformance(fields);
    }
  };

  const removePerformance = (index) => {
    if (onRemovePerformance) {
      onRemovePerformance(fields, index);
    }
  };

  return (
    <div>
      <div className="d-flex mb-2">
        <Button
          className="ml-auto"
          color="success"
          size="xs"
          onClick={addPerformance}
          disabled={disabled}
        >
          <i className="fa fa-plus mr-2" />
          Aggiungi data
        </Button>
      </div>
      <TransitionGroup component="div">
        {fields.map((performance, index) => {
          const isCommercial =
            performances[index].organizationType &&
            performances[index].organizationType === COMMERCIAL;
          const eventStartDate = performances[index].date
            ? new Date(performances[index].date)
            : null;

          return (
            <CSSTransition timeout={500} classNames="fade" key={index}>
              <Accordion
                className={styles.Accordion}
                editable={!disabled}
                expandOnMound
                header="Data evento"
              >
                <Row className="mt-3 mb-3">
                  <Col>
                    <EventPerformanceLegend />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="w-100 d-flex mb-2 justify-content-end">
                      <EventPerformanceAlert
                        id={index}
                        className="mr-3"
                        eventStartDate={eventStartDate}
                      />
                      <Button
                        color="danger"
                        size="xs"
                        disabled={disabled}
                        onClick={() => removePerformance(index)}
                      >
                        <i
                          className="fas fa-trash"
                          style={{ color: "#ffffff" }}
                        />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Titolo"
                      name={`${performance}.title`}
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderDateField}
                      label="Data"
                      name={`${performance}.date`}
                      utc
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Ora"
                      name={`${performance}.time`}
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                      placeholder="12:50"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Luogo"
                      name={`${performance}.location`}
                      disabled={disabled}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Indirizzo"
                      name={`${performance}.address`}
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderSelectMappingField}
                      mapping={PROVINCES}
                      label="Provincia"
                      name={`${performance}.province`}
                      value={`${performance}.province`}
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Riferimento"
                      name={`${performance}.referenceName`}
                      disabled={disabled}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Tel."
                      name={`${performance}.referencePhone`}
                      disabled={disabled}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Email"
                      name={`${performance}.referenceEmail`}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderSelectMappingField}
                      label="Organizzato da"
                      mapping={ORGANIZATIONS_TYPES}
                      name={`${performance}.organizationType`}
                      value={`${performance}.organizationType`}
                      disabled={disabled}
                      validate={disabled ? [] : [required]}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderSelectMappingField}
                      label="Gestione Libri"
                      mapping={BOOK_HANDLING}
                      name={`${performance}.bookHandling`}
                      value={`${performance}.bookHandling`}
                      disabled={disabled}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Numero di copie"
                      name={`${performance}.requiredBookCopies`}
                      disabled={disabled}
                      validate={disabled ? [] : [required, number]}
                    />
                  </Col>
                </Row>
                {isCommercial && (
                  <Row>
                    <Col>
                      <Field
                        component={renderSelectMappingField}
                        virtualized
                        label="Codice libreria"
                        mapping={booksellers.mapping}
                        isLoading={booksellers.isLoading}
                        name={`${performance}.booksellerCode`}
                        value={`${performance}.booksellerCode`}
                        disabled={disabled}
                        validate={disabled ? [] : [required]}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Descrizione"
                      name={`${performance}.description`}
                      type="textarea"
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderFormGroupTextField}
                      label="Note"
                      name={`${performance}.notes`}
                      type="textarea"
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </Accordion>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};
