class Bookseller {
  constructor (booksellerData) {
    this._id = booksellerData._id
    this.code = booksellerData.code
    this.name = booksellerData.name
    this.surname = booksellerData.surname
    this.email = booksellerData.email
    this.telephone = booksellerData.telephone
    this.companyName = booksellerData.companyName
    this.address = booksellerData.address
    this.active = booksellerData.active
    this.creatorId = booksellerData.creatorId
    this.country = booksellerData.country
    this.province = booksellerData.province
    this.city = booksellerData.city
    this.zipCode = booksellerData.zipCode
    this.website = booksellerData.website
    this.facebook = booksellerData.facebook
    this.channel = booksellerData.channel
    this._externalUsers = booksellerData.externalUsers
  }
  get externalUsers () {
    if (this._externalUsers) {
      return this._externalUsers.map(eu => eu._id)
    }
    return []
  }

  static fromResponse = (booksellerData) => {
    return new Bookseller(booksellerData)
  }
}

export default Bookseller
